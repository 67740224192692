@use '../../basics/globals' as *;

.mixin-slider-items {

    .mixin-article {
        text-align: center;

        .clm-wrapper {
            justify-content: center;
        }

        .slide {
            postion: relative;
            padding-bottom: 30px;
            border-bottom: 10px solid $color-blue-logo-2;

            a {
                cursor: pointer;
            }

            > .icon {
                display: block;
                margin: -20px 0 10px 0;
                color: $color-green-middle;
                font-size: 50px;

                &.icon-quotes {
                    color: $color-green-middle;
                }

                &.icon-checkmark,
                &.circle-background {
                    top: 1px;
                    color: $color-green-middle;
                    font-size: 35px;
                    line-height: 60px;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        z-index: 0;
                        width: 60px;
                        height: 60px;
                        background: $color-green-bright;
                        @include border-radius(50%, 50%, 50%, 50%);
                        @include transform(translate(-50%, 0));
                    }
                }
            }

            .preline {
                display: block;
                margin: -10px 0 10px 0;
                font-size: $font-size-50px;
                line-height: 1em;
                color: $color-green-middle;
                @include font-family('Museo Slab');
            }

            .headline {
                margin-bottom: 10px;
                color: $color-blue-logo-2;
                font-size: $font-size-28px;
                font-weight: 500;
                line-height: 1.3em;
                @include font-family('Museo Slab');

                &[data-trigger-href] {
                    cursor: pointer;

                    &:hover:not(.no-css-hover) {
                        text-decoration: underline;
                    }
                }
            }

            .teaser {
                font-weight: 500;
                text-align: center;
            }

            .image-wrapper {
                margin: 10px -30px -30px -30px;

                img {
                    width: 100%;
                }
            }
        }
    }

    .mixin-link {
        margin-bottom: -5px;
        text-align: center;
    }

    .mixin-btn {
        margin-top: 10px;
        justify-content: center;
    }

    .royalSlider {
        display: none;
        margin: 0 -120px;
        width: calc(100% + 240px);
        height: auto;
        @include transition(height, $duration-300ms, ease-out, 0s);

        * {
            @include user-select(none);
        }

        .rsVisibleNearbyWrap {
            height: inherit !important;

            .rsSlide {
                padding: 30px 30px 30px 30px;
                @include opacity(0.4);
                @include transition(opacity, $duration-300ms, ease-out, 0s);

                &.rsActiveSlide {
                    @include opacity(1);
                }
            }
        }

        .rsNav {
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            justify-content: center;
            cursor: pointer;

            .rsNavItem {
                padding: 8px;

                span {
                    display: block;
                    width: 15px;
                    height: 15px;
                    background: $color-blue-bright;
                    @include border-radius(50%, 50%, 50%, 50%);
                    @include transition(background, $duration-300ms, ease-out, 0s);
                }

                &.rsNavSelected {

                    span {
                        background: $color-blue-logo-2;
                    }
                }
            }
        }
    }

    &.initialized-slider {

        .royalSlider {
            display: block;
        }
    }
}

.bg-color-blue-logo-2 {

    .mixin-slider-items {

        .mixin-article {

            .slide {
                border-color: $color-green-middle;
            }
        }

        .royalSlider {

            .rsVisibleNearbyWrap {

                .rsSlide {
                    @include opacity(0.6);

                    &.rsActiveSlide {
                        @include opacity(1);
                    }
                }
            }

            .rsNav {

                .rsNavItem {

                    span {
                        background: $color-blue-dark;
                    }

                    &.rsNavSelected {

                        span {
                            background: $color-green-middle;
                        }
                    }
                }
            }
        }
    }
}

html.isBot {

    .royalSlider {
        display: block;
    }
}

@media screen and (max-width: 1500px) {

    .mixin-slider-items {

        .royalSlider {
            width: calc(100% + 500px);
            margin: 0 -250px;
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-slider-items {

        .royalSlider {
            width: calc(100% + 800px);
            margin: 0 -400px;
        }
    }
}

@media screen and (max-width: 800px) {

    .mixin-slider-items {

        .royalSlider {
            width: calc(300% + 200px);
            margin: 0 calc(-100% - 100px);
        }
    }
}

@media screen and (max-width: 400px) {

    .mixin-slider-items {

        .mixin-article {

            .slide {

                > .icon {
                    margin-top: -5px;
                }

                .preline {
                    margin-top: 0;
                }
            }
        }
    }
}
