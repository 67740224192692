@use 'globals' as *;

/* == TAGS ====================================================== */

* {
    @include font-family('Open Sans');
    color: $color-grey-dark;
    font-size: $font-size-18px;
    line-height: 1.5em;
    scrollbar-color: $color-blue-bright $color-blue-logo-2-opacity-005p;
}

html {
    width: 100%;
    overflow-x: hidden;

    &.isIframe {
        @include opacity(0);

        body {
            overflow: hidden !important;
            background: $transparent !important;
        }

        &.show {
            @include opacity(1);
            @include transition(opacity, 0.5s, ease-out, 3s);
        }
    }
}

body {
    width: 100%;
    min-width: 320px;
    background: $color-grey-white;
    overflow-x: hidden;
    overflow-y: auto;

    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     z-index: 1;
    //     width: 1px;
    //     height: 100%;
    //     background: $color-blue-logo-2-opacity-010p;
    //     pointer-events: none;
    // }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(120deg, $transparent, $transparent 50%, $color-blue-logo-2-opacity-005p 0, $color-blue-logo-2-opacity-005p 100%);
        pointer-events: none;
    }
}

h1 {
    color: $color-blue-logo-2;
    font-size: $font-size-50px;
    font-weight: 500;
    line-height: 1.1em;
    @include font-family('Museo Slab');
}

h2 {
    color: $color-blue-logo-2;
    font-size: $font-size-36px;
    font-weight: 500;
    line-height: 1.2em;
    @include font-family('Museo Slab');
}

h3 {
    color: $color-blue-logo-2;
    font-size: $font-size-26px;
    font-weight: 500;
    line-height: 1.3em;
    @include font-family('Museo Slab');
}

h4 {
    font-weight: 600;
}

p {
    hyphens: auto;

    a,
    .link-style,
    [data-overlay-url] {
        display: inline;
        color: $color-blue-logo-1 !important;
        font: inherit !important;
        line-height: inherit !important;
        hyphens: inherit;
        cursor: pointer;
        @include user-select(none);

        &:hover:not(.no-css-hover) {
            text-decoration: underline;
        }
    }

    em {
        font-weight: 500;
        hyphens: inherit;
    }

    &.small {
        font-size: $font-size-16px !important;
    }

    &.mini {
        font-size: $font-size-12px !important;
    }
}

span {

    &.no-break {
        white-space: nowrap;
    }
}

table {
    position: relative;
    width: 100%;
    border-collapse: collapse;

    * {
        font-size: $font-size-16px;
    }

    tr {
        width: 100% !important;

        th,
        td {
            position: relative;
            padding: 7px 8px;
        }

        th {

            &.mixin-table-th {
                max-width: 150px;
                border-right: 1px solid $color-blue-bright;
                border-bottom: 1px solid $color-blue-bright;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .headline {
                    max-width: 100%;
                    display: inline-block;
                    color: $color-blue-middle;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &.multilingual {

                    .headline {
                        max-width: calc(100% - 40px);
                    }
                }
            }

            &:last-child {
                border-right: none;
            }
        }

        td {
            vertical-align: middle;
            word-break: break-all;
            text-align: center;
            border-right: 1px solid $color-blue-bright;

            > a,
            > .link-style {
                display: inline-block;
                color: inherit;
                font: inherit;
                text-decoration: underline;
            }

            > .icon {
                top: 5px;

                &.icon-x,
                &.icon-pending,
                &.icon-warning {
                    font-size: 18px;
                    top: 3px;
                }

                &.text-before {
                    margin-left: 15px;
                }

                &.text-after {
                    margin-right: 15px;
                }
            }

            > .border-list {
                margin: 3px;
                padding: 3px;
                line-height: 1em;
                white-space: nowrap;
                border: 1px solid $color-grey-dark;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }

            .mixin-btn {
                padding-top: 0;
                justify-content: center !important;
                @include gap(0 10px);
                @include flex-wrap(nowrap);

                .btn {
                    padding: 3px;
                    width: auto;
                    height: 24px;
                    background: $transparent !important;
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                    .icon {
                        top: 1px;
                        color: $color-blue-logo-2 !important;
                    }

                    &.disabled {
                        color: $color-blue-bright !important;
                        background: $transparent !important;

                        .icon {
                            color: inherit !important;
                        }
                    }
                }
            }

            &.counter {
                color: $color-blue-bright;
                white-space: nowrap;
            }

            &.bold {
                font-weight: 400;
            }

            &.discreet {
                color: $color-blue-bright;
            }

            &:last-child {
                border: none;
            }
        }

        &.discreet {

            td {
                color: $color-blue-bright;
            }
        }

        &:hover:not(.no-css-hover) {

            td:first-child:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 3px;
                height: 100%;
                background: $color-grey-dim;
            }
        }

        &:nth-child(even) {

            td {
                background: $color-blue-light;
            }
        }

        &:nth-child(odd) {

            td {
                background: $color-grey-white;
            }
        }
    }

    a {
        color: inherit;
        font: inherit;

        &:hover:not(.no-css-hover) {
            text-decoration: underline;
        }
    }

    span {
        color: inherit;
        font: inherit;
    }

    .uniform {
        margin: 4px 0 0 0;
    }
}

@media screen and (pointer: coarse) {

    * {
        font-weight: 500;
    }
}

@media screen and (max-width: 1400px) {

    table {

        tr {

            td {
                white-space: nowrap;
            }
        }
    }
}

@media screen and (max-width: 1000px), screen and (pointer: coarse) {

    body {
        padding-top: $header-mobile-height;
    }
}

@media screen and (max-width: 500px) {

    * {
        font-weight: 400;
    }

    h1 {
        font-size: $font-size-40px;
        font-weight: 600;
        hyphens: auto;

        br {
            display: none;
        }
    }

    h2 {
        font-size: $font-size-32px;
        font-weight: 600;
        hyphens: auto;

        br {
            display: none;
        }
    }

    h3 {
        font-size: $font-size-24px;
        font-weight: 600;
        hyphens: auto;

        br {
            display: none;
        }
    }

    h4 {
        font-weight: 600;
        hyphens: auto;

        br {
            display: none;
        }
    }

    p {
        font-weight: 500;

        em {
            font-weight: 600;
        }
    }
}

/* == PSEUDO CLASSES ====================================================== */

::selection {
    color: $color-selection-color;
    background: $color-selection-background;
}

::-moz-selection {
    color: $color-selection-color;
    background: $color-selection-background;
}

/* == COMMON ====================================================== */

.section-content {
    display: flex;
    padding: 40px 0;
    background-size: 90% auto;
    background-repeat: no-repeat;
    background-position: center top;
    @include flex-wrap(wrap);
    @include gap(20px 0);

    .wrapper-section-content {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: 60px;

        .clm-wrapper {
            @include gap(20px 0);

            > .clm {
                padding: 0 30px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.clm-2 {

                    &:nth-child(odd) {
                        padding-left: 0;
                    }

                    &:nth-child(even) {
                        padding-right: 0;
                    }
                }
            }

            &.equal-distribution {
                margin: 0 -30px;

                > .clm {

                    &:first-child {
                        padding-left: 30px;
                    }

                    &:last-child {
                        padding-right: 30px;
                    }

                    &.clm-2 {

                        &:nth-child(odd) {
                            padding-left: 30px;
                        }

                        &:nth-child(even) {
                            padding-right: 30px;
                        }
                    }
                }
            }

            &.no-gap,
            &.division-header {
                @include gap(0);
            }
        }

        &:after {
            content: '';
            display: block;
            height: 1px;
        }
    }

    &:first-child {
        background-image: url('../images/assets/app/layout/background-dots-grey-light.svg?#{$timestamp}');
        background-repeat: no-repeat;
        background-position: -30px 0;
        background-size: 750px;

        &.bg-color-blue-logo-1 {
            background-image: url('../images/assets/app/layout/background-dots-blue-shiny.svg?#{$timestamp}');

            &:after {
                top: -100px;
                bottom: auto;
                height: calc(100% - 100px);
            }
        }
    }
}

.clm-wrapper,
.flex-wrapper {
    position: relative;

    > .clm {

        &.graphic-overlap {
            display: flex;
            flex-direction: column;
            @include gap(40px 0);

            > .mixin-image-wrapper {

                img {
                    width: 100%;
                    height: auto;
                    max-width: none;
                    max-height: none;
                }

                &.oversize {

                    img {
                        width: calc(100% + 100px);
                    }
                }
            }
        }
    }

    &.key-value {
        @include gap(0 20px !important);

        > .clm,
        > .flex-item {
            display: flex;
            padding: 0 !important;
            @include gap(10px 0);

            > * {
                max-width: 100%;
            }

            > .float-right {
                margin-left: auto;
                @include flex(0 0 auto);
            }

            p {
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 500;
                hyphens: none;

                a,
                .link-style {
                    display: inline-block;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: 300 !important;
                }

                .box {
                    padding: 0 5px;
                    font: inherit;
                    font-size: $font-size-14px;
                    font-weight: 600;
                    border: 1px solid $color-grey-dark;
                    text-transform: uppercase;
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                }

                &:first-letter {
                    text-transform: capitalize;
                }

                &.edit-entry {

                    &:first-letter {
                        text-transform: none;
                    }
                }
            }

            &:first-child {
                @include flex(0 0 auto);
            }

            &:last-child {
                @include flex(1 1 auto);
                @include gap(0 10px);

                .select2 {
                    top: 4px;
                    margin: -7px 0 5px 0;
                }

                > .float-right {

                    .icon {
                        color: $color-green-middle;
                        font-size: 14px;
                        line-height: 28px;

                        &.icon-checkmark {
                            top: 1px;
                            width: 22px;
                            font-size: 13px;
                            text-align: center;

                            &:after {
                                content: '';
                                position: absolute;
                                top: 2px;
                                left: 0;
                                z-index: -1;
                                width: 22px;
                                height: 22px;
                                background: $color-green-bright;
                                @include border-radius(50%, 50%, 50%, 50%);
                            }
                        }

                        &.icon-cloud {
                            top: -1px;
                            left: 1px;
                            font-size: 22px;
                            color: $color-blue-logo-2;
                        }

                        &.icon-x,
                        &.icon-pending,
                        &.icon-warning {
                            top: -1px;
                            left: 1px;
                            font-size: 22px;
                            color: $color-grey-dark;
                        }
                    }
                }
            }
        }

        .icon,
        .icon-wrapper {
            top: 1px;
            @include flex(0 1);

            &.icon {
                font-size: 20px;
            }
        }

        &.justify-content-space-between {

            .clm,
            .flex-item {

                &:last-child {
                    @include flex(0 1 auto);
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.tile-wrapper {
    position: relative;
    margin-bottom: 40px;
    padding: 40px 30px;
    background: $color-grey-white;
    overflow: hidden;
    @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.2));
    @include border-radius($border-radius-5px, $border-radius-5px, $border-radius-5px, $border-radius-5px);

    .tile-badge {
        position: relative;
        bottom: -40px;
        margin: 0 -30px;
        padding: 5px 10px;
        line-height: 1em;
        text-align: center;
        background: $color-green-middle;
        @include border-radius(0, 0, $border-radius-5px, $border-radius-5px);

        span {
            color: $color-grey-white;
            font-size: $font-size-14px;
            font-weight: 500;
        }

        .icon {
            top: 4px;
            margin-right: 10px;
            color: $color-grey-white;
            font-size: 14px;

            &.icon-warning {
                top: 3px;
                font-size: 16px;
            }
        }

        &.incomplete {
            background: $color-grey-dark;
        }
    }

    &.border-top-color-blue-logo-1 {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 10px;
            background: $color-blue-logo-1;
        }
    }

    &.border-bottom-color-blue-logo-1 {

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 10px;
            background: $color-blue-logo-1;
        }
    }
}

.table-wrapper {
    margin: 20px 0;
    border: 1px solid $color-blue-bright;
    overflow-x: auto;
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
}

.form-trailer {
    margin-top: 30px;

    p {
        margin-bottom: 10px;
        font-size: $font-size-16px;
        text-align: center;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.uniform {
    margin: 4px 8px 0 2px;
    width: 18px;
    height: 18px;
    line-height: 18px;

    &.uniform-radio,
    &.uniform-checkbox {

        &:before {
            bottom: 0;
            width: 100%;
            height: 100%;
            border-color: $color-blue-bright;
            @include opacity(1);
        }
    }

    &.uniform-checkbox {

        &:after {
            content: '\e90a';
            bottom: 5px;
            color: $color-green-middle;
            font-size: 13px;
            font-family: 'icomoon';
            line-height: 18px;
            @include opacity(1);
        }
    }

    &.uniform-radio {

        &:after {
            bottom: 6px;
            background: $color-blue-black;
            @include opacity(0.7);
        }
    }
}

.language-hide {
    display: none !important;
}

.box-shadow-item {
    @include box-shadow(-10px, 20px, 50px, -15px, rgba(0, 0, 0, 0.7));
}

.icon {

    &.button {
        padding: 3px !important;
        color: $color-blue-logo-2 !important;
        font-size: 16px !important;
        line-height: 1em !important;
        border: 1px solid $color-blue-logo-2 !important;
        cursor: pointer;
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
        @include transition(all, 0.3s, ease-out, 0s);

        &:hover:not(.no-css-hover) {
            color: $color-green-middle;
            border-color: $color-green-middle;
        }
    }
}

[data-overlay-url],
[data-overlay-src],
[data-overlay-data],
[data-overlay-content] {
    cursor: pointer;
}

[data-currency] {
    font-variant: tabular-nums !important;

    &:after {
        content: attr(data-currency) !important;
        padding-left: 3px !important;
    }
}

html.isIOS {

    .uniform {

        &.uniform-radio,
        &.uniform-checkbox {

            &:after {
                bottom: 6px;
            }
        }
    }
}

@media screen and (min-width: 1601px) {

    .section-content {

        .wrapper-section-content {

            &.content-oversize-top {
                margin-top: 120px;
            }

            &.content-oversize-bottom {
                margin-bottom: 120px;
            }
        }

        &.content-oversize-top {
            padding-top: 80px;
        }

        &.content-oversize-bottom {
            padding-bottom: 80px;
        }
    }
}

@media screen and (max-width: 1600px) {

    .clm-wrapper,
    .flex-wrapper {

        > .clm {

            &.graphic-overlap {

                > .mixin-image-wrapper {

                    &.oversize {

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {

    .section-content {

        .wrapper-section-content {

            .clm-wrapper:not(.division-header) {

                > .clm {

                    &.clm-3:not(.row) {
                        width: 50%;
                    }
                }
            }
        }

        &:first-child {
            background: none !important;
        }
    }
}

@media screen and (max-width: 1000px) {

    .section-content {

        .wrapper-section-content {

            .clm-wrapper:not(.division-header) {
                // padding: 10px 0;

                > .clm {

                    &[class*='clm clm-'] {
                        width: 100% !important;
                        padding: 0 !important;
                    }

                    &.no-content,
                    &.max-width-1000px-display-none {
                        display: none;

                        ~ :nth-child(2) {

                            .mixin-article-header {
                                margin-top: 0;
                            }
                        }
                    }

                    &:first-child {

                        .mixin-article-header {
                            margin-top: 0;

                        }
                    }
                }

                &.equal-distribution {
                    margin: 0;
                }

                &.max-width-1000px-flex-direction-column-reverse {
                    flex-direction: column-reverse;
                }

                // &:after {
                //     content: '';
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 100%;
                //     height: 1px;
                //     background: linear-gradient(90deg, $color-blue-logo-2-opacity-015p, $color-blue-logo-2-opacity-015p 50%, $transparent 0, $transparent);
                //     background-size: 8px 1px;
                //     pointer-events: none;
                // }

                // &:last-child {

                //     &:after {
                //         content: none;
                //     }
                // }
            }
        }
    }

    .clm-wrapper,
    .flex-wrapper {

        > .clm {

            &.graphic-overlap {
                 @include gap(0);
            }
        }

        &.key-value {

            > .clm,
            > .flex-item {
                width: 100%;

                &:last-child {

                    p {
                        color: $color-blue-grey;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    .section-content {

        .wrapper-section-content {
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 500px) {

    .clm-wrapper,
    .flex-wrapper {

        &.key-value {

            > .clm,
            > .flex-item {

                p {
                    font-weight: 600;

                    a,
                    .link-style {
                        font-weight: 400 !important;
                    }
                }
            }
        }
    }

    .section-content {

        .wrapper-section-content {
            margin-bottom: 20px;

            .clm-wrapper {

                > .clm {
                    padding: 0 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {

    .section-content {

        .wrapper-section-content {
            margin-bottom: 10px;
        }
    }

    .tile-wrapper {
        padding: 25px 15px;

        .tile-badge {
            bottom: -25px;
            right: -15px;
        }
    }
}

/* == CONTENT-WRAPPER ====================================================== */

.center-oversize-ctn {
    margin: 0 auto;
    max-width: 1400px;
}

.center-wide-ctn {
    margin: 0 auto;
    max-width: 1200px;
}

.center-middle-ctn {
    margin: 0 auto;
    max-width: 1000px;
}

.center-menu-ctn {
    margin: 0 100px;
}

@media screen and (max-width: 1600px) {

    .center-oversize-ctn {
        max-width: calc(100% - 200px);
    }
}

@media screen and (max-width: 1400px) {

    .center-wide-ctn {
        max-width: calc(100% - 200px);
    }
}

@media screen and (max-width: 1200px) {

    .center-oversize-ctn,
    .center-wide-ctn,
    .center-middle-ctn {
        max-width: calc(100% - 120px);
    }
}

@media screen and (max-width: 1100px) {

    .center-oversize-ctn,
    .center-wide-ctn,
    .center-middle-ctn {
        max-width: calc(100% - 80px);
    }
}

@media screen and (max-width: 1000px) {

    .center-oversize-ctn,
    .center-wide-ctn,
    .center-middle-ctn {

        > .clm-wrapper {

            > .clm {

                &.clm-2 {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    .center-menu-ctn {
        margin: 0 80px;
    }
}

@media screen and (max-width: 700px) {

    .center-oversize-ctn,
    .center-wide-ctn,
    .center-middle-ctn {
        max-width: calc(100% - 60px);
    }
}

@media screen and (max-width: 500px) {

    .center-oversize-ctn,
    .center-wide-ctn,
    .center-middle-ctn {
        max-width: calc(100% - 40px);
    }
}

@media screen and (max-width: 750px) {

    .center-menu-ctn {
        margin: 0 40px;
    }
}

@media screen and (max-width: 650px) {

    .center-menu-ctn {
        margin: 0 30px;
    }
}

@media screen and (max-width: 500px) {

    .center-menu-ctn {
        margin: 0 20px;
    }
}

/* == BACKGROUND ====================================================== */

.bg-color-grey-white {
    background-color: $color-grey-white;
}

.bg-color-grey-shiny {
    background-color: $color-grey-shiny;
}

.bg-color-grey-light {
    background-color: $color-grey-light;
}

.bg-color-grey-bright {
    background-color: $color-grey-bright;
}

.bg-color-grey-middle {
    background-color: $color-grey-middle;
}

.bg-color-grey-dark {
    background-color: $color-grey-dark;
}

.bg-color-grey-dark-i {
    background-color: $color-grey-dark !important;
}

.bg-color-blue-logo-1 {
    background-color: $color-blue-logo-1;

    h1,
    h2,
    h3 {
        color: $color-blue-dark;
    }

    p {
        color: $color-grey-white;

        a {
            color: $color-blue-dark !important;
            font-weight: 500 !important;
        }
    }
}

.bg-color-blue-logo-1-i {
    background-color: $color-blue-logo-1 !important;
}

.bg-color-blue-logo-2 {
    background-color: $color-blue-logo-2;

    h1,
    h2,
    h3 {
        color: $color-grey-white;
    }

    p {
        color: $color-blue-bright;
    }
}

.bg-color-blue-logo-2-i {
    background-color: $color-blue-logo-2 !important;
}

.bg-color-blue-dark {
    background-color: $color-blue-dark;
}

.bg-color-blue-black {
    background-color: $color-blue-black;
}

.bg-color-blue-black-i {
    background-color: $color-blue-black !important;
}

.bg-color-green-bright {
    background-color: $color-green-bright;
}

.bg-color-green-middle {
    background-color: $color-green-middle;
}

.bg-color-green-middle-i {
    background-color: $color-green-middle !important;
}

.bg-color-purple-middle {
    background-color: $color-purple-middle;
}

.bg-color-purple-middle-i {
    background-color: $color-purple-middle !important;
}

.bg-color-transparent-i {
    background-color: $transparent !important;
}

.bg-skew {
    position: relative;
    padding-top: 120px;
    min-height: 300px;
    background-color: $transparent;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        transform: skewY(-7deg);
        background: $color-grey-white;
    }

    &:after {
        background: $color-grey-white;
    }

    &.skew-top {

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 50%;
        }
    }

    &.skew-bottom {
        margin-bottom: 120px;
        padding-top: 40px;

        &:after {
            content: '';
            position: absolute;
            bottom: 50%;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 50%;
        }
    }

    &.skew-half {
        padding-top: 40px !important;

        &:before {
            top: 50%;
            height: 50%;
        }

        &:after {
            content: none;
        }
    }

    &.bg-color-blue-logo-1 {

        &:before,
        &:after {
            background: $color-blue-logo-1;
        }
    }

    &.bg-color-blue-logo-2 {

        &:before,
        &:after {
            background: $color-blue-logo-2;
        }
    }

    &.bg-color-grey-light {

        &:before,
        &:after {
            background: $color-grey-light;
        }
    }
}

@media screen and (max-width: 1000px) {

    .bg-skew {

        &.skew-bottom {
            margin-bottom: 80px;
            padding-bottom: 0;
        }
    }
}

@media screen and (max-width: 800px) {

    .bg-skew {
        padding-top: 100px;

        &.skew-bottom {
            margin-bottom: 60px;
            padding-bottom: 40px;
        }
    }
}

@media screen and (max-width: 400px) {

    .bg-skew {
        padding-top: 80px;

        .mixin-quotes {
            margin-top: 20px !important;
        }

        &.skew-bottom {
            margin-bottom: 40px;
            padding-bottom: 60px;
        }
    }
}

/* == COLOR ====================================================== */

.color-green-middle {
    color: $color-green-middle;
}

.color-green-middle-i {
    color: $color-green-middle !important;
}

.color-purple-middle {
    color: $color-purple-middle;
}

.color-purple-middle-i {
    color: $color-purple-middle !important;
}

/* == BORDER-COLOR ====================================================== */

.border-color-grey-white {
    border-color: $color-grey-white;
}

.border-color-grey-shiny {
    border-color: $color-grey-shiny;
}

.border-color-grey-light {
    border-color: $color-grey-light;
}

.border-color-grey-middle {
    border-color: $color-grey-middle;
}

.border-color-blue-logo-1 {
    border-color: $color-blue-logo-1;
}

.border-color-blue-logo-2 {
    border-color: $color-blue-logo-2;
}

.border-color-green-bright {
    border-color: $color-green-bright;
}

.border-color-green-middle {
    border-color: $color-green-middle;
}

.border-color-green-middle-i {
    border-color: $color-green-middle !important;
}

.border-color-purple-middle {
    border-color: $color-purple-middle;
}

.border-color-purple-middle-i {
    border-color: $color-purple-middle !important;
}
