@use '../../basics/globals' as *;

.mixin-quotes {
    position: relative;
    margin-bottom: 20px !important;

    .mixin-article {

        .mixin-article-header {
            margin: -20px auto 0 auto;
            max-width: 900px;

            h2 {
                font-size: $font-size-50px;
                line-height: 1.1em;
            }

            .wrapper-icon {
                position: absolute;
                top: -100px;
                left: -90px;
                z-index: -1;
                height: auto;
                margin: 0;
                padding: 0;
                text-align: left;

                .icon {
                    color: $color-green-middle;
                    font-size: 200px;
                }

                &:before {
                    border: none;
                    background: none;
                }

                &:after {
                    content: none;
                }
            }

            .subline {
                padding: 30px 0 0 5px;
                font-size: $font-size-24px;
            }
        }

        .copy {
            margin: 20px auto 80px auto;
            padding-right: 200px;
            max-width: 900px;

            p {
                font-weight: 500;
            }
        }
    }

    .mixin-image-strip {
        margin: 50px 0;
        padding: 0 60px;
        background: $color-blue-dark;

        .img-wrapper {
            display: block;
        }
    }

    .background-wrapper {
        position: absolute;
        top: -50px;
        bottom: -10%;
        right: 0;
        width: 15%;
        background: $color-green-middle;
        transform: skewY(-7deg);
    }
}

@media screen and (max-width: 1700px) {

    .mixin-quotes {

        .mixin-image-strip {

            .img-wrapper {

                &:nth-child(7) {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {

    .mixin-quotes {

        .mixin-image-strip {

            .img-wrapper {

                &:nth-child(8) {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {

    .mixin-quotes {

        .mixin-article {

            .mixin-article-header {

                .wrapper-icon {
                    left: -20px;
                }
            }
        }

        .background-wrapper {
            display: none;
        }
    }
}

@media screen and (max-width: 1200px) {

    .mixin-quotes {

        .mixin-image-strip {

            .img-wrapper {

                &:nth-child(5) {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-quotes {

        .mixin-article {

            .mixin-article-header {

                .wrapper-icon {
                    top: -120px;
                }
            }

            .copy {
                margin-bottom: 0;
                padding-right: 0;
            }
        }

        .mixin-image-strip {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-quotes {

        .mixin-article {

            .mixin-article-header {

                .wrapper-icon {
                    left: 0;
                }
            }
        }
    }
}
