@charset "UTF-8";
/* == CORE / GLOBALS / BASICS ====================================================== */
/* == COMMON ====================================================== */
/* == FLEX ====================================================== */
/* == COLUMN ====================================================== */
/* == GAP ====================================================== */
/* == TEXT & FONTS ====================================================== */
/* == BOX-SHADOW ====================================================== */
/* == GRADIENT ====================================================== */
/* == ROTATE ====================================================== */
/* == SCALE ====================================================== */
/* == CALC ====================================================== */
/* == CLIP-PATH ====================================================== */
/* == TRANSFORM ====================================================== */
/* == TRANSITION ====================================================== */
/* @include transition(opacity, 0.5s, ease-out, 0s); */
/* == ANIMATION ====================================================== */
/* == CURSOR ====================================================== */
/* == IMG ====================================================== */
/* == BG-IMG ====================================================== */
/* == RESET ====================================================== */
* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  color: #000;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.6em;
  vertical-align: top;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  height: 100%;
}

body {
  font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-height: 100%;
  position: relative;
}

address,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

picture {
  display: inline-block;
}
picture img {
  width: 100%;
  height: 100%;
}

img {
  display: inline-block;
  font-size: 0;
  width: auto;
  height: auto;
}

ul {
  font-size: 0;
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover,
a:focus,
img:focus {
  border: none;
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  background: #000;
}

em {
  font: inherit;
  color: inherit;
}

form {
  display: block;
}

input,
select {
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
  overflow: auto;
  vertical-align: top;
  border: 1px solid #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

input[type=text],
input[type=number],
input[type=password] {
  border: 1px solid #000;
}

audio,
canvas,
video {
  display: block;
}

video {
  object-fit: cover;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

b,
strong {
  font-weight: bold;
}

svg:not(:root) {
  overflow: hidden;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[class^=icon-],
[class*=" icon-"] {
  display: inline-block;
  position: relative;
  font-family: "icomoon" !important;
  speak: none !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  text-align: center !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=icon-] span,
[class*=" icon-"] span {
  font-family: "icomoon" !important;
  speak: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-variant: inherit !important;
  text-transform: inherit !important;
  line-height: inherit;
}
[class^=icon-] .visually-hidden,
[class*=" icon-"] .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  position: relative;
  z-index: 1;
}

/* == NATIVE - COMMON ====================================================== */
.clear-both {
  clear: both;
}

.clear-both-i {
  clear: both !important;
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-nowrap-i {
  white-space: nowrap !important;
}

.white-space-normal {
  white-space: normal;
}

.white-space-normal-i {
  white-space: normal !important;
}

.visibility-hidden {
  visibility: hidden;
}

.word-break-break-all {
  word-break: break-all;
}

.list-style-position-inside {
  list-style-position: inside;
}

.list-style-type-decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}

.gap-0-10px {
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}

.column-count-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

/* == NATIVE - DISPLAY ====================================================== */
.display-none {
  display: none;
}

.display-none-i {
  display: none !important;
}

.display-block {
  display: block;
}

.display-block-i {
  display: block !important;
}

.display-inline {
  display: inline;
}

.display-inline-i {
  display: inline !important;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-block-i {
  display: inline-block !important;
}

/* == NATIVE - FLEX ====================================================== */
.flex-0-0-auto {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}

.flex-0-0-auto-i {
  -webkit-flex: 0 0 auto !important;
  -moz-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  -o-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
}

.flex-1-0-auto {
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}

.flex-1-0-auto-i {
  -webkit-flex: 1 0 auto !important;
  -moz-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  -o-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}

.flex-0-1-auto {
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}

.flex-0-1-auto-i {
  -webkit-flex: 0 1 auto !important;
  -moz-flex: 0 1 auto !important;
  -ms-flex: 0 1 auto !important;
  -o-flex: 0 1 auto !important;
  flex: 0 1 auto !important;
}

.flex-1-1-auto {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-1-1-auto-i {
  -webkit-flex: 1 1 auto !important;
  -moz-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  -o-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-0-0-100p {
  -webkit-flex: 0 0 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  -o-flex: 0 0 100%;
  flex: 0 0 100%;
}

.flex-0-0-100p-i {
  -webkit-flex: 0 0 100% !important;
  -moz-flex: 0 0 100% !important;
  -ms-flex: 0 0 100% !important;
  -o-flex: 0 0 100% !important;
  flex: 0 0 100% !important;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-right-i {
  justify-content: right !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-center-i {
  justify-content: center !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-between-i {
  justify-content: space-between !important;
}

/* == NATIVE - POSITION ====================================================== */
.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

/* == NATIVE - Z-INDEX ====================================================== */
.z-index--1 {
  z-index: -1;
}

.z-index--1-i {
  z-index: -1 !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-2-i {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3;
}

.z-index-3-i {
  z-index: 3 !important;
}

.z-index-10 {
  z-index: 10;
}

.z-index-10-i {
  z-index: 10 !important;
}

/* == NATIVE - FLOAT ====================================================== */
.float-left {
  float: left;
}

.float-left-i {
  float: left !important;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.float-none-i {
  float: none !important;
}

/* == NATIVE - TEXT ====================================================== */
.text-align-left {
  text-align: left;
}

.text-align-left-i {
  text-align: left !important;
}

.text-align-right {
  text-align: right;
}

.text-align-right-i {
  text-align: right !important;
}

.text-align-center {
  text-align: center;
}

.text-align-center-i {
  text-align: center !important;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-lowercase-i {
  text-transform: lowercase !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-uppercase-i {
  text-transform: uppercase !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-none-i {
  text-decoration: none !important;
}

/* == NATIVE - VERTICAL-ALIGN ====================================================== */
.vertical-align-top {
  vertical-align: top;
}

.vertical-align-top-i {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.vertical-align-inherit {
  vertical-align: inherit;
}

/* == NATIVE - OVERFLOW ====================================================== */
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* == NATIVE - BORDER ====================================================== */
.border-top-none {
  border-top: none;
}

.border-bottom-none {
  border-bottom: none;
}

.border-bottom-none-i {
  border-bottom: none !important;
}

.border-radius-3px {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

.border-radius-5px {
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.border-radius-10px {
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

.border-radius-20px {
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -ms-border-radius: 20px 20px 20px 20px;
  -o-border-radius: 20px 20px 20px 20px;
  border-radius: 20px 20px 20px 20px;
}

.border-radius-30px {
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}

/* == NATIVE VALUES - TOP ====================================================== */
.top-0 {
  top: 0;
}

.top-2px {
  top: 2px;
}

.top--2px {
  top: -2px;
}

.top--2px-i {
  top: -2px !important;
}

.top-3px {
  top: 3px;
}

.top-3px-i {
  top: 3px !important;
}

.top-4px {
  top: 4px;
}

.top-5px {
  top: 5px;
}

.top-8px {
  top: 8px;
}

.top-100 {
  top: 100%;
}

/* == NATIVE VALUES - BOTTOM ====================================================== */
.bottom-0 {
  bottom: 0;
}

.bottom-10px {
  bottom: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.left-auto-i {
  left: auto !important;
}

.left-0 {
  left: 0;
}

.left-10 {
  left: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.right-0 {
  right: 0;
}

.right-10px {
  right: 10px;
}

/* == NATIVE VALUES - WIDTH ====================================================== */
.width-20px {
  width: 20px;
}

.width-20px-i {
  width: 20px !important;
}

.width-33px {
  width: 33px;
}

.width-34px {
  width: 34px;
}

.width-50px {
  width: 50px;
}

.width-80px {
  width: 80px;
}

.width-100px {
  width: 100px;
}

.width-110px {
  width: 110px;
}

.width-120px {
  width: 120px;
}

.width-120px-i {
  width: 120px !important;
}

.width-140px {
  width: 140px;
}

.width-150px {
  width: 150px;
}

.width-160px {
  width: 160px;
}

.width-170px {
  width: 170px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-280px {
  width: 280px;
}

.width-300px {
  width: 300px;
}

.width-330px {
  width: 330px;
}

.width-350px {
  width: 350px;
}

.width-400px {
  width: 400px;
}

.width-500px {
  width: 500px;
}

.width-600px {
  width: 600px;
}

.width-100p {
  width: 100%;
}

/* == NATIVE VALUES - MIN WIDTH ====================================================== */
.min-width-300px {
  min-width: 300px;
}

.min-width-400px {
  min-width: 400px;
}

.min-width-640px {
  min-width: 640px;
}

/* == NATIVE VALUES - MAX-WIDTH ====================================================== */
.max-width-100px {
  max-width: 100px;
}

.max-width-110px {
  max-width: 110px;
}

.max-width-130px {
  max-width: 130px;
}

.max-width-170px {
  max-width: 170px;
}

.max-width-200px {
  max-width: 200px;
}

.max-width-280px-i {
  max-width: 280px !important;
}

.max-width-600px {
  max-width: 600px;
}

/* == NATIVE VALUES - HEIGHT ====================================================== */
.height-auto-i {
  height: auto !important;
}

.height-1px {
  height: 1px;
}

.height-20px {
  height: 20px;
}

.height-26px {
  height: 26px;
}

.height-34px {
  height: 34px;
}

.height-100px {
  height: 100px;
}

.height-100px-i {
  height: 100px !important;
}

.height-195px {
  height: 195px;
}

.height-350px {
  height: 350px;
}

.height-050p {
  height: 50%;
}

.height-050p-i {
  height: 50% !important;
}

.height-100p {
  height: 100%;
}

.height-100p-i {
  height: 100% !important;
}

/* == NATIVE VALUES - MIN HEIGHT ====================================================== */
.min-height-40px {
  min-height: 40px;
}

.min-height-40px-i {
  min-height: 40px !important;
}

/* == NATIVE VALUES - MAX HEIGHT ====================================================== */
.max-height-100px {
  max-height: 100px;
}

/* == NATIVE VALUES - MARGIN ====================================================== */
.margin-3px {
  margin: 3px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-i {
  margin-top: 0 !important;
}

.margin-top-2px {
  margin-top: 2px;
}

.margin-top-4px {
  margin-top: 4px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-10px-i {
  margin-top: 10px !important;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-20px-i {
  margin-top: 20px !important;
}

.margin-top-25px {
  margin-top: 25px;
}

.margin-top-25px-i {
  margin-top: 25px !important;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-top-30px-i {
  margin-top: 30px !important;
}

.margin-top-40px {
  margin-top: 40px;
}

.margin-top-40px-i {
  margin-top: 40px !important;
}

.margin-top-50px {
  margin-top: 50px;
}

.margin-top-50px-i {
  margin-top: 50px !important;
}

.margin-top-60px {
  margin-top: 60px;
}

.margin-top-80px {
  margin-top: 80px;
}

.margin-top--1px {
  margin-top: -1px;
}

.margin-top--20px {
  margin-top: -20px;
}

.margin-top--34px {
  margin-top: -34px;
}

.margin-top--060p {
  margin-top: -60%;
}

.margin-top--100p {
  margin-top: -100%;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-20px {
  margin-left: 20px;
}

.margin-left-25px {
  margin-left: 25px;
}

.margin-left-30px {
  margin-left: 30px;
}

.margin-left-310px {
  margin-left: 310px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-right-20px {
  margin-right: 20px;
}

.margin-right-30px {
  margin-right: 30px;
}

.margin-right-40px {
  margin-right: 40px;
}

.margin-right-48px {
  margin-right: 48px;
}

.margin-right-60px {
  margin-right: 60px;
}

.margin-right--10px {
  margin-right: -10px;
}

.margin-right--20px {
  margin-right: -20px;
}

.margin-right--40px {
  margin-right: -40px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-i {
  margin-bottom: 0 !important;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-bottom-10px-i {
  margin-bottom: 10px !important;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-40px {
  margin-bottom: 40px;
}

.margin-bottom-40px-i {
  margin-bottom: 40px !important;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.margin-bottom-60px-i {
  margin-bottom: 60px !important;
}

.margin-bottom-80px {
  margin-bottom: 80px;
}

.margin-bottom--10px {
  margin-bottom: -10px;
}

.margin-bottom--10px-i {
  margin-bottom: -10px !important;
}

.margin-bottom--50px {
  margin-bottom: -50px;
}

.margin-bottom--240px {
  margin-bottom: -240px;
}

.margin-vertical--20px {
  margin-left: -20px;
  margin-right: -20px;
}

/* == NATIVE VALUES - PADDING ====================================================== */
.adding-0 {
  padding: 0;
}

.padding-0-i {
  padding: 0 !important;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-i {
  padding-top: 0 !important;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-20px-i {
  padding-top: 20px !important;
}

.padding-top-25px {
  padding-top: 25px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-i {
  padding-bottom: 0 !important;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.padding-bottom-20px-i {
  padding-bottom: 20px !important;
}

.padding-bottom-25px {
  padding-bottom: 25px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-i {
  padding-left: 0 !important;
}

.padding-left-3px-i {
  padding-left: 3px !important;
}

.padding-left-5px {
  padding-left: 5px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-20px {
  padding-left: 20px;
}

.padding-left-25px-i {
  padding-left: 25px !important;
}

.padding-left-150px {
  padding-left: 150px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-i {
  padding-right: 0 !important;
}

.padding-right-3px {
  padding-right: 3px;
}

.padding-right-3px-i {
  padding-right: 3px !important;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-10px {
  padding-right: 10px;
}

.padding-right-20px {
  padding-right: 20px;
}

.padding-right-43px-i {
  padding-right: 43px !important;
}

.padding-horizontal-3px {
  padding-left: 3px;
  padding-right: 3px;
}

.padding-horizontal-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.padding-horizontal-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-horizontal-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-vertical-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.padding-vertical-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-vertical-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-3px {
  padding: 3px;
}

.padding-10px {
  padding: 10px;
}

/* == NATIVE VALUES - FONT SIZE ====================================================== */
.font-size-0 {
  font-size: 0;
}

.font-size-1em {
  font-size: 1em;
}

.font-size-1c6em {
  font-size: 1.6em;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-22px {
  font-size: 22px;
}

.font-size-24px {
  font-size: 24px;
}

.font-size-26px {
  font-size: 26px;
}

.font-size-28px {
  font-size: 28px;
}

.font-size-30px {
  font-size: 30px;
}

.font-size-32px {
  font-size: 32px;
}

.font-size-34px {
  font-size: 34px;
}

.font-size-38px {
  font-size: 38px;
}

.font-size-40px {
  font-size: 40px;
}

.font-size-80px {
  font-size: 80px;
}

/* == NATIVE VALUES - FONT WEIGHT ====================================================== */
.font-weight-200 {
  font-weight: 200;
}

.font-weight-200-i {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-300-i {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-400-i {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-500-i {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-600-i {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-700-i {
  font-weight: 700 !important;
}

/* == NATIVE VALUES - LINE HEIGHT ====================================================== */
.line-height-1em {
  line-height: 1em;
}

.line-height-26px {
  line-height: 26px;
}

.line-height-34px {
  line-height: 34px;
}

.line-height-37px {
  line-height: 37px;
}

/* == OPACITY ====================================================== */
.opacity-0 {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

.opacity-0-i {
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  -ms-filter: alpha(opacity=0) !important;
}

.opacity-1-i {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  -ms-filter: alpha(opacity=100) !important;
}

/* == HOVER ====================================================== */
.hover-text-decoration-underline:hover:hover:not(.no-css-hover) {
  text-decoration: underline;
}

/* == TAGS ====================================================== */
body {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  background: #fff;
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
body.scrolling iframe {
  pointer-events: none;
}
body.resizing * {
  -webkit-transition: all 0s linear 0s !important;
  -moz-transition: all 0s linear 0s !important;
  -ms-transition: all 0s linear 0s !important;
  -o-transition: all 0s linear 0s !important;
  transition: all 0s linear 0s !important;
}

img,
[data-lazy] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s ease-out 0s;
  -moz-transition: opacity 1s ease-out 0s;
  -ms-transition: opacity 1s ease-out 0s;
  -o-transition: opacity 1s ease-out 0s;
  transition: opacity 1s ease-out 0s;
}
img.img-loaded,
[data-lazy].img-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
img.img-lazy-waiting,
[data-lazy].img-lazy-waiting {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
img.img-lazy-loaded,
[data-lazy].img-lazy-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

/* == COMMON ====================================================== */
.box-square {
  position: relative;
  overflow: hidden;
}
.box-square:before {
  content: "";
  display: block;
  aspect-ratio: 1;
  height: 100%;
}
.box-square .box-square-ctn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow.box-shadow-huge {
  -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -ms-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -o-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
}
.box-shadow.box-shadow-small {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.css-table {
  display: table;
  width: 100%;
  height: 100%;
}
.css-table .css-cell {
  display: table-cell;
  vertical-align: middle;
}

.text-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

.line-break:after {
  content: "\a";
  white-space: pre;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.crop-at-show-more,
.crop-at-show-less {
  display: inline-block;
  padding-left: 5px;
  cursor: pointer;
}

.crop-text {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ctn-capitalize {
  position: relative;
}
.ctn-capitalize .ctn-cap {
  position: relative;
  color: inherit;
  font: inherit;
}

.clm-wrapper {
  display: flex;
  font-size: 0;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.clm-wrapper > .clm {
  display: inline-block;
}
.clm-wrapper > .clm.clm-1 {
  width: 100%;
}
.clm-wrapper > .clm.clm-2 {
  width: 50%;
}
.clm-wrapper > .clm.clm-3 {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-4 {
  width: 25%;
}
.clm-wrapper > .clm.clm-5 {
  width: 20%;
}
.clm-wrapper > .clm.clm-6 {
  width: 16.666666%;
}
.clm-wrapper > .clm.clm-7 {
  width: 14.285%;
}
.clm-wrapper > .clm.clm-7x3 {
  width: 42.855%;
}
.clm-wrapper > .clm.clm-7x4 {
  width: 57.14%;
}
.clm-wrapper > .clm.clm-8 {
  width: 12.5%;
}
.clm-wrapper > .clm.clm-9 {
  width: 11.111111%;
}
.clm-wrapper > .clm.clm-20p {
  width: 20%;
}
.clm-wrapper > .clm.clm-25p {
  width: 25%;
}
.clm-wrapper > .clm.clm-30p {
  width: 30%;
}
.clm-wrapper > .clm.clm-33p {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-35p {
  width: 35%;
}
.clm-wrapper > .clm.clm-40p {
  width: 40%;
}
.clm-wrapper > .clm.clm-45p {
  width: 45%;
}
.clm-wrapper > .clm.clm-55p {
  width: 55%;
}
.clm-wrapper > .clm.clm-50p {
  width: 50%;
}
.clm-wrapper > .clm.clm-60p {
  width: 60%;
}
.clm-wrapper > .clm.clm-65p {
  width: 65%;
}
.clm-wrapper > .clm.clm-66p {
  width: 66.666666%;
}
.clm-wrapper > .clm.clm-70p {
  width: 70%;
}
.clm-wrapper > .clm.clm-75p {
  width: 75%;
}
.clm-wrapper > .clm.clm-80p {
  width: 80%;
}
.clm-wrapper > .clm.clm-83p {
  width: 83.334%;
}
.clm-wrapper.nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.uniform {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.uniform input {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.uniform.uniform-radio:before {
  bottom: 3px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-radio:after {
  bottom: 8px;
  width: 8px;
  height: 8px;
  background: #181818;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-checkbox:before {
  bottom: 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.uniform.uniform-checkbox:after {
  content: "✓";
  height: 1em;
  bottom: 8px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: alpha(opacity=80);
}
.uniform.uniform-checked:after {
  display: block;
}
.uniform:before, .uniform:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.uniform:before {
  width: 16px;
  height: 16px;
  border: 1px solid #181818;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
}
.uniform:after {
  display: none;
}

html.isFirefox .uniform.uniform-checkbox:before {
  bottom: 3px;
}

[data-set-parent-height] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
[data-set-parent-height][style] {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

[data-trigger-href] {
  cursor: pointer;
}

[data-clone] {
  cursor: pointer;
}

/* == OLD BROWSER ====================================================== */
#old-browser {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #181818;
}
#old-browser h1 {
  padding: 0 20px;
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  background: none;
}
#old-browser p {
  margin-top: 20px;
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.6em;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#old-browser p span,
#old-browser p a {
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
}
#old-browser p a {
  text-decoration: underline;
  cursor: pointer;
}

/* == CORE / GLOBALS / PLUGINS ====================================================== */
.grecaptcha-badge {
  display: none !important;
}

/* == CORE / GLOBALS / MODULES ====================================================== */
#main {
  position: relative;
}

/* == CORE / APP / BASICS ====================================================== */
/* == CORE / APP / HELPERS ====================================================== */
[data-vue] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  -moz-transition: opacity 0.3s ease-out 0.2s;
  -ms-transition: opacity 0.3s ease-out 0.2s;
  -o-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s;
}
[data-vue][data-v-app] {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

/* == CORE / APP / PAGES ====================================================== */
#page-error {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  background-image: none;
  overflow-y: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#page-error h1 {
  padding: 0 20px;
  font-size: 70px;
  line-height: 1em;
}
#page-error p {
  margin-top: 20px;
  padding: 0 20px;
  font-size: 30px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#page-error a {
  margin-top: 20px;
  text-decoration: underline;
}
#page-error .icon {
  margin-bottom: 30px;
  color: #333;
  font-size: 100px;
}

@media screen and (max-width: 767px) {
  #page-error .icon {
    font-size: 60px;
  }
  #page-error h1 {
    font-size: 40px;
  }
  #page-error p {
    font-size: 20px;
  }
}
#page-maintenance {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  background-image: none;
  overflow-y: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#page-maintenance h1 {
  font-size: 30px;
  line-height: 1.3em;
}
#page-maintenance form {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 400px;
  text-align: left;
}
#page-maintenance form label.mandatory:after {
  content: "*";
  margin-left: 5px;
}
#page-maintenance form input {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#page-maintenance form input[type=submit] {
  padding: 4px 5px;
  color: #fff;
  background: #333;
  text-transform: uppercase;
}
#page-maintenance .icon-excavator {
  margin-bottom: 30px;
  color: #333;
  font-size: 100px;
}
#page-maintenance .subline {
  margin-top: 10px;
  font-size: 20px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

/* == PROJECT / APP / BASICS ====================================================== */
@font-face {
  font-family: "icomoon";
  src: url("../icons/app/icomoon.eot?1744355188281");
  src: url("../icons/app/icomoon.eot?1744355188281#iefix") format("embedded-opentype"), url("../icons/app/icomoon.ttf?1744355188281") format("truetype"), url("../icons/app/icomoon.woff?1744355188281") format("woff"), url("../icons/app/icomoon.svg?1744355188281#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon-logo-icon:before {
  content: "\e90d";
}

.icon-logo-icon-font:before {
  content: "\e912";
}

.icon-arrow-long-right:before {
  content: "\e90f";
}

.icon-arrow-left:before {
  content: "\e916";
}

.icon-arrow-right:before {
  content: "\e91b";
}

.icon-arrows-left:before {
  content: "\e929";
}

.icon-arrows-right:before {
  content: "\e92a";
}

.icon-arrow-up:before {
  content: "\e922";
}

.icon-arrow-down:before {
  content: "\e923";
}

.icon-app-icon-facebook:before {
  content: "\e905";
}

.icon-app-icon-google:before {
  content: "\e906";
}

.icon-app-icon-tripadvisor:before {
  content: "\e907";
}

.icon-app-icon-share:before {
  content: "\e93d";
}

.icon-change:before {
  content: "\e918";
}

.icon-enlarge:before {
  content: "\e919";
}

.icon-cloud:before {
  content: "\e917";
}

.icon-link:before {
  content: "\e91a";
}

.icon-lock:before {
  content: "\e92d";
}

.icon-shield:before {
  content: "\e92f";
}

.icon-off:before {
  content: "\e928";
}

.icon-copy:before {
  content: "\e935";
}

.icon-trash:before {
  content: "\e915";
}

.icon-edit:before {
  content: "\e911";
}

.icon-warning:before {
  content: "\e910";
}

.icon-checkmark:before {
  content: "\e90a";
}

.icon-x:before {
  content: "\e914";
}

.icon-pending:before {
  content: "\e927";
}

.icon-location:before {
  content: "\e92c";
}

.icon-search:before {
  content: "\e908";
}

.icon-filter:before {
  content: "\e909";
}

.icon-percentage:before {
  content: "\e902";
}

.icon-eye:before {
  content: "\e913";
}

.icon-eye-closed:before {
  content: "\e938";
}

.icon-star-filled:before {
  content: "\e904";
}

.icon-star:before {
  content: "\e903";
}

.icon-heart-filled:before {
  content: "\e924";
}

.icon-heart:before {
  content: "\e925";
}

.icon-refresh:before {
  content: "\e926";
}

.icon-account:before {
  content: "\e90b";
}

.icon-smiley:before {
  content: "\e940";
}

.icon-infinite:before {
  content: "\e941";
}

.icon-credit-card:before {
  content: "\e90c";
}

.icon-cutlery:before {
  content: "\e901";
}

.icon-settings:before {
  content: "\e91c";
}

.icon-order:before {
  content: "\e91d";
}

.icon-billing:before {
  content: "\e921";
}

.icon-statistics:before {
  content: "\e92e";
}

.icon-table:before {
  content: "\e92b";
}

.icon-fingerprint:before {
  content: "\e930";
}

.icon-delete:before {
  content: "\e931";
}

.icon-translations:before {
  content: "\e900";
}

.icon-bug:before {
  content: "\e90e";
}

.icon-drink:before {
  content: "\e91f";
}

.icon-rocket:before {
  content: "\e920";
}

.icon-send:before {
  content: "\e936";
}

.icon-play:before {
  content: "\e93e";
}

.icon-envelope:before {
  content: "\e937";
}

.icon-phone:before {
  content: "\e93f";
}

.icon-bot:before {
  content: "\e91e";
}

.icon-quotes:before {
  content: "\e933";
}

.icon-excavator:before {
  content: "\e932";
}

.icon-plan:before {
  content: "\e934";
}

.icon-logo-icon-facebook:before {
  content: "\e939";
}

.icon-logo-icon-linkedin:before {
  content: "\e93a";
}

.icon-logo-icon-x:before {
  content: "\e93b";
}

.icon-logo-icon-xing:before {
  content: "\e93c";
}

/* == TAGS ====================================================== */
* {
  font-family: "Open Sans", sans-serif;
  color: #628290;
  font-size: 18px;
  line-height: 1.5em;
  scrollbar-color: #aac3cd rgba(3, 127, 170, 0.05);
}

html {
  width: 100%;
  overflow-x: hidden;
}
html.isIframe {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
html.isIframe body {
  overflow: hidden !important;
  background: rgba(0, 0, 0, 0) !important;
}
html.isIframe.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.5s ease-out 3s;
  -moz-transition: opacity 0.5s ease-out 3s;
  -ms-transition: opacity 0.5s ease-out 3s;
  -o-transition: opacity 0.5s ease-out 3s;
  transition: opacity 0.5s ease-out 3s;
}
html.isBot * {
  -webkit-transition: all 0s ease-out 0s !important;
  -moz-transition: all 0s ease-out 0s !important;
  -ms-transition: all 0s ease-out 0s !important;
  -o-transition: all 0s ease-out 0s !important;
  transition: all 0s ease-out 0s !important;
}

body {
  width: 100%;
  min-width: 320px;
  background: #fbfeff;
  overflow-x: hidden;
  overflow-y: auto;
}
body:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(120deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(3, 127, 170, 0.05) 0, rgba(3, 127, 170, 0.05) 100%);
  pointer-events: none;
}

h1 {
  color: #037faa;
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1em;
  font-family: "Museo Slab", sans-serif;
}

h2 {
  color: #037faa;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.2em;
  font-family: "Museo Slab", sans-serif;
}

h3 {
  color: #037faa;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: "Museo Slab", sans-serif;
}

h4 {
  font-weight: 600;
}

p {
  hyphens: auto;
}
p a,
p .link-style,
p [data-overlay-url] {
  display: inline;
  color: #42bbef !important;
  font: inherit !important;
  line-height: inherit !important;
  hyphens: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
p a:hover:not(.no-css-hover),
p .link-style:hover:not(.no-css-hover),
p [data-overlay-url]:hover:not(.no-css-hover) {
  text-decoration: underline;
}
p em {
  font-weight: 500;
  hyphens: inherit;
}
p.small {
  font-size: 16px !important;
}
p.mini {
  font-size: 12px !important;
}

span.no-break {
  white-space: nowrap;
}

table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}
table * {
  font-size: 16px;
}
table tr {
  width: 100% !important;
}
table tr th,
table tr td {
  position: relative;
  padding: 7px 8px;
}
table tr th.mixin-table-th {
  max-width: 150px;
  border-right: 1px solid #aac3cd;
  border-bottom: 1px solid #aac3cd;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
table tr th.mixin-table-th .headline {
  max-width: 100%;
  display: inline-block;
  color: #099bda;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
table tr th.mixin-table-th.multilingual .headline {
  max-width: calc(100% - 40px);
}
table tr th:last-child {
  border-right: none;
}
table tr td {
  vertical-align: middle;
  word-break: break-all;
  text-align: center;
  border-right: 1px solid #aac3cd;
}
table tr td > a,
table tr td > .link-style {
  display: inline-block;
  color: inherit;
  font: inherit;
  text-decoration: underline;
}
table tr td > .icon {
  top: 5px;
}
table tr td > .icon.icon-x, table tr td > .icon.icon-pending, table tr td > .icon.icon-warning {
  font-size: 18px;
  top: 3px;
}
table tr td > .icon.text-before {
  margin-left: 15px;
}
table tr td > .icon.text-after {
  margin-right: 15px;
}
table tr td > .border-list {
  margin: 3px;
  padding: 3px;
  line-height: 1em;
  white-space: nowrap;
  border: 1px solid #628290;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
table tr td .mixin-btn {
  padding-top: 0;
  justify-content: center !important;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
table tr td .mixin-btn .btn {
  padding: 3px;
  width: auto;
  height: 24px;
  background: rgba(0, 0, 0, 0) !important;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
table tr td .mixin-btn .btn .icon {
  top: 1px;
  color: #037faa !important;
}
table tr td .mixin-btn .btn.disabled {
  color: #aac3cd !important;
  background: rgba(0, 0, 0, 0) !important;
}
table tr td .mixin-btn .btn.disabled .icon {
  color: inherit !important;
}
table tr td.counter {
  color: #aac3cd;
  white-space: nowrap;
}
table tr td.bold {
  font-weight: 400;
}
table tr td.discreet {
  color: #aac3cd;
}
table tr td:last-child {
  border: none;
}
table tr.discreet td {
  color: #aac3cd;
}
table tr:hover:not(.no-css-hover) td:first-child:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #676a6c;
}
table tr:nth-child(even) td {
  background: #d9e9f3;
}
table tr:nth-child(odd) td {
  background: #fbfeff;
}
table a {
  color: inherit;
  font: inherit;
}
table a:hover:not(.no-css-hover) {
  text-decoration: underline;
}
table span {
  color: inherit;
  font: inherit;
}
table .uniform {
  margin: 4px 0 0 0;
}

@media screen and (pointer: coarse) {
  * {
    font-weight: 500;
  }
}
@media screen and (max-width: 1400px) {
  table tr td {
    white-space: nowrap;
  }
}
@media screen and (max-width: 1000px), screen and (pointer: coarse) {
  body {
    padding-top: 66px;
  }
}
@media screen and (max-width: 500px) {
  * {
    font-weight: 400;
  }
  h1 {
    font-size: 40px;
    font-weight: 600;
    hyphens: auto;
  }
  h1 br {
    display: none;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    hyphens: auto;
  }
  h2 br {
    display: none;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    hyphens: auto;
  }
  h3 br {
    display: none;
  }
  h4 {
    font-weight: 600;
    hyphens: auto;
  }
  h4 br {
    display: none;
  }
  p {
    font-weight: 500;
  }
  p em {
    font-weight: 600;
  }
}
/* == PSEUDO CLASSES ====================================================== */
::selection {
  color: #fbfeff;
  background: #42bbef;
}

::-moz-selection {
  color: #fbfeff;
  background: #42bbef;
}

/* == COMMON ====================================================== */
.section-content {
  display: flex;
  padding: 40px 0;
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px 0;
  -moz-gap: 20px 0;
  -ms-gap: 20px 0;
  -o-gap: 20px 0;
  gap: 20px 0;
}
.section-content .wrapper-section-content {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 60px;
}
.section-content .wrapper-section-content .clm-wrapper {
  -webkit-gap: 20px 0;
  -moz-gap: 20px 0;
  -ms-gap: 20px 0;
  -o-gap: 20px 0;
  gap: 20px 0;
}
.section-content .wrapper-section-content .clm-wrapper > .clm {
  padding: 0 30px;
}
.section-content .wrapper-section-content .clm-wrapper > .clm:first-child {
  padding-left: 0;
}
.section-content .wrapper-section-content .clm-wrapper > .clm:last-child {
  padding-right: 0;
}
.section-content .wrapper-section-content .clm-wrapper > .clm.clm-2:nth-child(odd) {
  padding-left: 0;
}
.section-content .wrapper-section-content .clm-wrapper > .clm.clm-2:nth-child(even) {
  padding-right: 0;
}
.section-content .wrapper-section-content .clm-wrapper.equal-distribution {
  margin: 0 -30px;
}
.section-content .wrapper-section-content .clm-wrapper.equal-distribution > .clm:first-child {
  padding-left: 30px;
}
.section-content .wrapper-section-content .clm-wrapper.equal-distribution > .clm:last-child {
  padding-right: 30px;
}
.section-content .wrapper-section-content .clm-wrapper.equal-distribution > .clm.clm-2:nth-child(odd) {
  padding-left: 30px;
}
.section-content .wrapper-section-content .clm-wrapper.equal-distribution > .clm.clm-2:nth-child(even) {
  padding-right: 30px;
}
.section-content .wrapper-section-content .clm-wrapper.no-gap, .section-content .wrapper-section-content .clm-wrapper.division-header {
  -webkit-gap: 0;
  -moz-gap: 0;
  -ms-gap: 0;
  -o-gap: 0;
  gap: 0;
}
.section-content .wrapper-section-content:after {
  content: "";
  display: block;
  height: 1px;
}
.section-content:first-child {
  background-image: url("../images/assets/app/layout/background-dots-grey-light.svg?1744355188281");
  background-repeat: no-repeat;
  background-position: -30px 0;
  background-size: 750px;
}
.section-content:first-child.bg-color-blue-logo-1 {
  background-image: url("../images/assets/app/layout/background-dots-blue-shiny.svg?1744355188281");
}
.section-content:first-child.bg-color-blue-logo-1:after {
  top: -100px;
  bottom: auto;
  height: calc(100% - 100px);
}

.clm-wrapper,
.flex-wrapper {
  position: relative;
}
.clm-wrapper > .clm.graphic-overlap,
.flex-wrapper > .clm.graphic-overlap {
  display: flex;
  flex-direction: column;
  -webkit-gap: 40px 0;
  -moz-gap: 40px 0;
  -ms-gap: 40px 0;
  -o-gap: 40px 0;
  gap: 40px 0;
}
.clm-wrapper > .clm.graphic-overlap > .mixin-image-wrapper img,
.flex-wrapper > .clm.graphic-overlap > .mixin-image-wrapper img {
  width: 100%;
  height: auto;
  max-width: none;
  max-height: none;
}
.clm-wrapper > .clm.graphic-overlap > .mixin-image-wrapper.oversize img,
.flex-wrapper > .clm.graphic-overlap > .mixin-image-wrapper.oversize img {
  width: calc(100% + 100px);
}
.clm-wrapper.key-value,
.flex-wrapper.key-value {
  -webkit-gap: 0 20px !important;
  -moz-gap: 0 20px !important;
  -ms-gap: 0 20px !important;
  -o-gap: 0 20px !important;
  gap: 0 20px !important;
}
.clm-wrapper.key-value > .clm,
.clm-wrapper.key-value > .flex-item,
.flex-wrapper.key-value > .clm,
.flex-wrapper.key-value > .flex-item {
  display: flex;
  padding: 0 !important;
  -webkit-gap: 10px 0;
  -moz-gap: 10px 0;
  -ms-gap: 10px 0;
  -o-gap: 10px 0;
  gap: 10px 0;
}
.clm-wrapper.key-value > .clm > *,
.clm-wrapper.key-value > .flex-item > *,
.flex-wrapper.key-value > .clm > *,
.flex-wrapper.key-value > .flex-item > * {
  max-width: 100%;
}
.clm-wrapper.key-value > .clm > .float-right,
.clm-wrapper.key-value > .flex-item > .float-right,
.flex-wrapper.key-value > .clm > .float-right,
.flex-wrapper.key-value > .flex-item > .float-right {
  margin-left: auto;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.clm-wrapper.key-value > .clm p,
.clm-wrapper.key-value > .flex-item p,
.flex-wrapper.key-value > .clm p,
.flex-wrapper.key-value > .flex-item p {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  hyphens: none;
}
.clm-wrapper.key-value > .clm p a,
.clm-wrapper.key-value > .clm p .link-style,
.clm-wrapper.key-value > .flex-item p a,
.clm-wrapper.key-value > .flex-item p .link-style,
.flex-wrapper.key-value > .clm p a,
.flex-wrapper.key-value > .clm p .link-style,
.flex-wrapper.key-value > .flex-item p a,
.flex-wrapper.key-value > .flex-item p .link-style {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 300 !important;
}
.clm-wrapper.key-value > .clm p .box,
.clm-wrapper.key-value > .flex-item p .box,
.flex-wrapper.key-value > .clm p .box,
.flex-wrapper.key-value > .flex-item p .box {
  padding: 0 5px;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #628290;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.clm-wrapper.key-value > .clm p:first-letter,
.clm-wrapper.key-value > .flex-item p:first-letter,
.flex-wrapper.key-value > .clm p:first-letter,
.flex-wrapper.key-value > .flex-item p:first-letter {
  text-transform: capitalize;
}
.clm-wrapper.key-value > .clm p.edit-entry:first-letter,
.clm-wrapper.key-value > .flex-item p.edit-entry:first-letter,
.flex-wrapper.key-value > .clm p.edit-entry:first-letter,
.flex-wrapper.key-value > .flex-item p.edit-entry:first-letter {
  text-transform: none;
}
.clm-wrapper.key-value > .clm:first-child,
.clm-wrapper.key-value > .flex-item:first-child,
.flex-wrapper.key-value > .clm:first-child,
.flex-wrapper.key-value > .flex-item:first-child {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.clm-wrapper.key-value > .clm:last-child,
.clm-wrapper.key-value > .flex-item:last-child,
.flex-wrapper.key-value > .clm:last-child,
.flex-wrapper.key-value > .flex-item:last-child {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}
.clm-wrapper.key-value > .clm:last-child .select2,
.clm-wrapper.key-value > .flex-item:last-child .select2,
.flex-wrapper.key-value > .clm:last-child .select2,
.flex-wrapper.key-value > .flex-item:last-child .select2 {
  top: 4px;
  margin: -7px 0 5px 0;
}
.clm-wrapper.key-value > .clm:last-child > .float-right .icon,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon {
  color: #03aa9e;
  font-size: 14px;
  line-height: 28px;
}
.clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-checkmark,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-checkmark,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-checkmark,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-checkmark {
  top: 1px;
  width: 22px;
  font-size: 13px;
  text-align: center;
}
.clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-checkmark:after,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-checkmark:after,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-checkmark:after,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-checkmark:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
  width: 22px;
  height: 22px;
  background: #a2ecb7;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-cloud,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-cloud,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-cloud,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-cloud {
  top: -1px;
  left: 1px;
  font-size: 22px;
  color: #037faa;
}
.clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-x, .clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-pending, .clm-wrapper.key-value > .clm:last-child > .float-right .icon.icon-warning,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-x,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-pending,
.clm-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-warning,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-x,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-pending,
.flex-wrapper.key-value > .clm:last-child > .float-right .icon.icon-warning,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-x,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-pending,
.flex-wrapper.key-value > .flex-item:last-child > .float-right .icon.icon-warning {
  top: -1px;
  left: 1px;
  font-size: 22px;
  color: #628290;
}
.clm-wrapper.key-value .icon,
.clm-wrapper.key-value .icon-wrapper,
.flex-wrapper.key-value .icon,
.flex-wrapper.key-value .icon-wrapper {
  top: 1px;
  -webkit-flex: 0 1;
  -moz-flex: 0 1;
  -ms-flex: 0 1;
  -o-flex: 0 1;
  flex: 0 1;
}
.clm-wrapper.key-value .icon.icon,
.clm-wrapper.key-value .icon-wrapper.icon,
.flex-wrapper.key-value .icon.icon,
.flex-wrapper.key-value .icon-wrapper.icon {
  font-size: 20px;
}
.clm-wrapper.key-value.justify-content-space-between .clm:last-child,
.clm-wrapper.key-value.justify-content-space-between .flex-item:last-child,
.flex-wrapper.key-value.justify-content-space-between .clm:last-child,
.flex-wrapper.key-value.justify-content-space-between .flex-item:last-child {
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
.clm-wrapper.key-value:last-child,
.flex-wrapper.key-value:last-child {
  margin-bottom: 0;
}

.tile-wrapper {
  position: relative;
  margin-bottom: 40px;
  padding: 40px 30px;
  background: #fbfeff;
  overflow: hidden;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.tile-wrapper .tile-badge {
  position: relative;
  bottom: -40px;
  margin: 0 -30px;
  padding: 5px 10px;
  line-height: 1em;
  text-align: center;
  background: #03aa9e;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}
.tile-wrapper .tile-badge span {
  color: #fbfeff;
  font-size: 14px;
  font-weight: 500;
}
.tile-wrapper .tile-badge .icon {
  top: 4px;
  margin-right: 10px;
  color: #fbfeff;
  font-size: 14px;
}
.tile-wrapper .tile-badge .icon.icon-warning {
  top: 3px;
  font-size: 16px;
}
.tile-wrapper .tile-badge.incomplete {
  background: #628290;
}
.tile-wrapper.border-top-color-blue-logo-1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #42bbef;
}
.tile-wrapper.border-bottom-color-blue-logo-1:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #42bbef;
}
.tile-wrapper.glow-rainbow-spin {
  overflow: visible;
}
.tile-wrapper.glow-rainbow-spin:before, .tile-wrapper.glow-rainbow-spin:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: conic-gradient(from calc(var(--angle)), #42bbef, #a2ecb7, #03aa9e, #a56cbb, #42bbef);
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tile-wrapper.glow-rainbow-spin:before {
  padding: 7px;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -ms-filter: blur(10px);
  -o-filter: blur(10px);
  filter: blur(10px);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.tile-wrapper.glow-rainbow-spin:after {
  padding: 7px;
}
.tile-wrapper.glow-rainbow-spin:hover:before {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

.table-wrapper {
  margin: 20px 0;
  border: 1px solid #aac3cd;
  overflow-x: auto;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

.form-trailer {
  margin-top: 30px;
}
.form-trailer p {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
}
.form-trailer p:last-child {
  margin-bottom: 0;
}

.uniform {
  margin: 4px 8px 0 2px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.uniform.uniform-radio:before, .uniform.uniform-checkbox:before {
  bottom: 0;
  width: 100%;
  height: 100%;
  border-color: #aac3cd;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.uniform.uniform-checkbox:after {
  content: "\e90a";
  bottom: 5px;
  color: #03aa9e;
  font-size: 13px;
  font-family: "icomoon";
  line-height: 18px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.uniform.uniform-radio:after {
  bottom: 6px;
  background: #41535d;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -ms-filter: alpha(opacity=70);
}

.language-hide {
  display: none !important;
}

.box-shadow-item {
  -webkit-box-shadow: -10px 20px 50px -15px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: -10px 20px 50px -15px rgba(0, 0, 0, 0.7);
  -ms-box-shadow: -10px 20px 50px -15px rgba(0, 0, 0, 0.7);
  -o-box-shadow: -10px 20px 50px -15px rgba(0, 0, 0, 0.7);
  box-shadow: -10px 20px 50px -15px rgba(0, 0, 0, 0.7);
}

.icon.button {
  padding: 3px !important;
  color: #037faa !important;
  font-size: 16px !important;
  line-height: 1em !important;
  border: 1px solid #037faa !important;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.icon.button:hover:not(.no-css-hover) {
  color: #03aa9e;
  border-color: #03aa9e;
}

[data-overlay-url],
[data-overlay-src],
[data-overlay-data],
[data-overlay-content] {
  cursor: pointer;
}

[data-currency] {
  font-variant: tabular-nums !important;
}
[data-currency]:after {
  content: attr(data-currency) !important;
  padding-left: 3px !important;
}

html.isIOS .uniform.uniform-radio:after, html.isIOS .uniform.uniform-checkbox:after {
  bottom: 6px;
}

@media screen and (min-width: 1601px) {
  .section-content .wrapper-section-content.content-oversize-top {
    margin-top: 120px;
  }
  .section-content .wrapper-section-content.content-oversize-bottom {
    margin-bottom: 120px;
  }
  .section-content.content-oversize-top {
    padding-top: 80px;
  }
  .section-content.content-oversize-bottom {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 1600px) {
  .clm-wrapper > .clm.graphic-overlap > .mixin-image-wrapper.oversize img,
  .flex-wrapper > .clm.graphic-overlap > .mixin-image-wrapper.oversize img {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm.clm-3:not(.row) {
    width: 50%;
  }
  .section-content:first-child {
    background: none !important;
  }
}
@media screen and (max-width: 1000px) {
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm[class*="clm clm-"] {
    width: 100% !important;
    padding: 0 !important;
  }
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm.no-content, .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm.max-width-1000px-display-none {
    display: none;
  }
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm.no-content ~ :nth-child(2) .mixin-article-header, .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm.max-width-1000px-display-none ~ :nth-child(2) .mixin-article-header {
    margin-top: 0;
  }
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header) > .clm:first-child .mixin-article-header {
    margin-top: 0;
  }
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header).equal-distribution {
    margin: 0;
  }
  .section-content .wrapper-section-content .clm-wrapper:not(.division-header).max-width-1000px-flex-direction-column-reverse {
    flex-direction: column-reverse;
  }
  .clm-wrapper > .clm.graphic-overlap,
  .flex-wrapper > .clm.graphic-overlap {
    -webkit-gap: 0;
    -moz-gap: 0;
    -ms-gap: 0;
    -o-gap: 0;
    gap: 0;
  }
  .clm-wrapper.key-value > .clm,
  .clm-wrapper.key-value > .flex-item,
  .flex-wrapper.key-value > .clm,
  .flex-wrapper.key-value > .flex-item {
    width: 100%;
  }
  .clm-wrapper.key-value > .clm:last-child p,
  .clm-wrapper.key-value > .flex-item:last-child p,
  .flex-wrapper.key-value > .clm:last-child p,
  .flex-wrapper.key-value > .flex-item:last-child p {
    color: #8da3ab;
    font-weight: 300;
  }
}
@media screen and (max-width: 800px) {
  .section-content .wrapper-section-content {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 500px) {
  .clm-wrapper.key-value > .clm p,
  .clm-wrapper.key-value > .flex-item p,
  .flex-wrapper.key-value > .clm p,
  .flex-wrapper.key-value > .flex-item p {
    font-weight: 600;
  }
  .clm-wrapper.key-value > .clm p a,
  .clm-wrapper.key-value > .clm p .link-style,
  .clm-wrapper.key-value > .flex-item p a,
  .clm-wrapper.key-value > .flex-item p .link-style,
  .flex-wrapper.key-value > .clm p a,
  .flex-wrapper.key-value > .clm p .link-style,
  .flex-wrapper.key-value > .flex-item p a,
  .flex-wrapper.key-value > .flex-item p .link-style {
    font-weight: 400 !important;
  }
  .section-content .wrapper-section-content {
    margin-bottom: 20px;
  }
  .section-content .wrapper-section-content .clm-wrapper > .clm {
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .section-content .wrapper-section-content {
    margin-bottom: 10px;
  }
  .tile-wrapper {
    padding: 25px 15px;
  }
  .tile-wrapper .tile-badge {
    bottom: -25px;
    right: -15px;
  }
}
/* == CONTENT-WRAPPER ====================================================== */
.center-oversize-ctn {
  margin: 0 auto;
  max-width: 1400px;
}

.center-wide-ctn {
  margin: 0 auto;
  max-width: 1200px;
}

.center-middle-ctn {
  margin: 0 auto;
  max-width: 1000px;
}

.center-menu-ctn {
  margin: 0 100px;
}

@media screen and (max-width: 1600px) {
  .center-oversize-ctn {
    max-width: calc(100% - 200px);
  }
}
@media screen and (max-width: 1400px) {
  .center-wide-ctn {
    max-width: calc(100% - 200px);
  }
}
@media screen and (max-width: 1200px) {
  .center-oversize-ctn,
  .center-wide-ctn,
  .center-middle-ctn {
    max-width: calc(100% - 120px);
  }
}
@media screen and (max-width: 1100px) {
  .center-oversize-ctn,
  .center-wide-ctn,
  .center-middle-ctn {
    max-width: calc(100% - 80px);
  }
}
@media screen and (max-width: 1000px) {
  .center-oversize-ctn > .clm-wrapper > .clm.clm-2,
  .center-wide-ctn > .clm-wrapper > .clm.clm-2,
  .center-middle-ctn > .clm-wrapper > .clm.clm-2 {
    width: 100%;
    padding: 0;
  }
  .center-menu-ctn {
    margin: 0 80px;
  }
}
@media screen and (max-width: 700px) {
  .center-oversize-ctn,
  .center-wide-ctn,
  .center-middle-ctn {
    max-width: calc(100% - 60px);
  }
}
@media screen and (max-width: 500px) {
  .center-oversize-ctn,
  .center-wide-ctn,
  .center-middle-ctn {
    max-width: calc(100% - 40px);
  }
}
@media screen and (max-width: 750px) {
  .center-menu-ctn {
    margin: 0 40px;
  }
}
@media screen and (max-width: 650px) {
  .center-menu-ctn {
    margin: 0 30px;
  }
}
@media screen and (max-width: 500px) {
  .center-menu-ctn {
    margin: 0 20px;
  }
}
/* == BACKGROUND ====================================================== */
.bg-color-grey-white {
  background-color: #fbfeff;
}

.bg-color-grey-shiny {
  background-color: #f6f9fc;
}

.bg-color-grey-light {
  background-color: #f3f6f7;
}

.bg-color-grey-bright {
  background-color: #b6c1c4;
}

.bg-color-grey-middle {
  background-color: #999c9e;
}

.bg-color-grey-dark {
  background-color: #628290;
}

.bg-color-grey-dark-i {
  background-color: #628290 !important;
}

.bg-color-blue-logo-1 {
  background-color: #42bbef;
}
.bg-color-blue-logo-1 h1,
.bg-color-blue-logo-1 h2,
.bg-color-blue-logo-1 h3 {
  color: #005e7d;
}
.bg-color-blue-logo-1 p {
  color: #fbfeff;
}
.bg-color-blue-logo-1 p a {
  color: #005e7d !important;
  font-weight: 500 !important;
}

.bg-color-blue-logo-1-i {
  background-color: #42bbef !important;
}

.bg-color-blue-logo-2 {
  background-color: #037faa;
}
.bg-color-blue-logo-2 h1,
.bg-color-blue-logo-2 h2,
.bg-color-blue-logo-2 h3 {
  color: #fbfeff;
}
.bg-color-blue-logo-2 p {
  color: #aac3cd;
}

.bg-color-blue-logo-2-i {
  background-color: #037faa !important;
}

.bg-color-blue-dark {
  background-color: #005e7d;
}

.bg-color-blue-black {
  background-color: #41535d;
}

.bg-color-blue-black-i {
  background-color: #41535d !important;
}

.bg-color-green-bright {
  background-color: #a2ecb7;
}

.bg-color-green-middle {
  background-color: #03aa9e;
}

.bg-color-green-middle-i {
  background-color: #03aa9e !important;
}

.bg-color-purple-middle {
  background-color: #a56cbb;
}

.bg-color-purple-middle-i {
  background-color: #a56cbb !important;
}

.bg-color-transparent-i {
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-skew {
  position: relative;
  padding-top: 120px;
  min-height: 300px;
  background-color: rgba(0, 0, 0, 0);
}
.bg-skew:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transform: skewY(-7deg);
  background: #fbfeff;
}
.bg-skew:after {
  background: #fbfeff;
}
.bg-skew.skew-top:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 50%;
}
.bg-skew.skew-bottom {
  margin-bottom: 120px;
  padding-top: 40px;
}
.bg-skew.skew-bottom:after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 50%;
}
.bg-skew.skew-half {
  padding-top: 40px !important;
}
.bg-skew.skew-half:before {
  top: 50%;
  height: 50%;
}
.bg-skew.skew-half:after {
  content: none;
}
.bg-skew.bg-color-blue-logo-1:before, .bg-skew.bg-color-blue-logo-1:after {
  background: #42bbef;
}
.bg-skew.bg-color-blue-logo-2:before, .bg-skew.bg-color-blue-logo-2:after {
  background: #037faa;
}
.bg-skew.bg-color-grey-light:before, .bg-skew.bg-color-grey-light:after {
  background: #f3f6f7;
}

@media screen and (max-width: 1000px) {
  .bg-skew.skew-bottom {
    margin-bottom: 80px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 800px) {
  .bg-skew {
    padding-top: 100px;
  }
  .bg-skew.skew-bottom {
    margin-bottom: 60px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 400px) {
  .bg-skew {
    padding-top: 80px;
  }
  .bg-skew .mixin-quotes {
    margin-top: 20px !important;
  }
  .bg-skew.skew-bottom {
    margin-bottom: 40px;
    padding-bottom: 60px;
  }
}
/* == COLOR ====================================================== */
.color-green-middle {
  color: #03aa9e;
}

.color-green-middle-i {
  color: #03aa9e !important;
}

.color-purple-middle {
  color: #a56cbb;
}

.color-purple-middle-i {
  color: #a56cbb !important;
}

/* == BORDER-COLOR ====================================================== */
.border-color-grey-white {
  border-color: #fbfeff;
}

.border-color-grey-shiny {
  border-color: #f6f9fc;
}

.border-color-grey-light {
  border-color: #f3f6f7;
}

.border-color-grey-middle {
  border-color: #999c9e;
}

.border-color-blue-logo-1 {
  border-color: #42bbef;
}

.border-color-blue-logo-2 {
  border-color: #037faa;
}

.border-color-green-bright {
  border-color: #a2ecb7;
}

.border-color-green-middle {
  border-color: #03aa9e;
}

.border-color-green-middle-i {
  border-color: #03aa9e !important;
}

.border-color-purple-middle {
  border-color: #a56cbb;
}

.border-color-purple-middle-i {
  border-color: #a56cbb !important;
}

/* == KEYFRAMES ====================================================== */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}
/* == CORE / GLOBALS / PLUGINS ====================================================== */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/* == CORE / GLOBALS / HELPERS ====================================================== */
.helper-loading {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* == CORE / GLOBALS / MIXINS ====================================================== */
form {
  text-align: left;
}
form .row {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
}
form .row .form-info {
  position: absolute;
  top: calc(var(--top) + 8px);
  right: 10px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
form .row .form-info .icon {
  color: #e2430f;
  font-size: 18px;
}
form .row .form-info.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
form .row .counter-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #999c9e;
  font-size: 12px;
}
form .row .counter-wrapper.counter-overlength {
  color: #e2430f;
}
form .row .validation-status-wrapper span {
  display: block;
  color: #999c9e;
  font-size: 12px;
}
form .row:last-child {
  margin-bottom: 0;
}
form .form-box {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
form .form-box.error-flag {
  border-color: #e2430f;
}
form .form-box.margin-top {
  margin-top: 20px;
}
form input[type=text],
form input[type=number],
form input[type=email],
form input[type=password],
form input[type=date],
form input[type=time],
form input[type=datetime-local],
form textarea,
form .input-checkbox,
form .input-radio,
form .input-switcher,
form .type-text-style,
form .type-textarea-style {
  display: block;
  width: 100%;
  padding: 4px 5px;
  color: #999c9e;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  border: 1px solid #999c9e;
  background: #fff;
}
form input[type=text]::-webkit-input-placeholder,
form input[type=number]::-webkit-input-placeholder,
form input[type=email]::-webkit-input-placeholder,
form input[type=password]::-webkit-input-placeholder,
form input[type=date]::-webkit-input-placeholder,
form input[type=time]::-webkit-input-placeholder,
form input[type=datetime-local]::-webkit-input-placeholder,
form textarea::-webkit-input-placeholder,
form .input-checkbox::-webkit-input-placeholder,
form .input-radio::-webkit-input-placeholder,
form .input-switcher::-webkit-input-placeholder,
form .type-text-style::-webkit-input-placeholder,
form .type-textarea-style::-webkit-input-placeholder {
  color: #e0e0e0;
}
form input[type=text]::-moz-placeholder,
form input[type=number]::-moz-placeholder,
form input[type=email]::-moz-placeholder,
form input[type=password]::-moz-placeholder,
form input[type=date]::-moz-placeholder,
form input[type=time]::-moz-placeholder,
form input[type=datetime-local]::-moz-placeholder,
form textarea::-moz-placeholder,
form .input-checkbox::-moz-placeholder,
form .input-radio::-moz-placeholder,
form .input-switcher::-moz-placeholder,
form .type-text-style::-moz-placeholder,
form .type-textarea-style::-moz-placeholder {
  color: #e0e0e0;
}
form input[type=text]:-ms-input-placeholder,
form input[type=number]:-ms-input-placeholder,
form input[type=email]:-ms-input-placeholder,
form input[type=password]:-ms-input-placeholder,
form input[type=date]:-ms-input-placeholder,
form input[type=time]:-ms-input-placeholder,
form input[type=datetime-local]:-ms-input-placeholder,
form textarea:-ms-input-placeholder,
form .input-checkbox:-ms-input-placeholder,
form .input-radio:-ms-input-placeholder,
form .input-switcher:-ms-input-placeholder,
form .type-text-style:-ms-input-placeholder,
form .type-textarea-style:-ms-input-placeholder {
  color: #e0e0e0;
}
form input[type=text]:-moz-placeholder,
form input[type=number]:-moz-placeholder,
form input[type=email]:-moz-placeholder,
form input[type=password]:-moz-placeholder,
form input[type=date]:-moz-placeholder,
form input[type=time]:-moz-placeholder,
form input[type=datetime-local]:-moz-placeholder,
form textarea:-moz-placeholder,
form .input-checkbox:-moz-placeholder,
form .input-radio:-moz-placeholder,
form .input-switcher:-moz-placeholder,
form .type-text-style:-moz-placeholder,
form .type-textarea-style:-moz-placeholder {
  color: #e0e0e0;
}
form input[type=text]:-webkit-autofill,
form input[type=number]:-webkit-autofill,
form input[type=email]:-webkit-autofill,
form input[type=password]:-webkit-autofill,
form input[type=date]:-webkit-autofill,
form input[type=time]:-webkit-autofill,
form input[type=datetime-local]:-webkit-autofill,
form textarea:-webkit-autofill,
form .input-checkbox:-webkit-autofill,
form .input-radio:-webkit-autofill,
form .input-switcher:-webkit-autofill,
form .type-text-style:-webkit-autofill,
form .type-textarea-style:-webkit-autofill {
  -webkit-text-fill-color: #999c9e;
  -moz-text-fill-color: #999c9e;
  -ms-text-fill-color: #999c9e;
  -o-text-fill-color: #999c9e;
  text-fill-color: #999c9e;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
  -moz-box-shadow: inset 0 0 0 1000px #fff;
  -ms-box-shadow: inset 0 0 0 1000px #fff;
  -o-box-shadow: inset 0 0 0 1000px #fff;
  box-shadow: inset 0 0 0 1000px #fff;
  -webkit-transition: all 0s ease-out 0s !important;
  -moz-transition: all 0s ease-out 0s !important;
  -ms-transition: all 0s ease-out 0s !important;
  -o-transition: all 0s ease-out 0s !important;
  transition: all 0s ease-out 0s !important;
}
form input[type=text].unchangeable,
form input[type=number].unchangeable,
form input[type=email].unchangeable,
form input[type=password].unchangeable,
form input[type=date].unchangeable,
form input[type=time].unchangeable,
form input[type=datetime-local].unchangeable,
form textarea.unchangeable,
form .input-checkbox.unchangeable,
form .input-radio.unchangeable,
form .input-switcher.unchangeable,
form .type-text-style.unchangeable,
form .type-textarea-style.unchangeable {
  color: #676a6c;
  border-color: rgba(0, 0, 0, 0);
}
form input[type=text].input-checkbox label, form input[type=text].input-radio label,
form input[type=number].input-checkbox label,
form input[type=number].input-radio label,
form input[type=email].input-checkbox label,
form input[type=email].input-radio label,
form input[type=password].input-checkbox label,
form input[type=password].input-radio label,
form input[type=date].input-checkbox label,
form input[type=date].input-radio label,
form input[type=time].input-checkbox label,
form input[type=time].input-radio label,
form input[type=datetime-local].input-checkbox label,
form input[type=datetime-local].input-radio label,
form textarea.input-checkbox label,
form textarea.input-radio label,
form .input-checkbox.input-checkbox label,
form .input-checkbox.input-radio label,
form .input-radio.input-checkbox label,
form .input-radio.input-radio label,
form .input-switcher.input-checkbox label,
form .input-switcher.input-radio label,
form .type-text-style.input-checkbox label,
form .type-text-style.input-radio label,
form .type-textarea-style.input-checkbox label,
form .type-textarea-style.input-radio label {
  display: block;
  line-height: inherit;
  hyphens: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
form input[type=text].input-checkbox label span, form input[type=text].input-radio label span,
form input[type=number].input-checkbox label span,
form input[type=number].input-radio label span,
form input[type=email].input-checkbox label span,
form input[type=email].input-radio label span,
form input[type=password].input-checkbox label span,
form input[type=password].input-radio label span,
form input[type=date].input-checkbox label span,
form input[type=date].input-radio label span,
form input[type=time].input-checkbox label span,
form input[type=time].input-radio label span,
form input[type=datetime-local].input-checkbox label span,
form input[type=datetime-local].input-radio label span,
form textarea.input-checkbox label span,
form textarea.input-radio label span,
form .input-checkbox.input-checkbox label span,
form .input-checkbox.input-radio label span,
form .input-radio.input-checkbox label span,
form .input-radio.input-radio label span,
form .input-switcher.input-checkbox label span,
form .input-switcher.input-radio label span,
form .type-text-style.input-checkbox label span,
form .type-text-style.input-radio label span,
form .type-textarea-style.input-checkbox label span,
form .type-textarea-style.input-radio label span {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
form input[type=text].input-checkbox label span a, form input[type=text].input-radio label span a,
form input[type=number].input-checkbox label span a,
form input[type=number].input-radio label span a,
form input[type=email].input-checkbox label span a,
form input[type=email].input-radio label span a,
form input[type=password].input-checkbox label span a,
form input[type=password].input-radio label span a,
form input[type=date].input-checkbox label span a,
form input[type=date].input-radio label span a,
form input[type=time].input-checkbox label span a,
form input[type=time].input-radio label span a,
form input[type=datetime-local].input-checkbox label span a,
form input[type=datetime-local].input-radio label span a,
form textarea.input-checkbox label span a,
form textarea.input-radio label span a,
form .input-checkbox.input-checkbox label span a,
form .input-checkbox.input-radio label span a,
form .input-radio.input-checkbox label span a,
form .input-radio.input-radio label span a,
form .input-switcher.input-checkbox label span a,
form .input-switcher.input-radio label span a,
form .type-text-style.input-checkbox label span a,
form .type-text-style.input-radio label span a,
form .type-textarea-style.input-checkbox label span a,
form .type-textarea-style.input-radio label span a {
  position: relative;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
}
form input[type=text].input-checkbox a:hover:hover:not(.no-css-hover), form input[type=text].input-radio a:hover:hover:not(.no-css-hover),
form input[type=number].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=number].input-radio a:hover:hover:not(.no-css-hover),
form input[type=email].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=email].input-radio a:hover:hover:not(.no-css-hover),
form input[type=password].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=password].input-radio a:hover:hover:not(.no-css-hover),
form input[type=date].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=date].input-radio a:hover:hover:not(.no-css-hover),
form input[type=time].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=time].input-radio a:hover:hover:not(.no-css-hover),
form input[type=datetime-local].input-checkbox a:hover:hover:not(.no-css-hover),
form input[type=datetime-local].input-radio a:hover:hover:not(.no-css-hover),
form textarea.input-checkbox a:hover:hover:not(.no-css-hover),
form textarea.input-radio a:hover:hover:not(.no-css-hover),
form .input-checkbox.input-checkbox a:hover:hover:not(.no-css-hover),
form .input-checkbox.input-radio a:hover:hover:not(.no-css-hover),
form .input-radio.input-checkbox a:hover:hover:not(.no-css-hover),
form .input-radio.input-radio a:hover:hover:not(.no-css-hover),
form .input-switcher.input-checkbox a:hover:hover:not(.no-css-hover),
form .input-switcher.input-radio a:hover:hover:not(.no-css-hover),
form .type-text-style.input-checkbox a:hover:hover:not(.no-css-hover),
form .type-text-style.input-radio a:hover:hover:not(.no-css-hover),
form .type-textarea-style.input-checkbox a:hover:hover:not(.no-css-hover),
form .type-textarea-style.input-radio a:hover:hover:not(.no-css-hover) {
  text-decoration: underline;
}
form input[type=text].input-switcher .mixin-switcher,
form input[type=number].input-switcher .mixin-switcher,
form input[type=email].input-switcher .mixin-switcher,
form input[type=password].input-switcher .mixin-switcher,
form input[type=date].input-switcher .mixin-switcher,
form input[type=time].input-switcher .mixin-switcher,
form input[type=datetime-local].input-switcher .mixin-switcher,
form textarea.input-switcher .mixin-switcher,
form .input-checkbox.input-switcher .mixin-switcher,
form .input-radio.input-switcher .mixin-switcher,
form .input-switcher.input-switcher .mixin-switcher,
form .type-text-style.input-switcher .mixin-switcher,
form .type-textarea-style.input-switcher .mixin-switcher {
  float: right;
}
form input[type=text].input-switcher > span,
form input[type=number].input-switcher > span,
form input[type=email].input-switcher > span,
form input[type=password].input-switcher > span,
form input[type=date].input-switcher > span,
form input[type=time].input-switcher > span,
form input[type=datetime-local].input-switcher > span,
form textarea.input-switcher > span,
form .input-checkbox.input-switcher > span,
form .input-radio.input-switcher > span,
form .input-switcher.input-switcher > span,
form .type-text-style.input-switcher > span,
form .type-textarea-style.input-switcher > span {
  font-size: inherit;
  line-height: inherit;
}
form input[type=text].type-text-style, form input[type=text].type-textarea-style,
form input[type=number].type-text-style,
form input[type=number].type-textarea-style,
form input[type=email].type-text-style,
form input[type=email].type-textarea-style,
form input[type=password].type-text-style,
form input[type=password].type-textarea-style,
form input[type=date].type-text-style,
form input[type=date].type-textarea-style,
form input[type=time].type-text-style,
form input[type=time].type-textarea-style,
form input[type=datetime-local].type-text-style,
form input[type=datetime-local].type-textarea-style,
form textarea.type-text-style,
form textarea.type-textarea-style,
form .input-checkbox.type-text-style,
form .input-checkbox.type-textarea-style,
form .input-radio.type-text-style,
form .input-radio.type-textarea-style,
form .input-switcher.type-text-style,
form .input-switcher.type-textarea-style,
form .type-text-style.type-text-style,
form .type-text-style.type-textarea-style,
form .type-textarea-style.type-text-style,
form .type-textarea-style.type-textarea-style {
  display: flex;
  position: relative;
  border-color: #999c9e;
  background: #f3f3f4;
  overflow: hidden;
  justify-content: space-between;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -ms-gap: 10px;
  -o-gap: 10px;
  gap: 10px;
}
form input[type=text].type-text-style .text, form input[type=text].type-textarea-style .text,
form input[type=number].type-text-style .text,
form input[type=number].type-textarea-style .text,
form input[type=email].type-text-style .text,
form input[type=email].type-textarea-style .text,
form input[type=password].type-text-style .text,
form input[type=password].type-textarea-style .text,
form input[type=date].type-text-style .text,
form input[type=date].type-textarea-style .text,
form input[type=time].type-text-style .text,
form input[type=time].type-textarea-style .text,
form input[type=datetime-local].type-text-style .text,
form input[type=datetime-local].type-textarea-style .text,
form textarea.type-text-style .text,
form textarea.type-textarea-style .text,
form .input-checkbox.type-text-style .text,
form .input-checkbox.type-textarea-style .text,
form .input-radio.type-text-style .text,
form .input-radio.type-textarea-style .text,
form .input-switcher.type-text-style .text,
form .input-switcher.type-textarea-style .text,
form .type-text-style.type-text-style .text,
form .type-text-style.type-textarea-style .text,
form .type-textarea-style.type-text-style .text,
form .type-textarea-style.type-textarea-style .text {
  color: #999c9e;
  font: inherit;
  line-hegith: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: inherit;
  -webkit-flex: 1 1;
  -moz-flex: 1 1;
  -ms-flex: 1 1;
  -o-flex: 1 1;
  flex: 1 1;
}
form input[type=text].type-text-style .icon, form input[type=text].type-textarea-style .icon,
form input[type=number].type-text-style .icon,
form input[type=number].type-textarea-style .icon,
form input[type=email].type-text-style .icon,
form input[type=email].type-textarea-style .icon,
form input[type=password].type-text-style .icon,
form input[type=password].type-textarea-style .icon,
form input[type=date].type-text-style .icon,
form input[type=date].type-textarea-style .icon,
form input[type=time].type-text-style .icon,
form input[type=time].type-textarea-style .icon,
form input[type=datetime-local].type-text-style .icon,
form input[type=datetime-local].type-textarea-style .icon,
form textarea.type-text-style .icon,
form textarea.type-textarea-style .icon,
form .input-checkbox.type-text-style .icon,
form .input-checkbox.type-textarea-style .icon,
form .input-radio.type-text-style .icon,
form .input-radio.type-textarea-style .icon,
form .input-switcher.type-text-style .icon,
form .input-switcher.type-textarea-style .icon,
form .type-text-style.type-text-style .icon,
form .type-text-style.type-textarea-style .icon,
form .type-textarea-style.type-text-style .icon,
form .type-textarea-style.type-textarea-style .icon {
  top: 0;
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
}
form input[type=text].type-text-style.type-textarea-style .text, form input[type=text].type-textarea-style.type-textarea-style .text,
form input[type=number].type-text-style.type-textarea-style .text,
form input[type=number].type-textarea-style.type-textarea-style .text,
form input[type=email].type-text-style.type-textarea-style .text,
form input[type=email].type-textarea-style.type-textarea-style .text,
form input[type=password].type-text-style.type-textarea-style .text,
form input[type=password].type-textarea-style.type-textarea-style .text,
form input[type=date].type-text-style.type-textarea-style .text,
form input[type=date].type-textarea-style.type-textarea-style .text,
form input[type=time].type-text-style.type-textarea-style .text,
form input[type=time].type-textarea-style.type-textarea-style .text,
form input[type=datetime-local].type-text-style.type-textarea-style .text,
form input[type=datetime-local].type-textarea-style.type-textarea-style .text,
form textarea.type-text-style.type-textarea-style .text,
form textarea.type-textarea-style.type-textarea-style .text,
form .input-checkbox.type-text-style.type-textarea-style .text,
form .input-checkbox.type-textarea-style.type-textarea-style .text,
form .input-radio.type-text-style.type-textarea-style .text,
form .input-radio.type-textarea-style.type-textarea-style .text,
form .input-switcher.type-text-style.type-textarea-style .text,
form .input-switcher.type-textarea-style.type-textarea-style .text,
form .type-text-style.type-text-style.type-textarea-style .text,
form .type-text-style.type-textarea-style.type-textarea-style .text,
form .type-textarea-style.type-text-style.type-textarea-style .text,
form .type-textarea-style.type-textarea-style.type-textarea-style .text {
  white-space: normal;
}
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=email]:focus,
form input[type=password]:focus,
form input[type=date]:focus,
form input[type=time]:focus,
form input[type=datetime-local]:focus,
form textarea:focus,
form .input-checkbox:focus,
form .input-radio:focus,
form .input-switcher:focus,
form .type-text-style:focus,
form .type-textarea-style:focus {
  border-color: #1c84c6;
  -webkit-box-shadow: inset 0 0 3px 0 #1c84c6;
  -moz-box-shadow: inset 0 0 3px 0 #1c84c6;
  -ms-box-shadow: inset 0 0 3px 0 #1c84c6;
  -o-box-shadow: inset 0 0 3px 0 #1c84c6;
  box-shadow: inset 0 0 3px 0 #1c84c6;
}
form input[type=text] input[type=password]:after,
form input[type=number] input[type=password]:after,
form input[type=email] input[type=password]:after,
form input[type=password] input[type=password]:after,
form input[type=date] input[type=password]:after,
form input[type=time] input[type=password]:after,
form input[type=datetime-local] input[type=password]:after,
form textarea input[type=password]:after,
form .input-checkbox input[type=password]:after,
form .input-radio input[type=password]:after,
form .input-switcher input[type=password]:after,
form .type-text-style input[type=password]:after,
form .type-textarea-style input[type=password]:after {
  content: "###";
}
form input[type=date],
form input[type=time],
form input[type=datetime-local] {
  padding-top: 3px;
  padding-bottom: 3px;
}
form textarea {
  height: 150px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
form select {
  display: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
form select option {
  display: none;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
form select[name=language] {
  min-width: 200px;
}
form select.tags {
  display: block;
}
form select.mobile-native {
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
  clip: auto !important;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0) !important;
}
form select.mobile-native option {
  display: block;
  width: 100%;
  height: auto;
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
form code {
  padding: 4px 10px !important;
  background: #fff !important;
  overflow-x: auto !important;
}
form a.type-text-style {
  text-decoration: underline;
}
form .input-file .btn-wrapper {
  display: flex;
  position: relative;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 10px 10px;
  -moz-gap: 10px 10px;
  -ms-gap: 10px 10px;
  -o-gap: 10px 10px;
  gap: 10px 10px;
}
form .input-file .btn-wrapper input[type=file] {
  display: none;
}
form .input-file .btn-wrapper .accept,
form .input-file .btn-wrapper .dimension,
form .input-file .btn-wrapper .info {
  display: inline-block;
  padding: 0 10px;
  line-height: 32px;
  color: #999c9e;
  border: 1px solid #999c9e;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
form .input-file .btn-wrapper .accept span,
form .input-file .btn-wrapper .dimension span,
form .input-file .btn-wrapper .info span {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
form .input-file .img-wrapper {
  margin-top: 10px;
  font-size: 0;
}
form .input-file .img-wrapper img {
  max-width: 200px;
  max-height: 100px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0;
  background-size: 20px 20px;
  background-image: -webkit-linear-gradient(45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -webkit-linear-gradient(-45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%), -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%);
  background-image: -moz-linear-gradient(45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -moz-linear-gradient(-45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%), -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%);
  background-image: -ms-linear-gradient(45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -ms-linear-gradient(-45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -ms-linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%), -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%);
  background-image: -o-linear-gradient(45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -o-linear-gradient(-45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), -o-linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%), -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%);
  background-image: linear-gradient(45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(-45deg, #f3f3f4 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%), linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, #f3f3f4 75%);
}
form .input-file .img-wrapper img[src] {
  margin-bottom: 10px;
}
form .select2.select2-container,
form .select2.select2-container--open {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
}
form .select2.select2-container .select2-selection.select2-selection--default,
form .select2.select2-container .select2-selection.select2-selection--single,
form .select2.select2-container .select2-selection.select2-selection--multiple,
form .select2.select2-container .select2-selection.select2-selection--default:focus,
form .select2.select2-container .select2-selection.select2-selection--single:focus,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus,
form .select2.select2-container--open .select2-selection.select2-selection--default,
form .select2.select2-container--open .select2-selection.select2-selection--single,
form .select2.select2-container--open .select2-selection.select2-selection--multiple,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus {
  min-height: 34px;
  border: 1px solid #999c9e;
  background: #fff;
  text-align: left;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: border-color 0.3s ease-out 0s;
  -moz-transition: border-color 0.3s ease-out 0s;
  -ms-transition: border-color 0.3s ease-out 0s;
  -o-transition: border-color 0.3s ease-out 0s;
  transition: border-color 0.3s ease-out 0s;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered {
  padding: 4px 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #999c9e;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice {
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #999c9e;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice a,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice a {
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-decoration: underline;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  position: relative;
  top: 3px;
  margin-left: 2px;
  margin-right: 5px;
  color: inherit;
  font-weight: 300;
  line-height: 1em;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle {
  cursor: grab;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after {
  content: "⠿";
  margin-left: 5px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__placeholder {
  padding-right: 20px;
  color: #c3c3c3;
  line-height: 30px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered.select2-not-removable .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow {
  height: 32px;
  border: none;
  border-left: 1px solid #999c9e;
  background-image: -webkit-linear-gradient(top, #f3f3f4 50%, #e0e0e0 100%);
  background-image: -o-linear-gradient(top, #f3f3f4 50%, #e0e0e0 100%);
  background-image: linear-gradient(to bottom, #f3f3f4 50%, #e0e0e0 100%);
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b {
  border-color: #999c9e rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__clear {
  margin-left: 10px;
  margin-right: 0;
  color: #999c9e;
  font-weight: 300;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field {
  width: auto !important;
  margin-top: 0;
  color: #999c9e;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field::-webkit-input-placeholder {
  color: #c3c3c3;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field::-moz-placeholder {
  color: #c3c3c3;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field:-ms-input-placeholder {
  color: #c3c3c3;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field:-moz-placeholder {
  color: #c3c3c3;
}
form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--single .select2-selection__rendered, form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--single:focus .select2-selection__rendered {
  padding-right: 25px;
}
form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered, form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice, form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search, form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search {
  min-width: 50px;
  -webkit-flex: 1 1 50px;
  -moz-flex: 1 1 50px;
  -ms-flex: 1 1 50px;
  -o-flex: 1 1 50px;
  flex: 1 1 50px;
}
form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input, form .select2.select2-container .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--default.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--single.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus.select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search input {
  width: 100% !important;
}
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container.select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container--open.select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #999c9e;
}
form .select2.select2-container.error-flag .select2-selection.select2-selection--default,
form .select2.select2-container.error-flag .select2-selection.select2-selection--single,
form .select2.select2-container.error-flag .select2-selection.select2-selection--multiple,
form .select2.select2-container.error-flag .select2-selection.select2-selection--default:focus,
form .select2.select2-container.error-flag .select2-selection.select2-selection--single:focus,
form .select2.select2-container.error-flag .select2-selection.select2-selection--multiple:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--default,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--single,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--multiple,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--default:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--single:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--multiple:focus {
  border-color: #e2430f;
}
form.validate-wrapper .error-message {
  color: #e2430f;
  font-size: 14px;
  font-weight: 400;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
form.validate-wrapper.error-flag .error-message {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

.select2-container.select2-container--classic .select2-dropdown, .select2-container.select2-container--open .select2-dropdown {
  padding: 0;
  border: 1px solid #999c9e;
  border-top: none;
  background: #fff;
  overflow: hidden;
}
.select2-container.select2-container--classic .select2-dropdown .select2-search__field, .select2-container.select2-container--open .select2-dropdown .select2-search__field {
  padding: 4px 5px;
  color: #999c9e;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  border: 1px solid #999c9e;
  background: #fff;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__options, .select2-container.select2-container--open .select2-dropdown .select2-results__options {
  overflow-x: hidden;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option, .select2-container.select2-container--open .select2-dropdown .select2-results__option {
  margin-bottom: 1px;
  padding: 3px 5px;
  color: #676a6c;
  font-size: 16px;
  font-weight: 300;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option .select2-results__group, .select2-container.select2-container--open .select2-dropdown .select2-results__option .select2-results__group {
  padding-bottom: 0;
  text-transform: uppercase;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[role=group], .select2-container.select2-container--open .select2-dropdown .select2-results__option[role=group] {
  border-top: 1px solid #999c9e;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[role=group]:first-child, .select2-container.select2-container--open .select2-dropdown .select2-results__option[role=group]:first-child {
  border-top: none;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[aria-selected=true], .select2-container.select2-container--open .select2-dropdown .select2-results__option[aria-selected=true] {
  position: relative;
  z-index: 1;
  color: #fff;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[aria-selected=true]:before, .select2-container.select2-container--open .select2-dropdown .select2-results__option[aria-selected=true]:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -5px;
  right: -5px;
  background: #e0e0e0;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option.select2-results__option--highlighted, .select2-container.select2-container--open .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  position: relative;
  z-index: 1;
  color: #fff;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option.select2-results__option--highlighted:before, .select2-container.select2-container--open .select2-dropdown .select2-results__option.select2-results__option--highlighted:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -5px;
  right: -5px;
  background: #1c84c6;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option:last-child, .select2-container.select2-container--open .select2-dropdown .select2-results__option:last-child {
  margin-bottom: 0;
}
.select2-container.select2-container--classic .select2-dropdown.select2-dropdown--above, .select2-container.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-top: 1px solid #999c9e;
  border-bottom: none;
}
.select2-container.select2-container--classic .select2-dropdown.select2-crop-text .select2-results__option, .select2-container.select2-container--open .select2-dropdown.select2-crop-text .select2-results__option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container.select2-container--classic.select2-container--open, .select2-container.select2-container--open.select2-container--open {
  z-index: 9999999;
}
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--default,
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--single,
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--multiple,
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--default:focus,
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--single:focus,
.select2-container.select2-container--classic.select2-container--open.select2-container--above .select2-selection.select2-selection--multiple:focus, .select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--default,
.select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--single,
.select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--multiple,
.select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--default:focus,
.select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--single:focus,
.select2-container.select2-container--open.select2-container--open.select2-container--above .select2-selection.select2-selection--multiple:focus {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--default,
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--single,
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--multiple,
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--default:focus,
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--single:focus,
.select2-container.select2-container--classic.select2-container--open.select2-container--below .select2-selection.select2-selection--multiple:focus, .select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--default,
.select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--single,
.select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--multiple,
.select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--default:focus,
.select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--single:focus,
.select2-container.select2-container--open.select2-container--open.select2-container--below .select2-selection.select2-selection--multiple:focus {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.mixin-query.mixin-query-pagination {
  padding: 20px 0;
  text-align: center;
}
.mixin-query.mixin-query-pagination .btn {
  cursor: pointer;
}
.mixin-query.mixin-query-pagination .btn.disabled {
  cursor: default;
  background: #999c9e;
}
.mixin-query.mixin-query-pagination .select2 {
  display: inline-block !important;
  width: 130px !important;
  margin: 0 10px;
}
.mixin-query.mixin-query-select > .btn {
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.mixin-query.mixin-query-select .fluid-wrapper {
  height: 35px;
}
.mixin-query.mixin-query-select .fluid-wrapper .inner-wrapper {
  height: 100%;
}
.mixin-query.mixin-query-condition {
  float: right;
}
.mixin-query.mixin-query-condition .select2 {
  width: 180px !important;
}

.mixin-switcher {
  display: flex;
  position: relative;
  font-size: 0;
  border: 1px solid #000;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-switcher span {
  display: inline-block;
  padding: 2px 5px;
  min-width: 20px;
  min-height: 18px;
  color: rgba(0, 0, 0, 0);
  font-size: 12px;
  line-height: 1em;
  text-align: center;
  background: #000;
  border: 1px solid #fff;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
.mixin-switcher span.active {
  color: #000;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.mixin-toggle-password {
  float: right;
  position: relative;
  z-index: 10;
  top: -34px;
  width: 50px;
  height: 34px;
  margin-bottom: -34px;
  padding: 0 10px;
  line-height: 34px;
  text-align: right;
}
.mixin-toggle-password .icon {
  color: #1c84c6;
  font-size: 20px;
  line-height: inherit;
}

.mixin-tooltip {
  display: block !important;
  position: fixed !important;
  z-index: 9999999 !important;
  top: 0;
  left: 0;
  width: auto !important;
  height: auto !important;
  text-align: left !important;
  text-indent: 0 !important;
  overflow: visible !important;
  pointer-events: none !important;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mixin-tooltip.setup {
  -webkit-transition: all 0s ease-out 0s;
  -moz-transition: all 0s ease-out 0s;
  -ms-transition: all 0s ease-out 0s;
  -o-transition: all 0s ease-out 0s;
  transition: all 0s ease-out 0s;
}
.mixin-tooltip.show {
  pointer-events: auto !important;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mixin-tooltip .text {
  position: relative !important;
  display: block !important;
  z-index: 2 !important;
  margin: 0 !important;
  padding: 10px 15px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  text-transform: none !important;
  white-space: nowrap !important;
}
.mixin-tooltip .text a,
.mixin-tooltip .text input[type=submit],
.mixin-tooltip .text .link-style {
  color: inherit !important;
  font: inherit !important;
  line-height: inherit !important;
  text-decoration: underline !important;
}
.mixin-tooltip .text br {
  line-height: 1.2em;
}
.mixin-tooltip .text form {
  color: inherit !important;
  font: inherit !important;
  line-height: inherit !important;
}
.mixin-tooltip .text-bg {
  display: block;
  border: 1px solid #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -ms-filter: alpha(opacity=70);
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
}
.mixin-tooltip .text-bg .arrow-mask {
  display: block;
  position: absolute;
  overflow: hidden;
}
.mixin-tooltip .text-bg .arrow-mask .arrow-position {
  position: absolute;
}
.mixin-tooltip .text-bg .arrow-mask .arrow-position .arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  background: #000;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
}
.mixin-tooltip.top {
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.mixin-tooltip.top .text {
  top: -15px !important;
}
.mixin-tooltip.top .text-bg {
  top: -15px;
}
.mixin-tooltip.top .text-bg .arrow-mask {
  top: 100%;
  left: 0;
  width: 100%;
  height: 20px;
}
.mixin-tooltip.top .text-bg .arrow-mask .arrow-position {
  top: 0;
  left: 50%;
}
.mixin-tooltip.top .text-bg .arrow-mask .arrow-position .arrow {
  top: -18px;
  left: 0;
}
.mixin-tooltip.bottom {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-tooltip.bottom .text {
  top: 15px !important;
}
.mixin-tooltip.bottom .text-bg {
  top: 15px;
}
.mixin-tooltip.bottom .text-bg .arrow-mask {
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 20px;
}
.mixin-tooltip.bottom .text-bg .arrow-mask .arrow-position {
  bottom: 0;
  left: 50%;
}
.mixin-tooltip.bottom .text-bg .arrow-mask .arrow-position .arrow {
  bottom: -10px;
  left: 0;
}
.mixin-tooltip.left {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-tooltip.left .text {
  left: -15px !important;
}
.mixin-tooltip.left .text-bg {
  left: -15px;
}
.mixin-tooltip.left .text-bg .arrow-mask {
  top: 0;
  left: 100%;
  width: 20px;
  height: 100%;
}
.mixin-tooltip.left .text-bg .arrow-mask .arrow-position {
  top: 50%;
  left: 0;
}
.mixin-tooltip.left .text-bg .arrow-mask .arrow-position .arrow {
  top: -13px;
  left: -3px;
}
.mixin-tooltip.right {
  left: 140%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-tooltip.right .text {
  left: 15px;
}
.mixin-tooltip.right .text-bg {
  left: 15px;
}
.mixin-tooltip.right .text-bg .arrow-mask {
  top: 0;
  right: 100%;
  width: 20px;
  height: 100%;
}
.mixin-tooltip.right .text-bg .arrow-mask .arrow-position {
  top: 50%;
  right: 0;
}
.mixin-tooltip.right .text-bg .arrow-mask .arrow-position .arrow {
  top: -13px;
  right: -23px;
}
.mixin-tooltip.shift-left .text {
  left: -30% !important;
}
.mixin-tooltip.shift-left .text-bg {
  left: -30% !important;
}
.mixin-tooltip.shift-left .text-bg .arrow-mask .arrow-position {
  left: 80%;
}
.mixin-tooltip.shift-right .text {
  left: 30% !important;
}
.mixin-tooltip.shift-right .text-bg {
  left: 30% !important;
}
.mixin-tooltip.shift-right .text-bg .arrow-mask .arrow-position {
  left: 20%;
}
.mixin-tooltip.wrap .text {
  white-space: normal !important;
}
.mixin-tooltip.uppercase .text {
  text-transform: uppercase !important;
}
.mixin-tooltip.center .text {
  text-align: center !important;
}
.mixin-tooltip.on-click-close {
  cursor: default;
}

/* == CORE / GLOBALS / MODULES ====================================================== */
/* == CORE / APP / PLUGINS ====================================================== */
/* == CORE / APP / MODULES ====================================================== */
#overlay {
  position: fixed !important;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  cursor: initial !important;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#overlay > .outer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#overlay > .outer > .wrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  text-align: left;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#overlay > .outer > .wrapper > .back {
  display: none;
  float: left;
  position: relative;
  z-index: 3;
  width: 22px;
  height: 22px;
  border: 2px solid #676a6c;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#overlay > .outer > .wrapper > .back .trigger {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#overlay > .outer > .wrapper > .back:before, #overlay > .outer > .wrapper > .back:after {
  content: "";
  top: 8px;
  left: 7px;
  width: 9px;
  height: 2px;
  position: absolute;
  background: #676a6c;
}
#overlay > .outer > .wrapper > .back:before {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 3px;
  -moz-transform-origin: 0 3px;
  -ms-transform-origin: 0 3px;
  -o-transform-origin: 0 3px;
  transform-origin: 0 3px;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#overlay > .outer > .wrapper > .back:after {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 -1px;
  -moz-transform-origin: 0 -1px;
  -ms-transform-origin: 0 -1px;
  -o-transform-origin: 0 -1px;
  transform-origin: 0 -1px;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#overlay > .outer > .wrapper > .back.show {
  display: block;
}
#overlay > .outer > .wrapper > .close {
  float: right;
  position: relative;
  z-index: 3;
  width: 22px;
  height: 22px;
  border: 1px solid #676a6c;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#overlay > .outer > .wrapper > .close .trigger {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#overlay > .outer > .wrapper > .close.hide {
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
#overlay > .outer > .wrapper > .close:before, #overlay > .outer > .wrapper > .close:after {
  content: "";
  top: 8px;
  left: 2px;
  width: 14px;
  height: 2px;
  position: absolute;
  background: #676a6c;
}
#overlay > .outer > .wrapper > .close:before {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#overlay > .outer > .wrapper > .close:after {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#overlay > .outer > .wrapper > .content {
  clear: both;
  position: relative;
  z-index: 1;
  padding-top: 10px;
  overflow-x: hidden;
}
#overlay > .outer > .wrapper > .content .chunk-overlay {
  max-width: 100%;
}
#overlay > .outer > .wrapper > .loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#overlay > .outer > .wrapper > .loading.show {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#overlay > .outer > .wrapper > .loading:after {
  content: "Loading...";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#overlay.loading > .outer > .wrapper {
  overflow-y: hidden;
}
#overlay.locked > .outer > .wrapper > .close {
  display: none;
}

#overlay_wrapper {
  overflow: hidden !important;
}

body > #overlay {
  display: none;
}

@media screen and (max-width: 400px) {
  #overlay {
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}
#slideup .links {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 20px;
  -moz-gap: 0 20px;
  -ms-gap: 0 20px;
  -o-gap: 0 20px;
  gap: 0 20px;
}
#slideup .links .label {
  cursor: pointer;
}
#slideup .contents {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#slideup .contents .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content.show {
  pointer-events: initial;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 0.3s;
  -moz-transition: opacity 0.3s ease-out 0.3s;
  -ms-transition: opacity 0.3s ease-out 0.3s;
  -o-transition: opacity 0.3s ease-out 0.3s;
  transition: opacity 0.3s ease-out 0.3s;
}
#slideup .contents.show .close {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 1s;
  -moz-transition: opacity 0.3s ease-out 1s;
  -ms-transition: opacity 0.3s ease-out 1s;
  -o-transition: opacity 0.3s ease-out 1s;
  transition: opacity 0.3s ease-out 1s;
}

/* == CORE / APP / PAGES ====================================================== */
/* == PROJECT / APP / PLUGINS ====================================================== */
.notifyjs-corner {
  left: 50%;
  right: auto !important;
  z-index: 300000 !important;
  width: 100%;
  max-width: 600px;
  margin: 0 !important;
  padding: 7px 10px;
  text-align: center;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.notifyjs-corner .notifyjs-wrapper {
  margin: 3px 0 !important;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container {
  margin: 0 !important;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container #notify {
  display: inline-block;
  padding: 10px 10px 7px 10px;
  background: #005e7d;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container #notify span {
  color: #fbfeff;
  font-size: 16px;
  hyphens: auto;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container #notify span a {
  color: inherit;
  font: inherit;
  text-decoration: underline;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container #notify span.icon {
  top: 3px;
  margin-right: 10px;
}

/* PrismJS 1.17.1
https://prismjs.com/download.html#themes=prism&languages=markup */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*=language-],
pre[class*=language-] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection, pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection, code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::selection, pre[class*=language-] ::selection,
code[class*=language-]::selection, code[class*=language-] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre[class*=language-] {
  margin: 0;
  padding: 10px 15px;
}

code[class*=language-] {
  padding: 0 !important;
  white-space: pre-wrap;
  word-break: break-word;
  background: rgba(0, 0, 0, 0) !important;
}
code[class*=language-] * {
  font-family: Courier;
  font-size: 16px;
}

.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #a56cbb;
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: #03aa9e;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #037faa;
}

.token.punctuation {
  color: #628290;
}

body > div[class^=privacypolicies-com]:first-child {
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb {
  top: auto;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: auto;
  max-width: 1200px;
  max-height: calc(100vh - 40px);
  margin: 0 auto;
  background: #fff;
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px !important;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none !important;
  cursor: pointer;
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject {
  color: #fbfeff;
  background: #03aa9e;
  border: 1px solid #03aa9e;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
  color: #b6c1c4;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #b6c1c4;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-title {
  margin-bottom: 10px;
  color: #037faa;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: "Museo Slab", sans-serif;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-text {
  font-family: "Open Sans", sans-serif;
  color: #628290;
  font-size: 16px;
  line-height: 1.5em;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-main-container {
  padding: 20px;
}
body > div[class^=privacypolicies-com]:first-child.show {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

#privacypolicies-com---preferences-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] {
  left: 20px;
  right: 20px;
  width: auto;
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container {
  max-height: 100%;
  overflow-y: auto;
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head {
  display: block;
  position: relative;
  background: #fff;
  border-color: #b6c1c4;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-title {
  display: block;
  padding: 0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-title .cc-pc-head-title-headline {
  padding: 60px 20px 20px 20px;
  color: #037faa;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: "Museo Slab", sans-serif;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  min-height: unset;
  padding: 0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-lang-select {
  display: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  font-size: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close .trigger {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:before, #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:after {
  content: "";
  top: 10px;
  left: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  background: #628290;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:before {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:after {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs {
  width: 200px;
  min-width: 200px;
  background: #fff;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item {
  position: relative;
  border-color: #f3f6f7;
  background: #fff;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item .cc-cp-body-tabs-item-link {
  padding: 7px 15px 3px 20px;
  color: #628290;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true] {
  background: #f3f6f7 !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true]:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  z-index: 1;
  width: 5px;
  background: #037faa;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content {
  background: #f3f6f7 !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry {
  padding: 20px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-title {
  color: #037faa;
  font-weight: 600;
  line-height: 1.3em;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text {
  color: #628290;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6em;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a {
  display: inline;
  color: #42bbef;
  font: inherit;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a:hover {
  text-decoration: underline;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a:focus {
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox {
  position: relative;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] {
  display: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] + label:before {
  border-color: #b6c1c4;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox]:checked + label:before {
  background: #03aa9e;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] + label:after {
  border-color: #b6c1c4;
  background: #fff;
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot {
  display: block;
  text-align: right;
  background: #fff;
  border-color: #b6c1c4;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-byline {
  display: none !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button {
  padding: 20px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
  display: inline-block;
  margin: 0 !important;
  padding: 10px 15px;
  color: #fbfeff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  background: #03aa9e;
  border: 1px solid #03aa9e;
  box-shadow: none !important;
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
#privacypolicies-com---preferences-center.show {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

@media screen and (max-width: 700px) {
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
    float: none;
    display: block;
    width: 100%;
    margin: 0 0 20px 0 !important;
  }
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 600px) {
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  body > div[class^=privacypolicies-com]:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 10px;
    right: 10px;
    max-height: calc(100vh - 20px);
  }
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}
/**
Ion.RangeSlider, 2.3.1
© Denis Ineshin, 2010 - 2019, IonDen.com
Build date: 2019-12-19 16:51:02
*/
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9;
}

.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}

.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453;
}

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: white;
  background: linear-gradient(to bottom, #ddd -50%, white 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
}

.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66, 139, 202, 0.5);
}

.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
  border-radius: 30px;
}

.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0, 0, 0, 0.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
}

.irs--big .irs-min,
.irs--big .irs-max {
  top: 0;
  padding: 1px 5px;
  color: white;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
}

.irs--big .irs-from,
.irs--big .irs-to,
.irs--big .irs-single {
  color: white;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  border-radius: 3px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern {
  height: 55px;
}

.irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
}

.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209, 214, 224, 0.5);
}

.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: white;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
}

.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
}

.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min,
.irs--modern .irs-max {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: white;
  background-color: #d1d6e0;
  border-radius: 5px;
}

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: white;
  border-radius: 5px;
}

.irs--modern .irs-from:before,
.irs--modern .irs-to:before,
.irs--modern .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: black;
  border-radius: 2px;
}

.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5);
}

.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2;
}

.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: black;
}

.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: black;
}

.irs--sharp .irs-min,
.irs--sharp .irs-max {
  color: white;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: 0.4;
  background-color: #a804b2;
  border-radius: 2px;
}

.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: white;
  border-radius: 2px;
}

.irs--sharp .irs-from:before,
.irs--sharp .irs-to:before,
.irs--sharp .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
}

.irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #006cfa;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5);
}

.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min,
.irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #006cfa;
  color: white;
  border-radius: 4px;
}

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #006cfa;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede;
}

.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: black;
}

.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede;
}

.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid black;
  background-color: white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min,
.irs--square .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: black;
  color: white;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

/* v1.0.7 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
  width: 600px;
  height: 400px;
  position: relative;
  direction: ltr;
  touch-action: pan-y pinch-zoom;
}

.royalSlider > * {
  float: left;
}

.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
  -webkit-backface-visibility: hidden;
}

.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
  -webkit-transform: none;
}

.rsOverflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap .rsOverflow {
  position: absolute;
  left: 0;
  top: 0;
}

.rsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsArrow,
.rsThumbsArrow {
  cursor: pointer;
}

.rsThumb {
  float: left;
  position: relative;
}

.rsArrow,
.rsNav,
.rsThumbsArrow {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.rsHidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  -moz-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  -o-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

.rsGCaption {
  width: 100%;
  float: left;
  text-align: center;
}

/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.royalSlider .rsSlide.rsFakePreloader {
  opacity: 1 !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  display: none;
}

.rsSlide {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
  height: auto;
}

.rsContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.rsPreloader {
  position: absolute;
  z-index: 0;
}

.rsNav {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}

.rsNavItem {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
}

.rsThumbs {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  z-index: 22;
}

.rsTabs {
  float: left;
  background: none !important;
}

.rsTabs,
.rsThumbs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVideoContainer {
  width: auto;
  height: auto;
  line-height: 0;
  position: relative;
}

.rsVideoFrameHolder {
  position: absolute;
  left: 0;
  top: 0;
  background: #141414;
  opacity: 0;
  -webkit-transition: 0.3s;
}

.rsVideoFrameHolder.rsVideoActive {
  opacity: 1;
}

.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 44px;
}

.rsABlock {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 15;
}

img.rsImg {
  max-width: none;
}

.grab-cursor {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbing-cursor {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.rsLink {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 20;
  background: rgba(0, 0, 0, 0);
}

/* == PROJECT / APP / HELPERS ====================================================== */
.helper-loading {
  display: none;
  background: rgba(255, 255, 255, 0.5);
}
.helper-loading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background-image: url("../images/assets/app/layout/loading.svg?1744355188281");
  background-size: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* == PROJECT / APP / MIXINS / FORM ====================================================== */
form .row {
  position: relative;
}
form .row > a,
form .row .link-style {
  display: inline-block;
  padding: 0 15px 0 5px;
  color: #42bbef;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
form .row > a.type-text-style,
form .row .link-style.type-text-style {
  padding: 4px 5px;
}
form .row > a:hover:not(.no-css-hover),
form .row .link-style:hover:not(.no-css-hover) {
  text-decoration: underline;
}
form .row > input + a,
form .row > input + .link-style,
form .row > .select2 + a,
form .row > .select2 + .link-style {
  padding-top: 3px;
}
form .row .validation-info {
  position: absolute;
  bottom: 0;
  left: 50%;
}
form .row .counter-wrapper {
  float: right;
  position: relative;
  top: 3px;
  right: 5px;
  color: #aac3cd;
}
form .row .validation-status-wrapper {
  clear: left;
  margin: 5px 0 0 5px;
}
form .row .validation-status-wrapper > span {
  dislpay: block;
}
form .row .validation-status-wrapper > span span {
  display: inline-block;
  color: #aac3cd;
}
form .row .validation-status-wrapper > span span.icon {
  top: 2px;
  margin-right: 5px;
}
form .row .validation-status-wrapper > span span.icon.icon-checkmark {
  color: #03aa9e;
}
form .row .mixin-btn {
  justify-content: right;
}
form .row.row-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(3, 127, 170, 0.15), rgba(3, 127, 170, 0.15) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
  background-size: 8px 1px;
  pointer-events: none;
}
form .row.row-border:last-child {
  padding-bottom: 0;
}
form .row.row-border:last-child:after {
  content: none;
}
form .row.not-verified input[type=text],
form .row.not-verified input[type=number],
form .row.not-verified input[type=email],
form .row.not-verified input[type=password],
form .row.not-verified input[type=date],
form .row.not-verified input[type=time],
form .row.not-verified input[type=datetime-local],
form .row.not-verified textarea,
form .row.not-verified .input-checkbox,
form .row.not-verified .input-radio,
form .row.not-verified .type-text-style,
form .row.not-verified .type-textarea-style {
  border-color: #a56cbb !important;
}
form .row.not-verified input[type=text]:focus,
form .row.not-verified input[type=number]:focus,
form .row.not-verified input[type=email]:focus,
form .row.not-verified input[type=password]:focus,
form .row.not-verified input[type=date]:focus,
form .row.not-verified input[type=time]:focus,
form .row.not-verified input[type=datetime-local]:focus,
form .row.not-verified textarea:focus,
form .row.not-verified .input-checkbox:focus,
form .row.not-verified .input-radio:focus,
form .row.not-verified .type-text-style:focus,
form .row.not-verified .type-textarea-style:focus {
  -webkit-box-shadow: inset 0 0 3px 0 #a56cbb;
  -moz-box-shadow: inset 0 0 3px 0 #a56cbb;
  -ms-box-shadow: inset 0 0 3px 0 #a56cbb;
  -o-box-shadow: inset 0 0 3px 0 #a56cbb;
  box-shadow: inset 0 0 3px 0 #a56cbb;
}
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--default,
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--single,
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--multiple,
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--default:focus,
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--single:focus,
form .row.not-verified .select2.select2-container .select2-selection.select2-selection--multiple:focus,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--default,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--single,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--multiple,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--default:focus,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--single:focus,
form .row.not-verified .select2.select2-container--open .select2-selection.select2-selection--multiple:focus {
  border-color: #a56cbb !important;
}
form .row.not-valid input[type=text],
form .row.not-valid input[type=number],
form .row.not-valid input[type=email],
form .row.not-valid input[type=password],
form .row.not-valid input[type=date],
form .row.not-valid input[type=time],
form .row.not-valid input[type=datetime-local],
form .row.not-valid textarea,
form .row.not-valid .input-checkbox,
form .row.not-valid .input-radio,
form .row.not-valid .type-text-style,
form .row.not-valid .type-textarea-style {
  border-color: #e2430f !important;
}
form .row.not-valid input[type=text]:focus,
form .row.not-valid input[type=number]:focus,
form .row.not-valid input[type=email]:focus,
form .row.not-valid input[type=password]:focus,
form .row.not-valid input[type=date]:focus,
form .row.not-valid input[type=time]:focus,
form .row.not-valid input[type=datetime-local]:focus,
form .row.not-valid textarea:focus,
form .row.not-valid .input-checkbox:focus,
form .row.not-valid .input-radio:focus,
form .row.not-valid .type-text-style:focus,
form .row.not-valid .type-textarea-style:focus {
  -webkit-box-shadow: inset 0 0 3px 0 #e2430f;
  -moz-box-shadow: inset 0 0 3px 0 #e2430f;
  -ms-box-shadow: inset 0 0 3px 0 #e2430f;
  -o-box-shadow: inset 0 0 3px 0 #e2430f;
  box-shadow: inset 0 0 3px 0 #e2430f;
}
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--default,
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--single,
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--multiple,
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--default:focus,
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--single:focus,
form .row.not-valid .select2.select2-container .select2-selection.select2-selection--multiple:focus,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--default,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--single,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--multiple,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--default:focus,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--single:focus,
form .row.not-valid .select2.select2-container--open .select2-selection.select2-selection--multiple:focus {
  border-color: #e2430f !important;
}
form .row:last-child {
  margin-bottom: 10px;
}
form .row:after {
  content: "";
  display: block;
  clear: both;
}
form > .row:last-child {
  margin-bottom: 0;
}
form .form-box {
  position: relative;
  z-index: 1;
}
form .form-box.error-flag {
  border-color: #e2430f !important;
}
form input[type=text],
form input[type=number],
form input[type=email],
form input[type=password],
form input[type=date],
form input[type=time],
form input[type=datetime-local],
form textarea,
form .input-checkbox,
form .input-radio,
form .input-switcher,
form .type-text-style,
form .type-textarea-style {
  color: #628290;
  border-color: #aac3cd !important;
  background: #fff !important;
}
form input[type=text].unchangeable,
form input[type=number].unchangeable,
form input[type=email].unchangeable,
form input[type=password].unchangeable,
form input[type=date].unchangeable,
form input[type=time].unchangeable,
form input[type=datetime-local].unchangeable,
form textarea.unchangeable,
form .input-checkbox.unchangeable,
form .input-radio.unchangeable,
form .input-switcher.unchangeable,
form .type-text-style.unchangeable,
form .type-textarea-style.unchangeable {
  color: #aac3cd;
}
form input[type=text].input-checkbox, form input[type=text].input-radio,
form input[type=number].input-checkbox,
form input[type=number].input-radio,
form input[type=email].input-checkbox,
form input[type=email].input-radio,
form input[type=password].input-checkbox,
form input[type=password].input-radio,
form input[type=date].input-checkbox,
form input[type=date].input-radio,
form input[type=time].input-checkbox,
form input[type=time].input-radio,
form input[type=datetime-local].input-checkbox,
form input[type=datetime-local].input-radio,
form textarea.input-checkbox,
form textarea.input-radio,
form .input-checkbox.input-checkbox,
form .input-checkbox.input-radio,
form .input-radio.input-checkbox,
form .input-radio.input-radio,
form .input-switcher.input-checkbox,
form .input-switcher.input-radio,
form .type-text-style.input-checkbox,
form .type-text-style.input-radio,
form .type-textarea-style.input-checkbox,
form .type-textarea-style.input-radio {
  margin-top: 3px;
}
form input[type=text].input-checkbox label, form input[type=text].input-radio label,
form input[type=number].input-checkbox label,
form input[type=number].input-radio label,
form input[type=email].input-checkbox label,
form input[type=email].input-radio label,
form input[type=password].input-checkbox label,
form input[type=password].input-radio label,
form input[type=date].input-checkbox label,
form input[type=date].input-radio label,
form input[type=time].input-checkbox label,
form input[type=time].input-radio label,
form input[type=datetime-local].input-checkbox label,
form input[type=datetime-local].input-radio label,
form textarea.input-checkbox label,
form textarea.input-radio label,
form .input-checkbox.input-checkbox label,
form .input-checkbox.input-radio label,
form .input-radio.input-checkbox label,
form .input-radio.input-radio label,
form .input-switcher.input-checkbox label,
form .input-switcher.input-radio label,
form .type-text-style.input-checkbox label,
form .type-text-style.input-radio label,
form .type-textarea-style.input-checkbox label,
form .type-textarea-style.input-radio label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
form input[type=text].input-checkbox label span:not(.uniform), form input[type=text].input-radio label span:not(.uniform),
form input[type=number].input-checkbox label span:not(.uniform),
form input[type=number].input-radio label span:not(.uniform),
form input[type=email].input-checkbox label span:not(.uniform),
form input[type=email].input-radio label span:not(.uniform),
form input[type=password].input-checkbox label span:not(.uniform),
form input[type=password].input-radio label span:not(.uniform),
form input[type=date].input-checkbox label span:not(.uniform),
form input[type=date].input-radio label span:not(.uniform),
form input[type=time].input-checkbox label span:not(.uniform),
form input[type=time].input-radio label span:not(.uniform),
form input[type=datetime-local].input-checkbox label span:not(.uniform),
form input[type=datetime-local].input-radio label span:not(.uniform),
form textarea.input-checkbox label span:not(.uniform),
form textarea.input-radio label span:not(.uniform),
form .input-checkbox.input-checkbox label span:not(.uniform),
form .input-checkbox.input-radio label span:not(.uniform),
form .input-radio.input-checkbox label span:not(.uniform),
form .input-radio.input-radio label span:not(.uniform),
form .input-switcher.input-checkbox label span:not(.uniform),
form .input-switcher.input-radio label span:not(.uniform),
form .type-text-style.input-checkbox label span:not(.uniform),
form .type-text-style.input-radio label span:not(.uniform),
form .type-textarea-style.input-checkbox label span:not(.uniform),
form .type-textarea-style.input-radio label span:not(.uniform) {
  top: 0;
  display: block;
  color: #aac3cd;
  font-size: inherit;
  font-weight: inherit;
  line-height: 24px;
  hyphens: auto;
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
form input[type=text].input-checkbox label span:not(.uniform) a, form input[type=text].input-radio label span:not(.uniform) a,
form input[type=number].input-checkbox label span:not(.uniform) a,
form input[type=number].input-radio label span:not(.uniform) a,
form input[type=email].input-checkbox label span:not(.uniform) a,
form input[type=email].input-radio label span:not(.uniform) a,
form input[type=password].input-checkbox label span:not(.uniform) a,
form input[type=password].input-radio label span:not(.uniform) a,
form input[type=date].input-checkbox label span:not(.uniform) a,
form input[type=date].input-radio label span:not(.uniform) a,
form input[type=time].input-checkbox label span:not(.uniform) a,
form input[type=time].input-radio label span:not(.uniform) a,
form input[type=datetime-local].input-checkbox label span:not(.uniform) a,
form input[type=datetime-local].input-radio label span:not(.uniform) a,
form textarea.input-checkbox label span:not(.uniform) a,
form textarea.input-radio label span:not(.uniform) a,
form .input-checkbox.input-checkbox label span:not(.uniform) a,
form .input-checkbox.input-radio label span:not(.uniform) a,
form .input-radio.input-checkbox label span:not(.uniform) a,
form .input-radio.input-radio label span:not(.uniform) a,
form .input-switcher.input-checkbox label span:not(.uniform) a,
form .input-switcher.input-radio label span:not(.uniform) a,
form .type-text-style.input-checkbox label span:not(.uniform) a,
form .type-text-style.input-radio label span:not(.uniform) a,
form .type-textarea-style.input-checkbox label span:not(.uniform) a,
form .type-textarea-style.input-radio label span:not(.uniform) a {
  display: inline;
  color: #42bbef;
  hyphens: auto;
}
form input[type=text].input-checkbox label .uniform, form input[type=text].input-radio label .uniform,
form input[type=number].input-checkbox label .uniform,
form input[type=number].input-radio label .uniform,
form input[type=email].input-checkbox label .uniform,
form input[type=email].input-radio label .uniform,
form input[type=password].input-checkbox label .uniform,
form input[type=password].input-radio label .uniform,
form input[type=date].input-checkbox label .uniform,
form input[type=date].input-radio label .uniform,
form input[type=time].input-checkbox label .uniform,
form input[type=time].input-radio label .uniform,
form input[type=datetime-local].input-checkbox label .uniform,
form input[type=datetime-local].input-radio label .uniform,
form textarea.input-checkbox label .uniform,
form textarea.input-radio label .uniform,
form .input-checkbox.input-checkbox label .uniform,
form .input-checkbox.input-radio label .uniform,
form .input-radio.input-checkbox label .uniform,
form .input-radio.input-radio label .uniform,
form .input-switcher.input-checkbox label .uniform,
form .input-switcher.input-radio label .uniform,
form .type-text-style.input-checkbox label .uniform,
form .type-text-style.input-radio label .uniform,
form .type-textarea-style.input-checkbox label .uniform,
form .type-textarea-style.input-radio label .uniform {
  float: left;
  top: 0;
}
form input[type=text].input-checkbox.disabled, form input[type=text].input-radio.disabled,
form input[type=number].input-checkbox.disabled,
form input[type=number].input-radio.disabled,
form input[type=email].input-checkbox.disabled,
form input[type=email].input-radio.disabled,
form input[type=password].input-checkbox.disabled,
form input[type=password].input-radio.disabled,
form input[type=date].input-checkbox.disabled,
form input[type=date].input-radio.disabled,
form input[type=time].input-checkbox.disabled,
form input[type=time].input-radio.disabled,
form input[type=datetime-local].input-checkbox.disabled,
form input[type=datetime-local].input-radio.disabled,
form textarea.input-checkbox.disabled,
form textarea.input-radio.disabled,
form .input-checkbox.input-checkbox.disabled,
form .input-checkbox.input-radio.disabled,
form .input-radio.input-checkbox.disabled,
form .input-radio.input-radio.disabled,
form .input-switcher.input-checkbox.disabled,
form .input-switcher.input-radio.disabled,
form .type-text-style.input-checkbox.disabled,
form .type-text-style.input-radio.disabled,
form .type-textarea-style.input-checkbox.disabled,
form .type-textarea-style.input-radio.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
form input[type=text].input-checkbox.input-checkbox-checked label span:not(.uniform), form input[type=text].input-checkbox.input-radio-checked label span:not(.uniform), form input[type=text].input-radio.input-checkbox-checked label span:not(.uniform), form input[type=text].input-radio.input-radio-checked label span:not(.uniform),
form input[type=number].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=number].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=number].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=number].input-radio.input-radio-checked label span:not(.uniform),
form input[type=email].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=email].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=email].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=email].input-radio.input-radio-checked label span:not(.uniform),
form input[type=password].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=password].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=password].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=password].input-radio.input-radio-checked label span:not(.uniform),
form input[type=date].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=date].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=date].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=date].input-radio.input-radio-checked label span:not(.uniform),
form input[type=time].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=time].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=time].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=time].input-radio.input-radio-checked label span:not(.uniform),
form input[type=datetime-local].input-checkbox.input-checkbox-checked label span:not(.uniform),
form input[type=datetime-local].input-checkbox.input-radio-checked label span:not(.uniform),
form input[type=datetime-local].input-radio.input-checkbox-checked label span:not(.uniform),
form input[type=datetime-local].input-radio.input-radio-checked label span:not(.uniform),
form textarea.input-checkbox.input-checkbox-checked label span:not(.uniform),
form textarea.input-checkbox.input-radio-checked label span:not(.uniform),
form textarea.input-radio.input-checkbox-checked label span:not(.uniform),
form textarea.input-radio.input-radio-checked label span:not(.uniform),
form .input-checkbox.input-checkbox.input-checkbox-checked label span:not(.uniform),
form .input-checkbox.input-checkbox.input-radio-checked label span:not(.uniform),
form .input-checkbox.input-radio.input-checkbox-checked label span:not(.uniform),
form .input-checkbox.input-radio.input-radio-checked label span:not(.uniform),
form .input-radio.input-checkbox.input-checkbox-checked label span:not(.uniform),
form .input-radio.input-checkbox.input-radio-checked label span:not(.uniform),
form .input-radio.input-radio.input-checkbox-checked label span:not(.uniform),
form .input-radio.input-radio.input-radio-checked label span:not(.uniform),
form .input-switcher.input-checkbox.input-checkbox-checked label span:not(.uniform),
form .input-switcher.input-checkbox.input-radio-checked label span:not(.uniform),
form .input-switcher.input-radio.input-checkbox-checked label span:not(.uniform),
form .input-switcher.input-radio.input-radio-checked label span:not(.uniform),
form .type-text-style.input-checkbox.input-checkbox-checked label span:not(.uniform),
form .type-text-style.input-checkbox.input-radio-checked label span:not(.uniform),
form .type-text-style.input-radio.input-checkbox-checked label span:not(.uniform),
form .type-text-style.input-radio.input-radio-checked label span:not(.uniform),
form .type-textarea-style.input-checkbox.input-checkbox-checked label span:not(.uniform),
form .type-textarea-style.input-checkbox.input-radio-checked label span:not(.uniform),
form .type-textarea-style.input-radio.input-checkbox-checked label span:not(.uniform),
form .type-textarea-style.input-radio.input-radio-checked label span:not(.uniform) {
  color: #628290;
}
form input[type=text].input-switcher .mixin-switcher,
form input[type=number].input-switcher .mixin-switcher,
form input[type=email].input-switcher .mixin-switcher,
form input[type=password].input-switcher .mixin-switcher,
form input[type=date].input-switcher .mixin-switcher,
form input[type=time].input-switcher .mixin-switcher,
form input[type=datetime-local].input-switcher .mixin-switcher,
form textarea.input-switcher .mixin-switcher,
form .input-checkbox.input-switcher .mixin-switcher,
form .input-radio.input-switcher .mixin-switcher,
form .input-switcher.input-switcher .mixin-switcher,
form .type-text-style.input-switcher .mixin-switcher,
form .type-textarea-style.input-switcher .mixin-switcher {
  margin: 2px 4px 2px 8px;
}
form input[type=text].input-switcher > span,
form input[type=number].input-switcher > span,
form input[type=email].input-switcher > span,
form input[type=password].input-switcher > span,
form input[type=date].input-switcher > span,
form input[type=time].input-switcher > span,
form input[type=datetime-local].input-switcher > span,
form textarea.input-switcher > span,
form .input-checkbox.input-switcher > span,
form .input-radio.input-switcher > span,
form .input-switcher.input-switcher > span,
form .type-text-style.input-switcher > span,
form .type-textarea-style.input-switcher > span {
  font-size: 12px;
  line-height: 24px;
}
form input[type=text].type-text-style, form input[type=text].type-textarea-style,
form input[type=number].type-text-style,
form input[type=number].type-textarea-style,
form input[type=email].type-text-style,
form input[type=email].type-textarea-style,
form input[type=password].type-text-style,
form input[type=password].type-textarea-style,
form input[type=date].type-text-style,
form input[type=date].type-textarea-style,
form input[type=time].type-text-style,
form input[type=time].type-textarea-style,
form input[type=datetime-local].type-text-style,
form input[type=datetime-local].type-textarea-style,
form textarea.type-text-style,
form textarea.type-textarea-style,
form .input-checkbox.type-text-style,
form .input-checkbox.type-textarea-style,
form .input-radio.type-text-style,
form .input-radio.type-textarea-style,
form .input-switcher.type-text-style,
form .input-switcher.type-textarea-style,
form .type-text-style.type-text-style,
form .type-text-style.type-textarea-style,
form .type-textarea-style.type-text-style,
form .type-textarea-style.type-textarea-style {
  font-size: 16px;
  text-decoration: none;
}
form input[type=text].type-text-style > .text, form input[type=text].type-textarea-style > .text,
form input[type=number].type-text-style > .text,
form input[type=number].type-textarea-style > .text,
form input[type=email].type-text-style > .text,
form input[type=email].type-textarea-style > .text,
form input[type=password].type-text-style > .text,
form input[type=password].type-textarea-style > .text,
form input[type=date].type-text-style > .text,
form input[type=date].type-textarea-style > .text,
form input[type=time].type-text-style > .text,
form input[type=time].type-textarea-style > .text,
form input[type=datetime-local].type-text-style > .text,
form input[type=datetime-local].type-textarea-style > .text,
form textarea.type-text-style > .text,
form textarea.type-textarea-style > .text,
form .input-checkbox.type-text-style > .text,
form .input-checkbox.type-textarea-style > .text,
form .input-radio.type-text-style > .text,
form .input-radio.type-textarea-style > .text,
form .input-switcher.type-text-style > .text,
form .input-switcher.type-textarea-style > .text,
form .type-text-style.type-text-style > .text,
form .type-text-style.type-textarea-style > .text,
form .type-textarea-style.type-text-style > .text,
form .type-textarea-style.type-textarea-style > .text {
  color: #aac3cd;
}
form input[type=text].type-text-style .icon, form input[type=text].type-textarea-style .icon,
form input[type=number].type-text-style .icon,
form input[type=number].type-textarea-style .icon,
form input[type=email].type-text-style .icon,
form input[type=email].type-textarea-style .icon,
form input[type=password].type-text-style .icon,
form input[type=password].type-textarea-style .icon,
form input[type=date].type-text-style .icon,
form input[type=date].type-textarea-style .icon,
form input[type=time].type-text-style .icon,
form input[type=time].type-textarea-style .icon,
form input[type=datetime-local].type-text-style .icon,
form input[type=datetime-local].type-textarea-style .icon,
form textarea.type-text-style .icon,
form textarea.type-textarea-style .icon,
form .input-checkbox.type-text-style .icon,
form .input-checkbox.type-textarea-style .icon,
form .input-radio.type-text-style .icon,
form .input-radio.type-textarea-style .icon,
form .input-switcher.type-text-style .icon,
form .input-switcher.type-textarea-style .icon,
form .type-text-style.type-text-style .icon,
form .type-text-style.type-textarea-style .icon,
form .type-textarea-style.type-text-style .icon,
form .type-textarea-style.type-textarea-style .icon {
  top: 5px;
  color: #037faa;
  font-size: 16px;
}
form input[type=text].type-text-style .icon.icon-checkmark, form input[type=text].type-textarea-style .icon.icon-checkmark,
form input[type=number].type-text-style .icon.icon-checkmark,
form input[type=number].type-textarea-style .icon.icon-checkmark,
form input[type=email].type-text-style .icon.icon-checkmark,
form input[type=email].type-textarea-style .icon.icon-checkmark,
form input[type=password].type-text-style .icon.icon-checkmark,
form input[type=password].type-textarea-style .icon.icon-checkmark,
form input[type=date].type-text-style .icon.icon-checkmark,
form input[type=date].type-textarea-style .icon.icon-checkmark,
form input[type=time].type-text-style .icon.icon-checkmark,
form input[type=time].type-textarea-style .icon.icon-checkmark,
form input[type=datetime-local].type-text-style .icon.icon-checkmark,
form input[type=datetime-local].type-textarea-style .icon.icon-checkmark,
form textarea.type-text-style .icon.icon-checkmark,
form textarea.type-textarea-style .icon.icon-checkmark,
form .input-checkbox.type-text-style .icon.icon-checkmark,
form .input-checkbox.type-textarea-style .icon.icon-checkmark,
form .input-radio.type-text-style .icon.icon-checkmark,
form .input-radio.type-textarea-style .icon.icon-checkmark,
form .input-switcher.type-text-style .icon.icon-checkmark,
form .input-switcher.type-textarea-style .icon.icon-checkmark,
form .type-text-style.type-text-style .icon.icon-checkmark,
form .type-text-style.type-textarea-style .icon.icon-checkmark,
form .type-textarea-style.type-text-style .icon.icon-checkmark,
form .type-textarea-style.type-textarea-style .icon.icon-checkmark {
  color: #03aa9e;
}
form input[type=text].type-text-style .icon.icon-warning, form input[type=text].type-textarea-style .icon.icon-warning,
form input[type=number].type-text-style .icon.icon-warning,
form input[type=number].type-textarea-style .icon.icon-warning,
form input[type=email].type-text-style .icon.icon-warning,
form input[type=email].type-textarea-style .icon.icon-warning,
form input[type=password].type-text-style .icon.icon-warning,
form input[type=password].type-textarea-style .icon.icon-warning,
form input[type=date].type-text-style .icon.icon-warning,
form input[type=date].type-textarea-style .icon.icon-warning,
form input[type=time].type-text-style .icon.icon-warning,
form input[type=time].type-textarea-style .icon.icon-warning,
form input[type=datetime-local].type-text-style .icon.icon-warning,
form input[type=datetime-local].type-textarea-style .icon.icon-warning,
form textarea.type-text-style .icon.icon-warning,
form textarea.type-textarea-style .icon.icon-warning,
form .input-checkbox.type-text-style .icon.icon-warning,
form .input-checkbox.type-textarea-style .icon.icon-warning,
form .input-radio.type-text-style .icon.icon-warning,
form .input-radio.type-textarea-style .icon.icon-warning,
form .input-switcher.type-text-style .icon.icon-warning,
form .input-switcher.type-textarea-style .icon.icon-warning,
form .type-text-style.type-text-style .icon.icon-warning,
form .type-text-style.type-textarea-style .icon.icon-warning,
form .type-textarea-style.type-text-style .icon.icon-warning,
form .type-textarea-style.type-textarea-style .icon.icon-warning {
  top: 4px;
  font-size: 18px;
}
form input[type=text].type-text-style[href], form input[type=text].type-textarea-style[href],
form input[type=number].type-text-style[href],
form input[type=number].type-textarea-style[href],
form input[type=email].type-text-style[href],
form input[type=email].type-textarea-style[href],
form input[type=password].type-text-style[href],
form input[type=password].type-textarea-style[href],
form input[type=date].type-text-style[href],
form input[type=date].type-textarea-style[href],
form input[type=time].type-text-style[href],
form input[type=time].type-textarea-style[href],
form input[type=datetime-local].type-text-style[href],
form input[type=datetime-local].type-textarea-style[href],
form textarea.type-text-style[href],
form textarea.type-textarea-style[href],
form .input-checkbox.type-text-style[href],
form .input-checkbox.type-textarea-style[href],
form .input-radio.type-text-style[href],
form .input-radio.type-textarea-style[href],
form .input-switcher.type-text-style[href],
form .input-switcher.type-textarea-style[href],
form .type-text-style.type-text-style[href],
form .type-text-style.type-textarea-style[href],
form .type-textarea-style.type-text-style[href],
form .type-textarea-style.type-textarea-style[href] {
  text-decoration: none !important;
}
form input[type=text].type-text-style[href] > .text, form input[type=text].type-textarea-style[href] > .text,
form input[type=number].type-text-style[href] > .text,
form input[type=number].type-textarea-style[href] > .text,
form input[type=email].type-text-style[href] > .text,
form input[type=email].type-textarea-style[href] > .text,
form input[type=password].type-text-style[href] > .text,
form input[type=password].type-textarea-style[href] > .text,
form input[type=date].type-text-style[href] > .text,
form input[type=date].type-textarea-style[href] > .text,
form input[type=time].type-text-style[href] > .text,
form input[type=time].type-textarea-style[href] > .text,
form input[type=datetime-local].type-text-style[href] > .text,
form input[type=datetime-local].type-textarea-style[href] > .text,
form textarea.type-text-style[href] > .text,
form textarea.type-textarea-style[href] > .text,
form .input-checkbox.type-text-style[href] > .text,
form .input-checkbox.type-textarea-style[href] > .text,
form .input-radio.type-text-style[href] > .text,
form .input-radio.type-textarea-style[href] > .text,
form .input-switcher.type-text-style[href] > .text,
form .input-switcher.type-textarea-style[href] > .text,
form .type-text-style.type-text-style[href] > .text,
form .type-text-style.type-textarea-style[href] > .text,
form .type-textarea-style.type-text-style[href] > .text,
form .type-textarea-style.type-textarea-style[href] > .text {
  color: #037faa;
  text-decoration: underline;
}
form input[type=text].type-text-style[href] .icon, form input[type=text].type-textarea-style[href] .icon,
form input[type=number].type-text-style[href] .icon,
form input[type=number].type-textarea-style[href] .icon,
form input[type=email].type-text-style[href] .icon,
form input[type=email].type-textarea-style[href] .icon,
form input[type=password].type-text-style[href] .icon,
form input[type=password].type-textarea-style[href] .icon,
form input[type=date].type-text-style[href] .icon,
form input[type=date].type-textarea-style[href] .icon,
form input[type=time].type-text-style[href] .icon,
form input[type=time].type-textarea-style[href] .icon,
form input[type=datetime-local].type-text-style[href] .icon,
form input[type=datetime-local].type-textarea-style[href] .icon,
form textarea.type-text-style[href] .icon,
form textarea.type-textarea-style[href] .icon,
form .input-checkbox.type-text-style[href] .icon,
form .input-checkbox.type-textarea-style[href] .icon,
form .input-radio.type-text-style[href] .icon,
form .input-radio.type-textarea-style[href] .icon,
form .input-switcher.type-text-style[href] .icon,
form .input-switcher.type-textarea-style[href] .icon,
form .type-text-style.type-text-style[href] .icon,
form .type-text-style.type-textarea-style[href] .icon,
form .type-textarea-style.type-text-style[href] .icon,
form .type-textarea-style.type-textarea-style[href] .icon {
  text-decoration: none !important;
}
form input[type=text].type-code-style,
form input[type=number].type-code-style,
form input[type=email].type-code-style,
form input[type=password].type-code-style,
form input[type=date].type-code-style,
form input[type=time].type-code-style,
form input[type=datetime-local].type-code-style,
form textarea.type-code-style,
form .input-checkbox.type-code-style,
form .input-radio.type-code-style,
form .input-switcher.type-code-style,
form .type-text-style.type-code-style,
form .type-textarea-style.type-code-style {
  background: rgba(3, 127, 170, 0.05) !important;
}
form input[type=text]:-webkit-autofill,
form input[type=number]:-webkit-autofill,
form input[type=email]:-webkit-autofill,
form input[type=password]:-webkit-autofill,
form input[type=date]:-webkit-autofill,
form input[type=time]:-webkit-autofill,
form input[type=datetime-local]:-webkit-autofill,
form textarea:-webkit-autofill,
form .input-checkbox:-webkit-autofill,
form .input-radio:-webkit-autofill,
form .input-switcher:-webkit-autofill,
form .type-text-style:-webkit-autofill,
form .type-textarea-style:-webkit-autofill {
  -webkit-text-fill-color: #aac3cd;
  -moz-text-fill-color: #aac3cd;
  -ms-text-fill-color: #aac3cd;
  -o-text-fill-color: #aac3cd;
  text-fill-color: #aac3cd;
}
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=email]:focus,
form input[type=password]:focus,
form input[type=date]:focus,
form input[type=time]:focus,
form input[type=datetime-local]:focus,
form textarea:focus,
form .input-checkbox:focus,
form .input-radio:focus,
form .input-switcher:focus,
form .type-text-style:focus,
form .type-textarea-style:focus {
  border-color: #42bbef !important;
  -webkit-box-shadow: inset 0 0 3px 0 #42bbef;
  -moz-box-shadow: inset 0 0 3px 0 #42bbef;
  -ms-box-shadow: inset 0 0 3px 0 #42bbef;
  -o-box-shadow: inset 0 0 3px 0 #42bbef;
  box-shadow: inset 0 0 3px 0 #42bbef;
}
form pre.type-text-style,
form pre.type-textarea-style {
  overflow: visible !important;
}
form pre.type-text-style .icon,
form pre.type-textarea-style .icon {
  position: absolute !important;
  top: -25px !important;
  right: 7px !important;
  cursor: pointer;
}
form .clm-wrapper .clm {
  padding: 0 10px !important;
}
form .clm-wrapper .clm .mixin-form-field-info {
  right: 10px;
}
form .clm-wrapper .clm:first-child {
  padding-left: 0 !important;
}
form .clm-wrapper .clm:last-child {
  padding-right: 0 !important;
}
form .clm-wrapper .clm:last-child .mixin-form-field-info {
  right: 0;
}
form .clm-wrapper.equal-distribution {
  margin: 0 -10px !important;
}
form .clm-wrapper.equal-distribution > .clm:first-child {
  padding-left: 10px !important;
}
form .clm-wrapper.equal-distribution > .clm:last-child {
  padding-right: 10px !important;
}
form .clm-wrapper.equal-distribution > .clm.clm-2:nth-child(odd) {
  padding-left: 10px;
}
form .clm-wrapper.equal-distribution > .clm.clm-2:nth-child(even) {
  padding-right: 10px;
}
form.division-form {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}
form.division-form .division-header {
  position: relative;
  margin-bottom: 0;
  font-size: 0;
}
form.division-form .division-header .mixin-btn {
  margin: 0 !important;
  padding: 0 25px !important;
}
form.division-form .division-header .mixin-btn .btn {
  position: relative;
  width: 100%;
  text-align: center;
  color: #fbfeff;
  background: #aac3cd;
  border-color: #aac3cd;
  overflow: visible;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0s ease-out 0s;
  -moz-transition: all 0s ease-out 0s;
  -ms-transition: all 0s ease-out 0s;
  -o-transition: all 0s ease-out 0s;
  transition: all 0s ease-out 0s;
}
form.division-form .division-header .mixin-btn .btn.active {
  z-index: 1;
  background: #037faa;
  border-color: #037faa;
}
form.division-form .division-header .mixin-btn .btn.active:before, form.division-form .division-header .mixin-btn .btn.active:after {
  background: #037faa;
}
form.division-form .division-header .mixin-btn .btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -15px;
  width: 30px;
  height: 38px;
  background: #aac3cd;
  transform: skewX(-30deg);
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  -ms-border-radius: 3px 0 0 0;
  -o-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}
form.division-form .division-header .mixin-btn .btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -1px;
  right: -15px;
  width: 30px;
  height: 38px;
  background: #aac3cd;
  transform: skewX(30deg);
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  -ms-border-radius: 0 3px 0 0;
  -o-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}
form.division-form .division-header .mixin-btn:first-child {
  padding-left: 0 !important;
}
form.division-form .division-header .mixin-btn:first-child .btn {
  margin-left: 0;
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  -ms-border-radius: 3px 0 0 0;
  -o-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}
form.division-form .division-header .mixin-btn:first-child .btn:before {
  content: none;
}
form.division-form .division-header .mixin-btn:last-child {
  padding-right: 0 !important;
}
form.division-form .division-header .mixin-btn:last-child .btn {
  margin-right: 0;
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  -ms-border-radius: 0 3px 0 0;
  -o-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}
form.division-form .division-header .mixin-btn:last-child .btn:after {
  content: none;
}
form.division-form .division-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #037faa;
}
form.division-form .divisions-wrapper {
  position: relative;
  z-index: 1;
  font-size: 0;
  overflow: hidden;
  white-space: nowrap;
  background: #fbfeff;
  -webkit-transition: height 0.3s ease-out 0s;
  -moz-transition: height 0.3s ease-out 0s;
  -ms-transition: height 0.3s ease-out 0s;
  -o-transition: height 0.3s ease-out 0s;
  transition: height 0.3s ease-out 0s;
}
form.division-form .divisions-wrapper .division-wrapper {
  display: inline-block;
  width: 100%;
  padding: 20px 15px 15px 15px;
  white-space: normal;
  border: 1px solid #aac3cd;
  border-top: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
form.division-form .divisions-wrapper .division-wrapper.active {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
form.division-form .division-controls .mixin-btn {
  float: left;
  margin-top: 0 !important;
}
form.division-form .division-controls .mixin-btn .btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
form .select2.select2-container .select2-selection.select2-selection--default,
form .select2.select2-container .select2-selection.select2-selection--single,
form .select2.select2-container .select2-selection.select2-selection--multiple,
form .select2.select2-container .select2-selection.select2-selection--default:focus,
form .select2.select2-container .select2-selection.select2-selection--single:focus,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus,
form .select2.select2-container--open .select2-selection.select2-selection--default,
form .select2.select2-container--open .select2-selection.select2-selection--single,
form .select2.select2-container--open .select2-selection.select2-selection--multiple,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus {
  border-color: #aac3cd;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered {
  width: 100%;
  min-height: 34px;
  color: #628290;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice {
  display: inline-block;
  line-height: 24px;
  color: #8da3ab;
  background: #f3f6f7;
  border-color: #b6c1c4;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice.ui-sortable-handle:after {
  position: relative;
  top: 1px;
  font-size: 17px;
  line-height: 18px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__placeholder {
  color: #aac3cd;
  font-size: 16px;
  line-height: 28px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-search--inline:first-of-type,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search--inline:first-of-type {
  width: 100%;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-search--inline:first-of-type input,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-search--inline:first-of-type input {
  width: 100% !important;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__arrow,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow {
  background: #fbfeff;
  border-color: #aac3cd;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__arrow b,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__arrow b {
  border-top-color: #aac3cd;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__clear,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__clear,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__clear {
  position: relative;
  color: #aac3cd;
  font-size: 18px;
  line-height: 29px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__clear:after,
form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__clear:after,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__clear:after,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__clear:after,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__clear:after,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__clear:after,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__clear:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  height: 60px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field {
  position: relative;
  top: -1px;
  font-size: 16px;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field::-webkit-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field::-webkit-input-placeholder {
  color: #aac3cd;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field::-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field::-moz-placeholder {
  color: #aac3cd;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field:-ms-input-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field:-ms-input-placeholder {
  color: #aac3cd;
}
form .select2.select2-container .select2-selection.select2-selection--default .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-search__field:-moz-placeholder,
form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-search__field:-moz-placeholder {
  color: #aac3cd;
}
form .select2.select2-container.error-flag .select2-selection.select2-selection--default,
form .select2.select2-container.error-flag .select2-selection.select2-selection--single,
form .select2.select2-container.error-flag .select2-selection.select2-selection--multiple,
form .select2.select2-container.error-flag .select2-selection.select2-selection--default:focus,
form .select2.select2-container.error-flag .select2-selection.select2-selection--single:focus,
form .select2.select2-container.error-flag .select2-selection.select2-selection--multiple:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--default,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--single,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--multiple,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--default:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--single:focus,
form .select2.select2-container--open.error-flag .select2-selection.select2-selection--multiple:focus {
  border-color: #e2430f !important;
}

.select2-container.select2-container--classic .select2-dropdown, .select2-container.select2-container--open .select2-dropdown {
  border-color: #aac3cd;
}
.select2-container.select2-container--classic .select2-dropdown .select2-search__field, .select2-container.select2-container--open .select2-dropdown .select2-search__field {
  border-color: #aac3cd;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option, .select2-container.select2-container--open .select2-dropdown .select2-results__option {
  color: #aac3cd;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option .select2-results__group, .select2-container.select2-container--open .select2-dropdown .select2-results__option .select2-results__group {
  font-size: 16px;
  font-weight: 500;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[role=group], .select2-container.select2-container--open .select2-dropdown .select2-results__option[role=group] {
  border-top: 1px solid rgba(3, 127, 170, 0.05);
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[aria-selected=true], .select2-container.select2-container--open .select2-dropdown .select2-results__option[aria-selected=true] {
  color: #fbfeff;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option[aria-selected=true]:before, .select2-container.select2-container--open .select2-dropdown .select2-results__option[aria-selected=true]:before {
  background: #037faa;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option.select2-results__option--highlighted, .select2-container.select2-container--open .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  color: #fbfeff;
}
.select2-container.select2-container--classic .select2-dropdown .select2-results__option.select2-results__option--highlighted:before, .select2-container.select2-container--open .select2-dropdown .select2-results__option.select2-results__option--highlighted:before {
  background: #005e7d;
}
.select2-container.select2-container--classic .select2-dropdown.select2-dropdown--above, .select2-container.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-color: #aac3cd;
}

@media screen and (max-width: 1200px) {
  .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3 {
    width: 100%;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
  .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3 .btn {
    border: 1px solid #aac3cd;
    border-bottom: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
  .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3 .btn.active {
    border: 1px solid #037faa;
  }
  .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3 .btn:before, .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3 .btn:after {
    content: none;
  }
  .clm-wrapper .clm.clm-2 form.division-form .division-header .mixin-btn.clm-3:first-child .btn {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
}
@media screen and (max-width: 1000px) {
  form.division-form .division-header .mixin-btn.clm-5 {
    width: 100%;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
  form.division-form .division-header .mixin-btn.clm-5 .btn {
    border: 1px solid #aac3cd;
    border-bottom: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
  form.division-form .division-header .mixin-btn.clm-5 .btn.active {
    border: 1px solid #037faa;
  }
  form.division-form .division-header .mixin-btn.clm-5 .btn:before, form.division-form .division-header .mixin-btn.clm-5 .btn:after {
    content: none;
  }
  form.division-form .division-header .mixin-btn.clm-5:first-child .btn {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  form .row.row-border {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  form.division-form .division-header .mixin-btn.clm-3 {
    width: 100%;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
  form.division-form .division-header .mixin-btn.clm-3 .btn {
    border: 1px solid #aac3cd;
    border-bottom: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
  form.division-form .division-header .mixin-btn.clm-3 .btn.active {
    border: 1px solid #037faa;
  }
  form.division-form .division-header .mixin-btn.clm-3 .btn:before, form.division-form .division-header .mixin-btn.clm-3 .btn:after {
    content: none;
  }
  form.division-form .division-header .mixin-btn.clm-3:first-child .btn {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
}
@media screen and (max-width: 500px) {
  form.division-form .division-header .mixin-btn.clm-2 {
    width: 100%;
    margin-bottom: 2px !important;
    padding: 0 !important;
  }
  form.division-form .division-header .mixin-btn.clm-2 .btn {
    border: 1px solid #aac3cd;
    border-bottom: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
  form.division-form .division-header .mixin-btn.clm-2 .btn.active {
    border: 1px solid #037faa;
  }
  form.division-form .division-header .mixin-btn.clm-2 .btn:before, form.division-form .division-header .mixin-btn.clm-2 .btn:after {
    content: none;
  }
  form.division-form .division-header .mixin-btn.clm-2:first-child .btn {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  form .row > a,
  form .row .link-style {
    font-weight: 600;
  }
  form input[type=text],
  form input[type=number],
  form input[type=email],
  form input[type=password],
  form input[type=date],
  form input[type=time],
  form input[type=datetime-local],
  form textarea,
  form .input-checkbox,
  form .input-radio,
  form .input-switcher,
  form .type-text-style,
  form .type-textarea-style {
    font-weight: 400;
  }
  form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered,
  form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered,
  form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered,
  form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered,
  form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered,
  form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered {
    font-weight: 400;
  }
  form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    font-weight: 400;
  }
  form .select2.select2-container .select2-selection.select2-selection--default .select2-selection__clear,
  form .select2.select2-container .select2-selection.select2-selection--single .select2-selection__clear,
  form .select2.select2-container .select2-selection.select2-selection--multiple .select2-selection__clear,
  form .select2.select2-container .select2-selection.select2-selection--default:focus .select2-selection__clear,
  form .select2.select2-container .select2-selection.select2-selection--single:focus .select2-selection__clear,
  form .select2.select2-container .select2-selection.select2-selection--multiple:focus .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--default .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--single .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--default:focus .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--single:focus .select2-selection__clear,
  form .select2.select2-container--open .select2-selection.select2-selection--multiple:focus .select2-selection__clear {
    font-weight: 500;
  }
}
@media screen and (max-width: 400px) {
  form.division-form .divisions-wrapper .division-wrapper {
    padding: 10px;
  }
  form.division-form .division-controls .mixin-btn {
    float: none;
  }
}
.mixin-form-code {
  position: relative;
}
.mixin-form-code input[type=text],
.mixin-form-code input[type=password] {
  color: rgba(0, 0, 0, 0);
  caret-color: #999c9e;
  font-variant: tabular-nums;
}
.mixin-form-code .code {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 34px;
  color: #628290;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 34px;
  overflow: hidden;
  pointer-events: none;
}
.mixin-form-code[data-length="3"] input[type=text],
.mixin-form-code[data-length="3"] input[type=password] {
  padding-left: calc(16.6666666667% - 0.6ch);
  padding-right: calc(16.6666666667% - 0.5ch);
  background: repeating-linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 33.3333333333%, #aac3cd 1px, #aac3cd calc(33.3333333333% + 1px));
}
.mixin-form-code[data-length="3"] .code {
  padding-left: calc(16.6666666667% - 0.5ch);
  padding-right: calc(16.6666666667% - 0.5ch);
}
.mixin-form-code[data-length="4"] input[type=text],
.mixin-form-code[data-length="4"] input[type=password] {
  padding-left: calc(12.5% - 0.6ch);
  padding-right: calc(12.5% - 0.5ch);
  background: repeating-linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 25%, #aac3cd 1px, #aac3cd calc(25% + 1px));
}
.mixin-form-code[data-length="4"] .code {
  padding-left: calc(12.5% - 0.5ch);
  padding-right: calc(12.5% - 0.5ch);
}
.mixin-form-code[data-length="6"] input[type=text],
.mixin-form-code[data-length="6"] input[type=password] {
  padding-left: calc(8.3333333333% - 0.6ch);
  padding-right: calc(8.3333333333% - 0.5ch);
  background: repeating-linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 16.6666666667%, #aac3cd 1px, #aac3cd calc(16.6666666667% + 1px));
}
.mixin-form-code[data-length="6"] .code {
  padding-left: calc(8.3333333333% - 0.5ch);
  padding-right: calc(8.3333333333% - 0.5ch);
}
.mixin-form-code.type-password input[type=password] {
  font-family: monospace;
}
.mixin-form-code.type-password .code {
  font-family: monospace;
}

.mixin-form-copy-string {
  display: inline-block;
  margin-top: 3px;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mixin-form-field-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 27px;
  text-align: center;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.mixin-form-field-info .icon {
  top: 4px;
  color: #fbfeff;
  font-size: 17px;
}
.mixin-form-field-info:before, .mixin-form-field-info:after {
  content: "";
  position: absolute;
}
.mixin-form-field-info:before {
  bottom: -1px;
  left: -10px;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  -ms-border-radius: 0 0 3px 0;
  -o-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
  opacity: 0.75;
  filter: alpha(opacity=75);
  -ms-filter: alpha(opacity=75);
}
.mixin-form-field-info:after {
  bottom: -3px;
  right: 0;
  width: 2px;
  height: 2px;
}
.mixin-form-field-info.not-verified {
  background: #a56cbb;
}
.mixin-form-field-info.not-verified:before {
  border-bottom: 1px solid #a56cbb;
  border-right: 1px solid #a56cbb;
}
.mixin-form-field-info.not-verified:after {
  border-top: 1px solid #a56cbb;
  border-right: 1px solid #a56cbb;
}
.mixin-form-field-info.not-valid {
  background: #e2430f;
}
.mixin-form-field-info.not-valid:before {
  border-bottom: 1px solid #e2430f;
  border-right: 1px solid #e2430f;
}
.mixin-form-field-info.not-valid:after {
  border-top: 1px solid #e2430f;
  border-right: 1px solid #e2430f;
}

.mixin-form-header {
  display: flex;
  margin-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mixin-form-header h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3em;
}
.mixin-form-header span {
  display: inline-block;
  margin-left: 8px;
  color: #aac3cd;
  font-size: 12px;
  vertical-align: baseline;
}
.mixin-form-header p {
  width: 100%;
  margin: 10px 0;
  color: #8da3ab;
  font-size: 16px;
}

.mixin-form-identity-document > .clm-wrapper > .clm:first-child {
  position: relative;
}
.mixin-form-identity-document > .clm-wrapper input[type=file] {
  display: none;
}
.mixin-form-identity-document > .clm-wrapper .form-box.type-text-style .icon {
  float: right;
  top: 3px;
  right: 7px;
}
.mixin-form-identity-document > .clm-wrapper .form-box.type-text-style .icon.icon-checkmark {
  top: 5px;
  color: #03aa9e;
}
.mixin-form-identity-document > .clm-wrapper .mixin-btn {
  margin: 0 !important;
  padding-top: 0 !important;
}
.mixin-form-identity-document > .clm-wrapper .mixin-btn .btn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 8px;
}
.mixin-form-identity-document > .clm-wrapper .mixin-form-field-info {
  right: 10px;
}
.mixin-form-identity-document > .clm-wrapper .file-info {
  margin-top: 10px;
  padding: 0 3px;
}
.mixin-form-identity-document > .clm-wrapper .file-info .name {
  display: inline-block;
  max-width: 100%;
  font-size: 14px;
  line-break: anywhere;
  overflow: hidden;
}
.mixin-form-identity-document > .clm-wrapper .file-info .icon {
  float: right;
  padding: 2px 0 0 10px;
  cursor: pointer;
}
.mixin-form-identity-document > .clm-wrapper .clm-wrapper {
  display: flex;
  -webkit-gap: 10px 20px;
  -moz-gap: 10px 20px;
  -ms-gap: 10px 20px;
  -o-gap: 10px 20px;
  gap: 10px 20px;
}
.mixin-form-identity-document > .clm-wrapper .clm-wrapper .clm {
  width: auto !important;
  padding: 0 !important;
  -webkit-flex: 1 1 calc(50% - 20px);
  -moz-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  -o-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
}

@media screen and (max-width: 700px) {
  .mixin-form-identity-document > .clm-wrapper .clm-wrapper .clm {
    width: 100% !important;
    margin-bottom: 0;
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.mixin-form-label {
  display: flex;
  margin-bottom: 3px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 8px;
  -moz-gap: 0 8px;
  -ms-gap: 0 8px;
  -o-gap: 0 8px;
  gap: 0 8px;
}
.mixin-form-label .text {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.mixin-form-label .text:first-letter {
  text-transform: capitalize;
}
.mixin-form-label .info {
  position: relative;
  top: 3px;
  display: inline-block;
  padding-bottom: 3px;
  color: #aac3cd;
  font-size: 12px;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-form-label.line-break .info {
  display: block;
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  -o-flex: 1 1 100%;
  flex: 1 1 100%;
}
.mixin-form-label.line-break:after {
  content: none;
}

@media screen and (max-width: 500px) {
  .mixin-form-label .text {
    font-weight: 700;
  }
  .mixin-form-label .info {
    font-weight: 500;
  }
}
.mixin-form-number-pad {
  display: flex;
  flex-flow: row wrap;
  font-size: 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -ms-gap: 10px;
  -o-gap: 10px;
  gap: 10px;
}
.mixin-form-number-pad span {
  line-height: 50px;
  -webkit-flex: 0 0 calc(33.333% - 6.6666666667px);
  -moz-flex: 0 0 calc(33.333% - 6.6666666667px);
  -ms-flex: 0 0 calc(33.333% - 6.6666666667px);
  -o-flex: 0 0 calc(33.333% - 6.6666666667px);
  flex: 0 0 calc(33.333% - 6.6666666667px);
}
.mixin-form-number-pad span.number {
  color: #fbfeff;
  font-weight: 600;
  background: #037faa;
  text-align: center;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-form-number-pad span.delete {
  text-align: center;
}
.mixin-form-number-pad span.delete .icon {
  color: #037faa;
  font-size: 32px;
}

.mixin-form-quantity-selector {
  position: relative;
  text-align: center;
}
.mixin-form-quantity-selector .wrapper {
  display: inline-block;
  background: #fbfeff;
  border: 1px solid #aac3cd;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-form-quantity-selector .wrapper .control {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.mixin-form-quantity-selector .wrapper .control.minus {
  border-right: 1px solid #aac3cd;
}
.mixin-form-quantity-selector .wrapper .control.plus {
  border-left: 1px solid #aac3cd;
}
.mixin-form-quantity-selector .wrapper .control.plus:after {
  content: "";
  width: 1px;
  height: 10px;
}
.mixin-form-quantity-selector .wrapper .control:before, .mixin-form-quantity-selector .wrapper .control:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  background: #8da3ab;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mixin-form-quantity-selector .wrapper .control:before {
  content: "";
}
.mixin-form-quantity-selector .wrapper .value {
  display: inline-block;
  width: 30px;
  padding-top: 3px;
  color: #8da3ab;
  font-size: 16px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mixin-form-two-factor-authentication .resend-code {
  float: right !important;
  padding: 10px 5px 0 15px !important;
}

.mixin-form-uploadbox .dropzone {
  display: block;
  min-height: 100px;
  border: 2px dashed #099bda;
}
.mixin-form-uploadbox .dropzone input {
  display: none;
}
.mixin-form-uploadbox .dropzone .dz-message {
  padding: 30px;
  min-height: 100px;
  text-align: center;
}
.mixin-form-uploadbox .dropzone .dz-message button {
  color: #03aa9e;
  font-size: 22px;
  font-weight: 500;
}
.mixin-form-uploadbox .dropzone .dz-message .file-info {
  padding-top: 20px;
}
.mixin-form-uploadbox .dropzone .dz-message .file-info span {
  color: #999c9e;
  font-size: 16px;
  line-height: 30px;
}
.mixin-form-uploadbox .dropzone .dz-preview {
  clear: both;
  position: relative;
  margin: 0 20px;
  font-size: 0;
  border-top: 1px solid #aac3cd;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-image {
  float: left;
  display: inline-block;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-image img[src] {
  width: 120px;
  margin: 10px 20px 10px 0;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-image img[src].img-fallback {
  display: none;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-details {
  display: block;
  padding: 10px 20px 10px 0;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-details .dz-size {
  font-size: 0;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-details .dz-size span {
  font-size: 14px;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-details .dz-size strong {
  font-size: 14px;
  font-weight: 700;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-details .dz-filename span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-error-message {
  display: none;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-error-message span {
  display: block;
  padding-bottom: 10px;
  color: #e2430f;
  font-size: 16px;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-success-mark,
.mixin-form-uploadbox .dropzone .dz-preview .dz-error-mark {
  display: none;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-remove {
  position: absolute;
  bottom: 7px;
  right: 0;
  color: #fff;
}
.mixin-form-uploadbox .dropzone .dz-preview .dz-remove span {
  text-indent: 0;
}
.mixin-form-uploadbox .dropzone .dz-preview.dz-image-preview:after {
  content: "";
  clear: both;
  display: block;
}
.mixin-form-uploadbox .dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.mixin-form-uploadbox .dropzone:after {
  content: "";
  clear: both;
  display: block;
}
.mixin-form-uploadbox .select-file {
  float: left;
}
.mixin-form-uploadbox .mixin-flash.max-files-reached {
  display: none;
}
.mixin-form-uploadbox .mixin-flash.max-files-reached.show {
  display: block;
}

/* == PROJECT / APP / MIXINS / GLOBALS ====================================================== */
.mixin-article {
  position: relative;
}
.mixin-article p {
  padding-bottom: 15px;
  letter-spacing: 0.2px;
}
.mixin-article p em,
.mixin-article p span {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}
.mixin-article p em {
  font-weight: 500;
}
.mixin-article p span {
  font-size: 16px;
}
.mixin-article p span.link-style {
  display: inline;
  padding: 0;
  color: #42bbef;
}
.mixin-article p span.link-style:hover:not(.no-css-hover) {
  text-decoration: underline;
}
.mixin-article p:last-of-type {
  padding-bottom: 0;
}
.mixin-article .mixin-article-header {
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
}
.mixin-article .mixin-article-header h1,
.mixin-article .mixin-article-header h2,
.mixin-article .mixin-article-header h3,
.mixin-article .mixin-article-header h4 {
  display: inline-block;
  hyphens: auto;
}
.mixin-article .mixin-article-header h1[data-trigger-href]:hover:not(.no-css-hover),
.mixin-article .mixin-article-header h2[data-trigger-href]:hover:not(.no-css-hover),
.mixin-article .mixin-article-header h3[data-trigger-href]:hover:not(.no-css-hover),
.mixin-article .mixin-article-header h4[data-trigger-href]:hover:not(.no-css-hover) {
  text-decoration: underline;
}
.mixin-article .mixin-article-header .wrapper-icon {
  position: relative;
  height: 90px;
  margin-bottom: 40px;
  padding: 20px 0;
  text-align: center;
}
.mixin-article .mixin-article-header .wrapper-icon .icon {
  color: #fbfeff;
  font-size: 40px;
}
.mixin-article .mixin-article-header .wrapper-icon .icon.icon-cutlery {
  top: 5px;
  left: 2px;
}
.mixin-article .mixin-article-header .wrapper-icon .icon.icon-order {
  top: 8px;
}
.mixin-article .mixin-article-header .wrapper-icon .icon.icon-settings {
  font-size: 50px;
}
.mixin-article .mixin-article-header .wrapper-icon .mixin-image-lazy {
  position: relative;
  top: 5px;
  z-index: 1;
  height: 40px;
}
.mixin-article .mixin-article-header .wrapper-icon:before, .mixin-article .mixin-article-header .wrapper-icon:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-header .wrapper-icon:before {
  top: 0;
  height: 90px;
  width: 90px;
  background: #42bbef;
}
.mixin-article .mixin-article-header .wrapper-icon:after {
  top: 7px;
  height: 70px;
  width: 70px;
  border: 3px solid #fbfeff;
  background: #037faa;
}
.mixin-article .mixin-article-header .wrapper-icon:has(.mixin-image-lazy):after {
  background: #fbfeff;
}
.mixin-article .mixin-article-header .preline-wrapper {
  display: flex;
  position: relative;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -ms-gap: 10px;
  -o-gap: 10px;
  gap: 10px;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item {
  position: relative;
  padding-bottom: 15px;
  max-width: 100%;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item > a,
.mixin-article .mixin-article-header .preline-wrapper .preline-item > span {
  display: inline-block;
  padding: 5px 15px;
  max-width: 100%;
  color: #fbfeff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #005e7d;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
  font-family: "Exo 2", sans-serif;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item > a:hover:not(.no-css-hover),
.mixin-article .mixin-article-header .preline-wrapper .preline-item > span:hover:not(.no-css-hover) {
  text-decoration: none;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .mixin-dots {
  left: -10px;
  width: 32px;
  height: 100%;
  background: #005e7d;
  -webkit-border-radius: 0 100px 100px 0;
  -moz-border-radius: 0 100px 100px 0;
  -ms-border-radius: 0 100px 100px 0;
  -o-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .mixin-dots .dot {
  background: #fbfeff;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 20px;
  background: #fbfeff;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transform-origin: 300px 0;
  -moz-transform-origin: 300px 0;
  -ms-transform-origin: 300px 0;
  -o-transform-origin: 300px 0;
  transform-origin: 300px 0;
  -webkit-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -moz-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -ms-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -o-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item {
  display: block;
  font-size: 0;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a {
  display: block;
  position: relative;
  padding: 5px 0;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a span {
  color: #005e7d;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a:before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -20px;
  width: 5px;
  background: #03aa9e;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a:after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -20px;
  right: -20px;
  background: rgba(3, 127, 170, 0.05);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a.active:before, .mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a.active:after, .mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a:hover:not(.no-css-hover):before, .mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item a:hover:not(.no-css-hover):after {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:first-child a {
  padding-top: 10px;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:first-child a:before {
  top: 0;
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  -ms-border-radius: 3px 0 0 0;
  -o-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:first-child a:after {
  top: 0;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:first-child a.active span:before, .mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:first-child a:hover:not(.no-css-hover) span:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(3, 127, 170, 0.05);
  -webkit-transform: translate(50%, 0);
  -moz-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:last-child {
  border: none;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:last-child a:before {
  bottom: 0;
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  -ms-border-radius: 0 0 0 3px;
  -o-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper .dropdown-item:last-child a:after {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item .dropdown-wrapper:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #fbfeff;
  -webkit-transform: translate(50%, 0);
  -moz-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item.with-dropdown {
  max-width: calc(100% - 22px);
  white-space: nowrap;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item.with-dropdown > a,
.mixin-article .mixin-article-header .preline-wrapper .preline-item.with-dropdown > span {
  -webkit-border-radius: 100px 0 0 100px;
  -moz-border-radius: 100px 0 0 100px;
  -ms-border-radius: 100px 0 0 100px;
  -o-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;
}
.mixin-article .mixin-article-header .preline-wrapper .preline-item.show-dropdown .dropdown-wrapper {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -moz-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -ms-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -o-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  transform: perspective(300px) translate(0, 0) rotateX(0deg);
}
.mixin-article .mixin-article-header .subline {
  padding: 15px 0;
  font-weight: 500;
  line-height: 1.5em;
}
.mixin-article .mixin-article-header.preline-right .preline-wrapper {
  float: right;
  margin: 0;
}
.mixin-article .mixin-article-header.border-bottom {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
}
.mixin-article .mixin-article-header.margin-bottom-huge {
  margin-bottom: 50px;
}
.mixin-article .mixin-article-navigation {
  position: absolute !important;
  top: 3px;
  right: 0;
  z-index: 5;
  font-size: 0;
}
.mixin-article .mixin-article-navigation a,
.mixin-article .mixin-article-navigation > span {
  cursor: pointer;
}
.mixin-article .mixin-article-navigation + .mixin-article-header h1,
.mixin-article .mixin-article-navigation + .mixin-article-header h2,
.mixin-article .mixin-article-navigation + .mixin-article-header h3,
.mixin-article .mixin-article-navigation + .mixin-article-header h4 {
  padding-right: 35px;
}
.mixin-article .mixin-article-list {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 10px;
  padding: 10px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 20px;
  -moz-gap: 0 20px;
  -ms-gap: 0 20px;
  -o-gap: 0 20px;
  gap: 0 20px;
}
.mixin-article .mixin-article-list > li {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  color: #03aa9e;
  font-size: 18px;
  font-weight: 500;
  align-content: baseline;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 1 0 100%;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  -o-flex: 1 0 100%;
  flex: 1 0 100%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mixin-article .mixin-article-list > li a,
.mixin-article .mixin-article-list > li [data-overlay-url] {
  text-decoration: underline;
}
.mixin-article .mixin-article-list > li em {
  font-weight: 500 !important;
}
.mixin-article .mixin-article-list > li > .headline {
  display: flex;
  color: inherit !important;
  font: inherit !important;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  -o-flex: 1 1 100%;
  flex: 1 1 100%;
}
.mixin-article .mixin-article-list > li > .headline h4,
.mixin-article .mixin-article-list > li > .headline a,
.mixin-article .mixin-article-list > li > .headline em {
  display: inline-block !important;
  margin: 3px 0 0 0 !important;
  color: inherit !important;
  font: inherit !important;
  line-height: 1.3em !important;
  max-width: 100%;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
.mixin-article .mixin-article-list > li > .headline h4 > .icon,
.mixin-article .mixin-article-list > li > .headline a > .icon,
.mixin-article .mixin-article-list > li > .headline em > .icon {
  position: absolute;
  top: 0;
  right: 0;
}
.mixin-article .mixin-article-list > li > .headline h4:hover:not(.no-css-hover) > .icon,
.mixin-article .mixin-article-list > li > .headline a:hover:not(.no-css-hover) > .icon,
.mixin-article .mixin-article-list > li > .headline em:hover:not(.no-css-hover) > .icon {
  color: #03aa9e;
  border-color: #03aa9e;
}
.mixin-article .mixin-article-list > li > .headline > .icon,
.mixin-article .mixin-article-list > li > .headline > .number {
  margin-right: 10px;
  color: #03aa9e;
  font-size: 14px;
  line-height: 28px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.mixin-article .mixin-article-list > li > .headline > .icon.icon-checkmark,
.mixin-article .mixin-article-list > li > .headline > .number.icon-checkmark {
  top: 1px;
  width: 22px;
  font-size: 13px;
}
.mixin-article .mixin-article-list > li > .headline > .icon.icon-checkmark:after,
.mixin-article .mixin-article-list > li > .headline > .number.icon-checkmark:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
  width: 22px;
  height: 22px;
  background: #a2ecb7;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-list > li > .headline > .icon.icon-x, .mixin-article .mixin-article-list > li > .headline > .icon.icon-warning,
.mixin-article .mixin-article-list > li > .headline > .number.icon-x,
.mixin-article .mixin-article-list > li > .headline > .number.icon-warning {
  top: -1px;
  left: 1px;
  font-size: 22px;
  color: #628290;
}
.mixin-article .mixin-article-list > li > .headline > .icon.number,
.mixin-article .mixin-article-list > li > .headline > .number.number {
  display: inline-block;
  position: relative;
  top: 1px;
  width: 22px;
  height: 22px;
  color: #fbfeff;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  font-family: "Museo Slab", sans-serif;
}
.mixin-article .mixin-article-list > li > .headline > .icon.number:after,
.mixin-article .mixin-article-list > li > .headline > .number.number:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
  width: 22px;
  height: 22px;
  background: #a56cbb;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-list > li > .content {
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  -o-flex: 1 1 100%;
  flex: 1 1 100%;
}
.mixin-article .mixin-article-list > li > .content p {
  color: #8da3ab;
  font-size: 16px;
  line-height: 1.4;
  overflow-wrap: break-word;
}
.mixin-article .mixin-article-list > li > .content p a {
  max-width: 100%;
  overflow-wrap: break-word;
}
.mixin-article .mixin-article-list > li > .content p a:hover:not(.no-css-hover) {
  text-decoration: underline;
}
.mixin-article .mixin-article-list > li > .content p span {
  max-width: 100%;
  color: inherit;
  font: inherit;
  link-height: inherit;
  overflow-wrap: break-word;
}
.mixin-article .mixin-article-list > li > .content p span[data-overlay-url], .mixin-article .mixin-article-list > li > .content p span[data-overlay-src], .mixin-article .mixin-article-list > li > .content p span[data-overlay-data], .mixin-article .mixin-article-list > li > .content p span[data-overlay-content] {
  text-decoration: underline;
}
.mixin-article .mixin-article-list > li > .content ul {
  margin-top: 5px;
}
.mixin-article .mixin-article-list > li > .content ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 50px;
  color: #8da3ab;
  font-size: 16px;
}
.mixin-article .mixin-article-list > li > .content ul li:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 35px;
  width: 6px;
  height: 6px;
  background: #628290;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-list > li > .content ul li:first-child:last-child {
  padding-left: 32px;
}
.mixin-article .mixin-article-list > li > .content ul li:first-child:last-child:before {
  content: none;
}
.mixin-article .mixin-article-list > li > .content ul li:last-child {
  margin-bottom: 0;
}
.mixin-article .mixin-article-list > li .wrapper-icon-tooltip {
  position: absolute !important;
  z-index: 1;
  top: 0;
  left: 0;
  width: 22px;
  height: 30px;
}
.mixin-article .mixin-article-list > li.color-grey-dark {
  color: #628290;
}
.mixin-article .mixin-article-list > li.color-purple-middle {
  color: #a56cbb;
}
.mixin-article .mixin-article-list > li.has-icon p, .mixin-article .mixin-article-list > li.has-number p {
  padding-left: 34px;
}
.mixin-article .mixin-article-list > li.has-icon.has-number, .mixin-article .mixin-article-list > li.has-number.has-number {
  color: #a56cbb;
}
.mixin-article .mixin-article-list > li.has-icon.has-icon-x, .mixin-article .mixin-article-list > li.has-icon.has-icon-warning, .mixin-article .mixin-article-list > li.has-number.has-icon-x, .mixin-article .mixin-article-list > li.has-number.has-icon-warning {
  color: #628290;
}
.mixin-article .mixin-article-list > li.has-icon.has-icon-right p, .mixin-article .mixin-article-list > li.has-number.has-icon-right p {
  padding-right: 50px;
}
.mixin-article .mixin-article-list.clms-2 > li {
  -webkit-flex: 1 1 calc(50% - 20px);
  -moz-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  -o-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
}
.mixin-article .mixin-article-list.clms-2:after {
  -webkit-flex: 1 1 calc(50% - 20px);
  -moz-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  -o-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
}
.mixin-article .mixin-article-list.clms-3 > li {
  -webkit-flex: 1 1 calc(33.333333% - 20px);
  -moz-flex: 1 1 calc(33.333333% - 20px);
  -ms-flex: 1 1 calc(33.333333% - 20px);
  -o-flex: 1 1 calc(33.333333% - 20px);
  flex: 1 1 calc(33.333333% - 20px);
}
.mixin-article .mixin-article-list.clms-3:after {
  -webkit-flex: 1 1 calc(33.333333% - 20px);
  -moz-flex: 1 1 calc(33.333333% - 20px);
  -ms-flex: 1 1 calc(33.333333% - 20px);
  -o-flex: 1 1 calc(33.333333% - 20px);
  flex: 1 1 calc(33.333333% - 20px);
}
.mixin-article .mixin-article-list:hover:not(.no-css-hover), .mixin-article .mixin-article-list.touch-active {
  z-index: 2;
}
.mixin-article .mixin-article-process {
  display: flex;
  position: relative;
  z-index: 1;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
.mixin-article .mixin-article-process li {
  padding: 7px;
  max-width: 100%;
  -webkit-flex: 1 0 100%;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  -o-flex: 1 0 100%;
  flex: 1 0 100%;
}
.mixin-article .mixin-article-process li .header {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #42bbef;
  border-left: none;
  border-bottom: none;
  -webkit-border-radius: 0 15px 0 0;
  -moz-border-radius: 0 15px 0 0;
  -ms-border-radius: 0 15px 0 0;
  -o-border-radius: 0 15px 0 0;
  border-radius: 0 15px 0 0;
}
.mixin-article .mixin-article-process li .header .number {
  position: relative;
  z-index: 1;
  margin: 3px 0 -12px 0;
  width: 73px;
  height: 73px;
  line-height: 76px;
  color: #42bbef;
  font-size: 30px;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Museo Slab", sans-serif;
}
.mixin-article .mixin-article-process li .header .number:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  z-index: -1;
  background: #fbfeff;
  -webkit-border-radius: 13px 13px 13px 13px;
  -moz-border-radius: 13px 13px 13px 13px;
  -ms-border-radius: 13px 13px 13px 13px;
  -o-border-radius: 13px 13px 13px 13px;
  border-radius: 13px 13px 13px 13px;
  -webkit-box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.2);
}
.mixin-article .mixin-article-process li .header .headline {
  display: flex;
  position: relative;
  margin: 3px 0 -12px 0;
  padding: 20px 25px 20px 15px;
  max-width: calc(100% - 73px);
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-article .mixin-article-process li .header .headline .text {
  color: #fbfeff;
  font-size: 22px;
  font-weight: 400;
  white-space: nowrap;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-article .mixin-article-process li .header .headline .icon {
  top: 7px;
  margin-left: 10px;
  color: #fbfeff;
  font-size: 22px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.mixin-article .mixin-article-process li .header .headline:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: -15px;
  right: 7px;
  z-index: -1;
  background: #42bbef;
  -webkit-border-radius: 0 13px 13px 0;
  -moz-border-radius: 0 13px 13px 0;
  -ms-border-radius: 0 13px 13px 0;
  -o-border-radius: 0 13px 13px 0;
  border-radius: 0 13px 13px 0;
}
.mixin-article .mixin-article-process li .header:before {
  content: "";
  position: absolute;
  top: -4px;
  left: -7px;
  width: 5px;
  height: 5px;
  border: 1px solid #42bbef;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-process li .header:after {
  content: "";
  position: absolute;
  bottom: -16px;
  right: -1px;
  width: 15px;
  height: 15px;
  border: 1px solid #42bbef;
  border-top: none;
  border-left: none;
  -webkit-border-radius: 0 0 15px 0;
  -moz-border-radius: 0 0 15px 0;
  -ms-border-radius: 0 0 15px 0;
  -o-border-radius: 0 0 15px 0;
  border-radius: 0 0 15px 0;
}
.mixin-article .mixin-article-process li .info {
  position: relative;
  margin-right: 15px;
  padding: 20px 5px 20px 20px;
  border: 1px solid #42bbef;
  border-right: none;
  -webkit-border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -ms-border-radius: 15px 0 0 15px;
  -o-border-radius: 15px 0 0 15px;
  border-radius: 15px 0 0 15px;
}
.mixin-article .mixin-article-process li .info p {
  font-size: 16px;
  font-weight: 400;
}
.mixin-article .mixin-article-process li .info:before {
  content: "";
  position: absolute;
  bottom: 8px;
  right: -19px;
  width: 7px;
  height: 7px;
  background: #42bbef;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-process li .info:after {
  content: "";
  position: absolute;
  bottom: -1px;
  right: -16px;
  width: 15px;
  height: 15px;
  border: 1px solid #42bbef;
  border-top: none;
  border-left: none;
  -webkit-border-radius: 0 0 15px 0;
  -moz-border-radius: 0 0 15px 0;
  -ms-border-radius: 0 0 15px 0;
  -o-border-radius: 0 0 15px 0;
  border-radius: 0 0 15px 0;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .header {
  border-color: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .header .number {
  color: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .header .headline:after {
  background: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .header:before, .mixin-article .mixin-article-process li.color-blue-logo-2 .header:after {
  border-color: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .info {
  border-color: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .info:before {
  background: #037faa;
}
.mixin-article .mixin-article-process li.color-blue-logo-2 .info:after {
  border-color: #037faa;
}
.mixin-article .mixin-article-process li.color-green-middle .header {
  border-color: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .header .number {
  color: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .header .headline:after {
  background: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .header:before, .mixin-article .mixin-article-process li.color-green-middle .header:after {
  border-color: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .info {
  border-color: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .info:before {
  background: #03aa9e;
}
.mixin-article .mixin-article-process li.color-green-middle .info:after {
  border-color: #03aa9e;
}
.mixin-article .mixin-article-process li.color-purple-middle .header {
  border-color: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .header .number {
  color: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .header .headline:after {
  background: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .header:before, .mixin-article .mixin-article-process li.color-purple-middle .header:after {
  border-color: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .info {
  border-color: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .info:before {
  background: #a56cbb;
}
.mixin-article .mixin-article-process li.color-purple-middle .info:after {
  border-color: #a56cbb;
}
.mixin-article .mixin-article-accordion {
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
.mixin-article .mixin-article-accordion > li {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .mixin-article-navigation .icon {
  border: none !important;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .mixin-article-header {
  margin-bottom: 0;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer {
  display: none;
  overflow: hidden;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner p {
  color: #8da3ab;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ul,
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ol {
  display: flex;
  flex-direction: column;
  -webkit-gap: 10px 0;
  -moz-gap: 10px 0;
  -ms-gap: 10px 0;
  -o-gap: 10px 0;
  gap: 10px 0;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ul li,
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ol li {
  padding-left: 20px;
  margin-left: 20px;
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  -o-flex: 1 1 100%;
  flex: 1 1 100%;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ul li::marker,
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ol li::marker {
  color: #037faa;
  font-family: "Museo Slab", sans-serif;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ul li {
  position: relative;
}
.mixin-article .mixin-article-accordion > li .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner ul li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: -18px;
  width: 8px;
  height: 8px;
  border: 2px solid #037faa;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-article .mixin-article-accordion > li.show .tile-wrapper .mixin-article-navigation .icon {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: perspective(0) rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: perspective(0) rotateZ(90deg);
  -o-transform: perspective(0) rotateZ(90deg);
  transform: perspective(0) rotateZ(90deg);
}
.mixin-article .mixin-article-accordion > li.show .tile-wrapper .folding-wrapper-outer .folding-wrapper-inner {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  -moz-transition: opacity 0.3s ease-out 0.2s;
  -ms-transition: opacity 0.3s ease-out 0.2s;
  -o-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s;
}

@media screen and (max-width: 1000px) {
  .mixin-article > .clm-wrapper.key-value,
  .mixin-article > .flex-wrapper.key-value {
    margin-bottom: 10px;
  }
  .mixin-article > .clm-wrapper.key-value:last-child,
  .mixin-article > .flex-wrapper.key-value:last-child {
    margin-bottom: 0;
  }
  .mixin-article .mixin-article-list.clms-3 > li {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-basis: 100%;
    -o-flex-basis: 100%;
    flex-basis: 100%;
  }
  .mixin-article .mixin-article-process {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .mixin-article .mixin-article-list.clms-3 > li {
    -webkit-flex: 1 1 calc(50% - 20px);
    -moz-flex: 1 1 calc(50% - 20px);
    -ms-flex: 1 1 calc(50% - 20px);
    -o-flex: 1 1 calc(50% - 20px);
    flex: 1 1 calc(50% - 20px);
  }
  .mixin-article .mixin-article-list.clms-3:after {
    -webkit-flex: 1 1 calc(50% - 20px);
    -moz-flex: 1 1 calc(50% - 20px);
    -ms-flex: 1 1 calc(50% - 20px);
    -o-flex: 1 1 calc(50% - 20px);
    flex: 1 1 calc(50% - 20px);
  }
}
@media screen and (max-width: 500px) {
  .mixin-article p {
    font-weight: 400;
  }
  .mixin-article p em {
    font-weight: 600;
  }
  .mixin-article .mixin-article-header .subline {
    font-weight: 600;
  }
  .mixin-article .mixin-article-list > li em {
    font-weight: 600 !important;
  }
  .mixin-article .mixin-article-list > li .headline {
    font-weight: 600 !important;
  }
  .mixin-article .mixin-article-list.clms-2 > li, .mixin-article .mixin-article-list.clms-3 > li {
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .mixin-article .mixin-article-list.clms-2:after, .mixin-article .mixin-article-list.clms-3:after {
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.mixin-background-lines {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.mixin-background-lines .center-wide-ctn {
  position: relative;
  height: 100%;
}
.mixin-background-lines .center-wide-ctn .background-lines-solid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mixin-background-lines .center-wide-ctn .background-lines-solid .left,
.mixin-background-lines .center-wide-ctn .background-lines-solid .center,
.mixin-background-lines .center-wide-ctn .background-lines-solid .right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: rgba(3, 127, 170, 0.1);
}
.mixin-background-lines .center-wide-ctn .background-lines-solid .left {
  left: -20px;
}
.mixin-background-lines .center-wide-ctn .background-lines-solid .center {
  left: 50%;
  width: 1px;
  background: rgba(3, 127, 170, 0.1);
}
.mixin-background-lines .center-wide-ctn .background-lines-solid .right {
  right: -20px;
}
.mixin-background-lines .center-wide-ctn .background-lines-dotted {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mixin-background-lines .center-wide-ctn .background-lines-dotted .left,
.mixin-background-lines .center-wide-ctn .background-lines-dotted .right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(0deg, rgba(3, 127, 170, 0.1), rgba(3, 127, 170, 0.1) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
  background-size: 1px 8px;
}
.mixin-background-lines .center-wide-ctn .background-lines-dotted .left {
  left: 25%;
}
.mixin-background-lines .center-wide-ctn .background-lines-dotted .right {
  right: 25%;
}

@media screen and (max-width: 500px) {
  .mixin-background-lines .center-wide-ctn .background-lines-solid .left {
    left: -10px;
  }
  .mixin-background-lines .center-wide-ctn .background-lines-solid .right {
    right: -10px;
  }
  .mixin-background-lines .center-wide-ctn .background-lines-dotted .left {
    -webkit-transform: translate(-5px, 0);
    -moz-transform: translate(-5px, 0);
    -ms-transform: translate(-5px, 0);
    -o-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  .mixin-background-lines .center-wide-ctn .background-lines-dotted .right {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
}
.mixin-btn {
  display: flex;
  clear: both;
  padding-top: 20px;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
.mixin-btn.btn-hover .btn.btn:after,
.mixin-btn .btn.btn-hover.btn:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  outline: 0 solid #037faa;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
  -webkit-transition: all 0s ease-out 0s;
  -moz-transition: all 0s ease-out 0s;
  -ms-transition: all 0s ease-out 0s;
  -o-transition: all 0s ease-out 0s;
  transition: all 0s ease-out 0s;
}
.mixin-btn.btn-hover .btn.btn:hover:not(.no-css-hover):after,
.mixin-btn .btn.btn-hover.btn:hover:not(.no-css-hover):after {
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  z-index: 1;
  outline-width: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mixin-btn .btn {
  position: relative;
  display: flex;
  padding: 10px 15px;
  max-width: 100%;
  height: 38px;
  color: #fbfeff;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #037faa;
  border: 1px solid #037faa;
  cursor: pointer;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -ms-gap: 15px;
  -o-gap: 15px;
  gap: 15px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.mixin-btn .btn .text {
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
  text-overflow: inherit;
  white-space: inherit;
  overflow: hidden;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
  font-family: "Exo 2", sans-serif;
}
.mixin-btn .btn .icon {
  margin: -1px 0;
  color: inherit;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
.mixin-btn .btn.bg-color-transparent {
  color: #037faa;
  font-weight: 500;
  background: rgba(0, 0, 0, 0);
}
.mixin-btn .btn.bg-color-green-middle {
  border-color: #03aa9e;
  background: #03aa9e;
}
.mixin-btn .btn.bg-color-green-middle:after {
  outline-color: #03aa9e !important;
}
.mixin-btn .btn.bg-color-purple-middle {
  border-color: #a56cbb;
  background: #a56cbb;
}
.mixin-btn .btn.bg-color-purple-middle:after {
  outline-color: #a56cbb !important;
}
.mixin-btn .btn.bg-color-grey-dark {
  border-color: #628290;
  background: #628290;
}
.mixin-btn .btn.bg-color-grey-dark:after {
  outline-color: #628290 !important;
}
.mixin-btn .btn.bg-color-white {
  color: #005e7d;
  font-weight: 500;
  border-color: #fff;
  background: #fff;
}
.mixin-btn .btn.bg-color-white:after {
  outline-color: #fff !important;
}
.mixin-btn .btn.border-color-green-middle {
  color: #03aa9e;
  font-weight: 500;
  border-color: #03aa9e;
}
.mixin-btn .btn.border-color-green-middle:after {
  outline-color: #03aa9e !important;
}
.mixin-btn .btn.border-color-purple-middle {
  color: #a56cbb;
  font-weight: 500;
  border-color: #a56cbb;
}
.mixin-btn .btn.border-color-purple-middle:after {
  outline-color: #a56cbb !important;
}
.mixin-btn .btn.border-color-white {
  color: #fff;
  border-color: #fff;
}
.mixin-btn .btn.border-color-white:after {
  outline-color: #fff !important;
}
.mixin-btn .btn.disabled {
  cursor: default !important;
  background: #aac3cd !important;
  border-color: #aac3cd !important;
}
.mixin-btn .btn.disabled:after {
  content: none;
}
.mixin-btn .btn.disabled.bg-color-transparent {
  color: #aac3cd !important;
  background: rgba(0, 0, 0, 0) !important;
}
.mixin-btn .btn.form-inline-padding {
  height: 34px;
  padding-top: 7px;
}
.mixin-btn .teaser {
  min-width: 250px;
  max-width: 500px;
  color: #aac3cd;
  -webkit-flex: 1 1;
  -moz-flex: 1 1;
  -ms-flex: 1 1;
  -o-flex: 1 1;
  flex: 1 1;
}
.mixin-btn:has(.teaser) {
  justify-content: space-between !important;
}
.mixin-btn.no-padding-top {
  padding-top: 0;
}

@media screen and (max-width: 500px) {
  .mixin-btn .btn {
    font-weight: 500;
  }
}
@media screen and (max-width: 400px) {
  .mixin-btn .btn {
    width: 100%;
    margin: 0;
  }
}
.mixin-choose .choose-buttons {
  margin-bottom: 20px;
  text-align: center;
}
.mixin-choose .choose-buttons .clm {
  margin: 0 auto;
  padding: 0 20px;
}
.mixin-choose .choose-buttons .clm .wrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
}
.mixin-choose .choose-buttons .clm .wrapper h4 {
  margin: 20px 0 5px 0 !important;
  text-transform: uppercase;
  justify-content: center;
  font-weight: 600;
  line-height: 1.3em;
}
.mixin-choose .choose-buttons .clm .wrapper .icon {
  width: 60px;
  height: 60px;
  font-size: 32px;
  text-align: center;
  -webkit-transition: color 0.1s ease-out 0s;
  -moz-transition: color 0.1s ease-out 0s;
  -ms-transition: color 0.1s ease-out 0s;
  -o-transition: color 0.1s ease-out 0s;
  transition: color 0.1s ease-out 0s;
}
.mixin-choose .choose-buttons .clm .wrapper .icon.icon-drink:before {
  top: 14px;
  left: 1px;
}
.mixin-choose .choose-buttons .clm .wrapper .icon.icon-rocket:before {
  top: 15px;
  left: 1px;
}
.mixin-choose .choose-buttons .clm .wrapper .icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ddeff5;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transition: background 0.1s ease-out 0s;
  -moz-transition: background 0.1s ease-out 0s;
  -ms-transition: background 0.1s ease-out 0s;
  -o-transition: background 0.1s ease-out 0s;
  transition: background 0.1s ease-out 0s;
}
.mixin-choose .choose-buttons .clm .wrapper.active .icon, .mixin-choose .choose-buttons .clm .wrapper:hover:not(.no-css-hover) .icon {
  color: #03aa9e;
}
.mixin-choose .choose-buttons .clm .wrapper.active .icon:after, .mixin-choose .choose-buttons .clm .wrapper:hover:not(.no-css-hover) .icon:after {
  background: #a2ecb7;
}
.mixin-choose .choose-buttons .clm .wrapper.active:after {
  content: "";
  position: absolute;
  bottom: -22px;
  left: -20px;
  right: -20px;
  height: 2px;
  background: #03aa9e;
}
.mixin-choose .choose-buttons .clm:first-child:last-child .wrapper.active:after, .mixin-choose .choose-buttons .clm:first-child:last-child .wrapper:hover:not(.no-css-hover):after {
  content: none;
}
.mixin-choose .choose-wrapper {
  display: none;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
}
.mixin-choose .choose-wrapper.active {
  display: block;
}

@media screen and (max-width: 600px) {
  .mixin-choose .choose-buttons {
    -webkit-gap: 40px;
    -moz-gap: 40px;
    -ms-gap: 40px;
    -o-gap: 40px;
    gap: 40px;
  }
  .mixin-choose .choose-buttons .clm {
    width: 100%;
  }
  .mixin-choose .choose-buttons .clm .wrapper:after,
  .mixin-choose .choose-buttons .clm .wrapper.active:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: -20px;
    right: -20px;
    height: 1px;
    background: linear-gradient(90deg, rgba(3, 127, 170, 0.15), rgba(3, 127, 170, 0.15) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
    background-size: 8px 1px;
    pointer-events: none;
  }
  .mixin-choose .choose-buttons .clm:last-child .wrapper:after {
    content: none;
  }
  .mixin-choose.choosen .choose-buttons {
    -webkit-gap: 0;
    -moz-gap: 0;
    -ms-gap: 0;
    -o-gap: 0;
    gap: 0;
  }
  .mixin-choose.choosen .choose-buttons .clm .wrapper:not(.active) {
    display: none;
  }
  .mixin-choose.choosen .choose-buttons .clm .wrapper:after {
    content: none;
  }
}
.mixin-color-picker {
  width: 300px;
  max-width: 100%;
}
.mixin-color-picker .picker_wrapper.layout_default {
  display: block;
  padding: 0;
  width: auto;
  text-align: left;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.mixin-color-picker .picker_wrapper.layout_default .picker_slider {
  margin: 0 0 20px 0;
  padding: 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -ms-border-radius: 4px 4px 4px 4px;
  -o-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.mixin-color-picker .picker_wrapper.layout_default .picker_slider .picker_selector {
  padding: 8px;
  color: inherit;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.mixin-color-picker .picker_wrapper.layout_default .picker_slider .picker_selector:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_sl {
  margin: 0 0 20px 0;
  width: 100%;
  height: 150px;
  outline: 1px solid #aac3cd;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.mixin-color-picker .picker_wrapper.layout_default .picker_sl .picker_selector {
  padding: 8px;
  color: inherit;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.mixin-color-picker .picker_wrapper.layout_default .picker_sl .picker_selector:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_sl:before {
  content: none;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_editor {
  display: inline-block;
  margin: 0 0 0 1px;
  width: 120px;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_editor input {
  padding: 4px 5px;
  width: 100%;
  height: auto;
  color: #999c9e;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  border: 1px solid #aac3cd;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_sample {
  display: inline-block;
  position: relative;
  top: -1px;
  left: -34px;
  margin: 0;
  width: 34px;
  height: 34px;
  border: 1px solid #aac3cd;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_done {
  display: inline-block;
  float: right;
  margin: 0;
}
.mixin-color-picker .picker_wrapper.layout_default .picker_done button {
  padding: 5px 10px;
  color: #fbfeff;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6em;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background: #099bda;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

.mixin-dots {
  display: inline-block;
  position: relative;
}
.mixin-dots .dot {
  position: absolute;
  left: 10px;
  width: 4px;
  height: 4px;
  background: #628290;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-dots .dot:nth-child(1) {
  top: 10px;
}
.mixin-dots .dot:nth-child(2) {
  top: 17px;
}
.mixin-dots .dot:nth-child(3) {
  top: 24px;
}

.mixin-duplicate {
  position: relative;
}
.mixin-duplicate .duplicate-item {
  padding-right: 64px;
}
.mixin-duplicate .duplicate-item .duplicate-remove {
  position: absolute;
  bottom: 0;
  right: 0;
}
.mixin-duplicate .duplicate-item .duplicate-remove .mixin-btn {
  padding-top: 0;
}
.mixin-duplicate .duplicate-item .duplicate-remove .mixin-btn .btn {
  padding: 7px 12px;
}
.mixin-duplicate .duplicate-add {
  position: absolute;
  left: 0;
  bottom: 0;
}

.mixin-flash {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  min-height: 47px;
  background: #fbfeff;
  border: 1px solid #628290;
  border-left-width: 5px;
  overflow: hidden;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-flash p {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  hyphens: auto;
}
.mixin-flash p > a {
  display: inline !important;
  color: inherit !important;
  font: inherit !important;
  text-decoration: underline;
}
.mixin-flash .icon {
  float: right;
  min-width: 45px;
  margin: -10px -10px -10px 10px;
  padding: 10px 10px 10px 12px;
  color: #fbfeff;
  font-size: 30px;
  line-height: 25px;
  -webkit-border-radius: 3px 0 3px 3px;
  -moz-border-radius: 3px 0 3px 3px;
  -ms-border-radius: 3px 0 3px 3px;
  -o-border-radius: 3px 0 3px 3px;
  border-radius: 3px 0 3px 3px;
}
.mixin-flash.success {
  border-color: #03aa9e;
}
.mixin-flash.success p {
  color: #03aa9e;
}
.mixin-flash.success .icon {
  padding-left: 17px;
  padding-right: 13px;
  font-size: 22px;
  background: #03aa9e;
}
.mixin-flash.error {
  border-color: #e2430f;
}
.mixin-flash.error p {
  color: #e2430f;
}
.mixin-flash.error .icon {
  background: #e2430f;
}
.mixin-flash.notification {
  border-color: #037faa;
}
.mixin-flash.notification p {
  color: #037faa;
}
.mixin-flash.notification .icon {
  background: #037faa;
}

.mixin-flow-chart {
  position: relative;
  border-bottom: 10px solid #03aa9e;
}
.mixin-flow-chart .mixin-article {
  position: relative;
  z-index: 1;
}
.mixin-flow-chart .mixin-article .steps {
  display: flex;
  margin-top: 20px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 40px;
  -moz-gap: 40px;
  -ms-gap: 40px;
  -o-gap: 40px;
  gap: 40px;
}
.mixin-flow-chart .mixin-article .steps .step {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  -webkit-flex: 1 1 calc(25% - 120px);
  -moz-flex: 1 1 calc(25% - 120px);
  -ms-flex: 1 1 calc(25% - 120px);
  -o-flex: 1 1 calc(25% - 120px);
  flex: 1 1 calc(25% - 120px);
}
.mixin-flow-chart .mixin-article .steps .step img {
  max-width: 250px;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper > * {
  position: relative;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper > *:after {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  z-index: -1;
  background: #fbfeff;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -ms-filter: blur(10px);
  -o-filter: blur(10px);
  filter: blur(10px);
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper p {
  max-width: 250px;
  text-align: center;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper .mixin-btn {
  width: 100%;
  max-width: 250px;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper .mixin-btn .btn {
  width: 100%;
}
.mixin-flow-chart .mixin-article .steps .step .equal-wrapper .mixin-link {
  text-align: center;
}
.mixin-flow-chart .mixin-article .steps .transition {
  display: flex;
  justify-content: center;
  position: relative;
  -webkit-flex: 0 0 50px;
  -moz-flex: 0 0 50px;
  -ms-flex: 0 0 50px;
  -o-flex: 0 0 50px;
  flex: 0 0 50px;
}
.mixin-flow-chart .mixin-article .steps .transition img {
  width: 50px;
}
.mixin-flow-chart .mixin-article .steps .transition:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 48px;
  height: 48px;
  background: #fbfeff;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-flow-chart .backround-image-wrapper {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center 100px;
  background-size: cover;
  opacity: 0.2;
  filter: alpha(opacity=20);
  -ms-filter: alpha(opacity=20);
}

@media screen and (max-width: 1400px) {
  .mixin-flow-chart .mixin-article .steps .step {
    -webkit-flex: 1 1 calc(50% - 65px);
    -moz-flex: 1 1 calc(50% - 65px);
    -ms-flex: 1 1 calc(50% - 65px);
    -o-flex: 1 1 calc(50% - 65px);
    flex: 1 1 calc(50% - 65px);
  }
  .mixin-flow-chart .mixin-article .steps .transition:nth-child(4), .mixin-flow-chart .mixin-article .steps .transition:last-child {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .mixin-flow-chart .mixin-article .steps .step {
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .mixin-flow-chart .mixin-article .steps .step .equal-wrapper {
    content: "data-set-equal-min-height";
  }
  .mixin-flow-chart .mixin-article .steps .transition {
    display: none;
  }
}
.mixin-illustration {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}
.mixin-illustration img {
  position: relative;
}

.mixin-language-switcher {
  min-width: 200px;
}

.mixin-languages {
  min-width: auto !important;
}

.mixin-link {
  margin-top: 20px;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mixin-link .link {
  display: inline-block;
  margin-bottom: 5px;
  color: #42bbef;
  font-weight: 500;
  text-decoration: underline;
}
.mixin-link .link .icon {
  top: 9px;
  margin-left: 8px;
  color: inherit;
  font-size: 11px;
}
.mixin-link .link:last-child {
  margin-bottom: 0;
}
.mixin-link.no-margin {
  margin: 0;
}

.mixin-multilingual {
  float: right;
  position: relative;
  top: 1px;
  margin: 0 0 3px 5px;
  font-size: 0;
  border: 1px solid #03aa9e;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-multilingual span {
  display: inline-block;
  padding: 3px 5px;
  color: #03aa9e;
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
  text-transform: uppercase;
}

th .mixin-multilingual {
  float: none;
  display: inline-block;
  margin-left: 10px;
}

.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file {
  display: block;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper {
  -webkit-gap: 20px 10px;
  -moz-gap: 20px 10px;
  -ms-gap: 20px 10px;
  -o-gap: 20px 10px;
  gap: 20px 10px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper .accept,
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper .dimension {
  margin: 0;
  padding: 5px;
  font-size: 12px;
  line-height: 1em;
  text-align: center;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper .btn {
  padding: 10px 15px;
  color: #fbfeff;
  font-size: 16px;
  line-height: 1em;
  background: #037faa;
  border: 1px solid #037faa;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  -o-flex: 1 1 100%;
  flex: 1 1 100%;
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper .info {
  display: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  -webkit-flex: 0 0 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  -o-flex: 0 0 100%;
  flex: 0 0 100%;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .btn-wrapper .info.error {
  display: block;
  color: #e2430f;
  border-color: rgba(0, 0, 0, 0);
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .img-wrapper {
  clear: both;
  padding: 20px 0 0 0;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row .input-file .img-wrapper img {
  width: 100%;
  height: auto;
  max-width: none;
  max-height: none;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-logo .row:last-child {
  margin-bottom: 0;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-colors .row {
  display: flex;
  margin-bottom: -20px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 10px 20px;
  -moz-gap: 10px 20px;
  -ms-gap: 10px 20px;
  -o-gap: 10px 20px;
  gap: 10px 20px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-colors .row .wrapper-color {
  display: inline-block;
  position: relative;
  -webkit-flex: 1 0 150px;
  -moz-flex: 1 0 150px;
  -ms-flex: 1 0 150px;
  -o-flex: 1 0 150px;
  flex: 1 0 150px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-colors .row .wrapper-color .color-picker-input .icon {
  margin-right: 40px;
  color: #aac3cd;
  cursor: pointer;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-colors .row .wrapper-color .color-picker-background {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  border: 1px solid #aac3cd;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-colors .row .wrapper-color.placeholder {
  height: 1px;
  border: none;
  pointer-events: none;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-fonts {
  position: relative;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-fonts .row .font-selector-input .icon {
  margin-right: 46px;
  color: #aac3cd;
  cursor: pointer;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-fonts .row .edit {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-fonts .row .edit .btn {
  height: 34px;
  padding: 8px 10px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-preview.preview-configurator .divisions-wrapper .wrapper-fonts .row:last-child {
  margin-bottom: 0;
}
.mixin-preview.preview-screen {
  position: relative;
  min-width: 500px;
}
.mixin-preview.preview-screen .smartphone {
  position: relative;
  width: 435px;
  height: 800px;
  margin: -70px 0 -70px -40px;
  padding: 60px 30px;
  background: #005e7d;
  -webkit-border-radius: 50px 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px 50px;
  -ms-border-radius: 50px 50px 50px 50px;
  -o-border-radius: 50px 50px 50px 50px;
  border-radius: 50px 50px 50px 50px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.mixin-preview.preview-screen .smartphone iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: hidden;
}
.mixin-preview.preview-screen .smartphone:before {
  content: "";
  position: absolute;
  top: 140px;
  left: -4px;
  width: 5px;
  height: 90px;
  background: #005e7d;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.mixin-preview.preview-screen .smartphone:after {
  content: "";
  position: absolute;
  top: 160px;
  right: -4px;
  width: 5px;
  height: 60px;
  background: #005e7d;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.mixin-preview.preview-screen .mixin-qr-code {
  position: absolute;
  top: 40%;
  right: 0;
}

@media screen and (max-width: 1400px) {
  .mixin-preview.preview-screen {
    min-width: auto;
  }
  .mixin-preview.preview-screen .smartphone {
    margin-left: auto;
    margin-right: auto;
  }
  .mixin-preview.preview-screen .mixin-qr-code {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-preview.preview-screen .smartphone {
    display: none;
  }
}
.mixin-qr-code {
  display: inline-block;
  position: relative;
  background: #42bbef;
  -webkit-border-radius: 15px 15px 15px 15px;
  -moz-border-radius: 15px 15px 15px 15px;
  -ms-border-radius: 15px 15px 15px 15px;
  -o-border-radius: 15px 15px 15px 15px;
  border-radius: 15px 15px 15px 15px;
}
.mixin-qr-code span {
  display: block;
  position: relative;
  padding-top: 2px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
.mixin-qr-code .qr-code-wrapper {
  position: relative;
  margin: 10px 10px 5px 10px;
  width: 100px;
  height: 100px;
}
.mixin-qr-code .qr-code-wrapper canvas {
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.mixin-qr-code .qr-code-wrapper img {
  width: 100px;
  height: 100px;
}
.mixin-qr-code:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 30px;
  left: 5px;
  right: 5px;
  background: #fbfeff;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

.mixin-query.mixin-query-pagination .btn {
  display: inline-block;
  padding: 7px 10px;
  color: #fbfeff;
  font-size: 16px;
  line-height: 1em;
  text-transform: uppercase;
  background: #037faa;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mixin-query.mixin-query-pagination .btn .icon {
  color: #fbfeff;
}
.mixin-query.mixin-query-pagination .btn.prev .icon {
  left: -1px;
}
.mixin-query.mixin-query-pagination .btn.next .icon {
  right: -1px;
}
.mixin-query.mixin-query-pagination .btn.disabled {
  cursor: default;
  background: #aac3cd;
  border-color: #aac3cd;
}

.mixin-quotes {
  position: relative;
  margin-bottom: 20px !important;
}
.mixin-quotes .mixin-article .mixin-article-header {
  margin: -20px auto 0 auto;
  max-width: 900px;
}
.mixin-quotes .mixin-article .mixin-article-header h2 {
  font-size: 50px;
  line-height: 1.1em;
}
.mixin-quotes .mixin-article .mixin-article-header .wrapper-icon {
  position: absolute;
  top: -100px;
  left: -90px;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: left;
}
.mixin-quotes .mixin-article .mixin-article-header .wrapper-icon .icon {
  color: #03aa9e;
  font-size: 200px;
}
.mixin-quotes .mixin-article .mixin-article-header .wrapper-icon:before {
  border: none;
  background: none;
}
.mixin-quotes .mixin-article .mixin-article-header .wrapper-icon:after {
  content: none;
}
.mixin-quotes .mixin-article .mixin-article-header .subline {
  padding: 30px 0 0 5px;
  font-size: 24px;
}
.mixin-quotes .mixin-article .copy {
  margin: 20px auto 80px auto;
  padding-right: 200px;
  max-width: 900px;
}
.mixin-quotes .mixin-article .copy p {
  font-weight: 500;
}
.mixin-quotes .mixin-image-strip {
  margin: 50px 0;
  padding: 0 60px;
  background: #005e7d;
}
.mixin-quotes .mixin-image-strip .img-wrapper {
  display: block;
}
.mixin-quotes .background-wrapper {
  position: absolute;
  top: -50px;
  bottom: -10%;
  right: 0;
  width: 15%;
  background: #03aa9e;
  transform: skewY(-7deg);
}

@media screen and (max-width: 1700px) {
  .mixin-quotes .mixin-image-strip .img-wrapper:nth-child(7) {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .mixin-quotes .mixin-image-strip .img-wrapper:nth-child(8) {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .mixin-quotes .mixin-article .mixin-article-header .wrapper-icon {
    left: -20px;
  }
  .mixin-quotes .background-wrapper {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .mixin-quotes .mixin-image-strip .img-wrapper:nth-child(5) {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-quotes .mixin-article .mixin-article-header .wrapper-icon {
    top: -120px;
  }
  .mixin-quotes .mixin-article .copy {
    margin-bottom: 0;
    padding-right: 0;
  }
  .mixin-quotes .mixin-image-strip {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .mixin-quotes .mixin-article .mixin-article-header .wrapper-icon {
    left: 0;
  }
}
.mixin-share-links li a .icon {
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
.mixin-share-links li a:hover:not(.no-css-hover) .icon {
  color: #42bbef;
}

.mixin-slider-items .mixin-article {
  text-align: center;
}
.mixin-slider-items .mixin-article .clm-wrapper {
  justify-content: center;
}
.mixin-slider-items .mixin-article .slide {
  postion: relative;
  padding-bottom: 30px;
  border-bottom: 10px solid #037faa;
}
.mixin-slider-items .mixin-article .slide a {
  cursor: pointer;
}
.mixin-slider-items .mixin-article .slide > .icon {
  display: block;
  margin: -20px 0 10px 0;
  color: #03aa9e;
  font-size: 50px;
}
.mixin-slider-items .mixin-article .slide > .icon.icon-quotes {
  color: #03aa9e;
}
.mixin-slider-items .mixin-article .slide > .icon.icon-checkmark, .mixin-slider-items .mixin-article .slide > .icon.circle-background {
  top: 1px;
  color: #03aa9e;
  font-size: 35px;
  line-height: 60px;
}
.mixin-slider-items .mixin-article .slide > .icon.icon-checkmark:after, .mixin-slider-items .mixin-article .slide > .icon.circle-background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  width: 60px;
  height: 60px;
  background: #a2ecb7;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-slider-items .mixin-article .slide .preline {
  display: block;
  margin: -10px 0 10px 0;
  font-size: 50px;
  line-height: 1em;
  color: #03aa9e;
  font-family: "Museo Slab", sans-serif;
}
.mixin-slider-items .mixin-article .slide .headline {
  margin-bottom: 10px;
  color: #037faa;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: "Museo Slab", sans-serif;
}
.mixin-slider-items .mixin-article .slide .headline[data-trigger-href] {
  cursor: pointer;
}
.mixin-slider-items .mixin-article .slide .headline[data-trigger-href]:hover:not(.no-css-hover) {
  text-decoration: underline;
}
.mixin-slider-items .mixin-article .slide .teaser {
  font-weight: 500;
  text-align: center;
}
.mixin-slider-items .mixin-article .slide .image-wrapper {
  margin: 10px -30px -30px -30px;
}
.mixin-slider-items .mixin-article .slide .image-wrapper img {
  width: 100%;
}
.mixin-slider-items .mixin-link {
  margin-bottom: -5px;
  text-align: center;
}
.mixin-slider-items .mixin-btn {
  margin-top: 10px;
  justify-content: center;
}
.mixin-slider-items .royalSlider {
  display: none;
  margin: 0 -120px;
  width: calc(100% + 240px);
  height: auto;
  -webkit-transition: height 0.3s ease-out 0s;
  -moz-transition: height 0.3s ease-out 0s;
  -ms-transition: height 0.3s ease-out 0s;
  -o-transition: height 0.3s ease-out 0s;
  transition: height 0.3s ease-out 0s;
}
.mixin-slider-items .royalSlider * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mixin-slider-items .royalSlider .rsVisibleNearbyWrap {
  height: inherit !important;
}
.mixin-slider-items .royalSlider .rsVisibleNearbyWrap .rsSlide {
  padding: 30px 30px 30px 30px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  -ms-filter: alpha(opacity=40);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.mixin-slider-items .royalSlider .rsVisibleNearbyWrap .rsSlide.rsActiveSlide {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-slider-items .royalSlider .rsNav {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.mixin-slider-items .royalSlider .rsNav .rsNavItem {
  padding: 8px;
}
.mixin-slider-items .royalSlider .rsNav .rsNavItem span {
  display: block;
  width: 15px;
  height: 15px;
  background: #aac3cd;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transition: background 0.3s ease-out 0s;
  -moz-transition: background 0.3s ease-out 0s;
  -ms-transition: background 0.3s ease-out 0s;
  -o-transition: background 0.3s ease-out 0s;
  transition: background 0.3s ease-out 0s;
}
.mixin-slider-items .royalSlider .rsNav .rsNavItem.rsNavSelected span {
  background: #037faa;
}
.mixin-slider-items.initialized-slider .royalSlider {
  display: block;
}

.bg-color-blue-logo-2 .mixin-slider-items .mixin-article .slide {
  border-color: #03aa9e;
}
.bg-color-blue-logo-2 .mixin-slider-items .royalSlider .rsVisibleNearbyWrap .rsSlide {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -ms-filter: alpha(opacity=60);
}
.bg-color-blue-logo-2 .mixin-slider-items .royalSlider .rsVisibleNearbyWrap .rsSlide.rsActiveSlide {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.bg-color-blue-logo-2 .mixin-slider-items .royalSlider .rsNav .rsNavItem span {
  background: #005e7d;
}
.bg-color-blue-logo-2 .mixin-slider-items .royalSlider .rsNav .rsNavItem.rsNavSelected span {
  background: #03aa9e;
}

html.isBot .royalSlider {
  display: block;
}

@media screen and (max-width: 1500px) {
  .mixin-slider-items .royalSlider {
    width: calc(100% + 500px);
    margin: 0 -250px;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-slider-items .royalSlider {
    width: calc(100% + 800px);
    margin: 0 -400px;
  }
}
@media screen and (max-width: 800px) {
  .mixin-slider-items .royalSlider {
    width: calc(300% + 200px);
    margin: 0 calc(-100% - 100px);
  }
}
@media screen and (max-width: 400px) {
  .mixin-slider-items .mixin-article .slide > .icon {
    margin-top: -5px;
  }
  .mixin-slider-items .mixin-article .slide .preline {
    margin-top: 0;
  }
}
.mixin-slider-range {
  position: relative;
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
}
.mixin-slider-range > .header {
  display: flex;
}
.mixin-slider-range > .header .label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-slider-range > .header .control {
  position: relative;
  height: 20px;
  cursor: pointer;
  -webkit-flex: 0 0 20px;
  -moz-flex: 0 0 20px;
  -ms-flex: 0 0 20px;
  -o-flex: 0 0 20px;
  flex: 0 0 20px;
}
.mixin-slider-range > .header .control span {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
.mixin-slider-range > .header .control.plus:after {
  content: "";
  width: 1px;
  height: 10px;
}
.mixin-slider-range > .header .control:before, .mixin-slider-range > .header .control:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 10px;
  height: 1px;
  background: #628290;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mixin-slider-range > .header .control:before {
  content: "";
}
.mixin-slider-range .free-bar-wrapper {
  position: absolute;
  top: 71px;
  left: 0;
  width: 100%;
  height: 12px;
  overflow: hidden;
  pointer-events: none;
  -webkit-border-radius: 100px 0 0 100px;
  -moz-border-radius: 100px 0 0 100px;
  -ms-border-radius: 100px 0 0 100px;
  -o-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;
}
.mixin-slider-range .free-bar-wrapper .free-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background: #42bbef;
  -webkit-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-slider-range .irs--flat * {
  font-weight: 600;
  font-family: "Museo Slab", sans-serif;
}
.mixin-slider-range .irs--flat .irs-line {
  top: 25px;
  background: #aac3cd;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
.mixin-slider-range .irs--flat .irs-min,
.mixin-slider-range .irs--flat .irs-max {
  display: none;
}
.mixin-slider-range .irs--flat .irs-single,
.mixin-slider-range .irs--flat .irs-from,
.mixin-slider-range .irs--flat .irs-to {
  z-index: 1;
  padding: 3px 5px;
  color: #fbfeff;
  font-size: 12px;
  line-height: 1em;
}
.mixin-slider-range .irs--flat .irs-single.irs-from,
.mixin-slider-range .irs--flat .irs-from.irs-from,
.mixin-slider-range .irs--flat .irs-to.irs-from {
  background: #42bbef;
}
.mixin-slider-range .irs--flat .irs-single.irs-single, .mixin-slider-range .irs--flat .irs-single.irs-to,
.mixin-slider-range .irs--flat .irs-from.irs-single,
.mixin-slider-range .irs--flat .irs-from.irs-to,
.mixin-slider-range .irs--flat .irs-to.irs-single,
.mixin-slider-range .irs--flat .irs-to.irs-to {
  background: #037faa;
}
.mixin-slider-range .irs--flat .irs-single:before,
.mixin-slider-range .irs--flat .irs-from:before,
.mixin-slider-range .irs--flat .irs-to:before {
  content: none;
}
.mixin-slider-range .irs--flat .irs-grid .irs-grid-pol {
  background: #aac3cd;
}
.mixin-slider-range .irs--flat .irs-grid .irs-grid-text {
  color: #aac3cd;
}
.mixin-slider-range .irs--flat .irs-bar {
  top: 25px;
  background: #037faa;
  -webkit-border-radius: 100px 0 0 100px;
  -moz-border-radius: 100px 0 0 100px;
  -ms-border-radius: 100px 0 0 100px;
  -o-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;
}
.mixin-slider-range .irs--flat .irs-handle,
.mixin-slider-range .irs--flat .irs-handle:hover {
  top: 22px;
}
.mixin-slider-range .irs--flat .irs-handle.from > i:first-child,
.mixin-slider-range .irs--flat .irs-handle:hover.from > i:first-child {
  background: #42bbef;
}
.mixin-slider-range .irs--flat .irs-handle.single, .mixin-slider-range .irs--flat .irs-handle.to,
.mixin-slider-range .irs--flat .irs-handle:hover.single,
.mixin-slider-range .irs--flat .irs-handle:hover.to {
  z-index: 2;
}
.mixin-slider-range .irs--flat .irs-handle.single > i:first-child, .mixin-slider-range .irs--flat .irs-handle.to > i:first-child,
.mixin-slider-range .irs--flat .irs-handle:hover.single > i:first-child,
.mixin-slider-range .irs--flat .irs-handle:hover.to > i:first-child {
  background: #037faa;
}
.mixin-slider-range .irs--flat .irs-handle:after,
.mixin-slider-range .irs--flat .irs-handle:hover:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 60px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mixin-slider-range .irs--flat:after {
  content: "↔";
  position: absolute;
  top: -1px;
  left: 50%;
  color: #aac3cd;
  font-size: 20px;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
.mixin-slider-range.double .irs--flat .irs-from,
.mixin-slider-range.double .irs--flat .irs-to {
  visibility: visible !important;
}
.mixin-slider-range.double .irs--flat .irs-single {
  display: none !important;
}
.mixin-slider-range.double .irs--flat .irs-bar {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}

.mixin-switcher {
  border: 1px solid #aac3cd;
}
.mixin-switcher span {
  position: relative;
  min-width: 40px;
  background: #aac3cd;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-switcher span.active {
  color: #628290;
  text-transform: lowercase;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.mixin-switcher span.active:first-of-type {
  color: #f3f6f7;
  background: #03aa9e;
}
.mixin-switcher span.active:after {
  content: none;
}
.mixin-switcher span:after {
  content: "|||";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fbfeff;
  font-size: 8px;
  line-height: 14px;
  text-align: center;
}

.mixin-tile-items .clm-wrapper .clm {
  max-width: 600px;
}
.mixin-tile-items .clm-wrapper .clm .mixin-image-wrapper {
  position: relative;
  margin: -40px -30px 40px -30px;
  padding-top: calc(50% + 30px);
  overflow: hidden;
}
.mixin-tile-items .clm-wrapper .clm .mixin-image-wrapper img {
  position: absolute;
  width: 100%;
  margin: 0;
}

.mixin-tooltip .text {
  max-width: 300px;
  color: #fbfeff !important;
  font-weight: 500 !important;
  text-align: center !important;
  white-space: initial !important;
  word-break: normal !important;
}
.mixin-tooltip .text span {
  color: inherit;
  font: inherit;
  text-align: inherit;
  white-space: inherit;
  word-break: inherit;
}
.mixin-tooltip .text span.headline {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase !important;
}
.mixin-tooltip .text span.headline:last-child {
  margin-bottom: 0;
}
.mixin-tooltip .text-bg {
  border-color: #fbfeff;
  background: #005e7d;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.mixin-tooltip .text-bg .arrow-mask .arrow-position .arrow {
  border-color: #fbfeff;
  background: #005e7d;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

/* == PROJECT / APP / MIXINS / IMAGES ====================================================== */
.mixin-image-manager {
  display: flex;
  justify-content: space-between;
  min-height: 220px;
  background: #41535d;
  border: 1px solid #41535d;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.mixin-image-manager .image {
  width: auto;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-image-manager .no-image {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-image-manager .no-image span {
  display: block;
  position: relative;
  top: 50%;
  width: 100%;
  padding: 0 20px;
  color: #fbfeff;
  font-size: 16px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-image-manager .controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
.mixin-image-manager .controls .control {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-image-manager .controls .control > a,
.mixin-image-manager .controls .control > span {
  line-height: 1em;
  cursor: pointer;
}
.mixin-image-manager .controls .control > a:hover:not(.no-css-hover) .text,
.mixin-image-manager .controls .control > span:hover:not(.no-css-hover) .text {
  text-decoration: underline;
}
.mixin-image-manager .controls .control > a.disabled,
.mixin-image-manager .controls .control > span.disabled {
  cursor: default;
}
.mixin-image-manager .controls .control > a.disabled:hover:not(.no-css-hover) .text,
.mixin-image-manager .controls .control > span.disabled:hover:not(.no-css-hover) .text {
  text-decoration: none;
}
.mixin-image-manager .controls .control > a.disabled .icon,
.mixin-image-manager .controls .control > span.disabled .icon {
  color: #8da3ab;
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
.mixin-image-manager .controls .control > a.disabled .text,
.mixin-image-manager .controls .control > span.disabled .text {
  color: #8da3ab;
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
.mixin-image-manager .controls .control .icon {
  top: -1px;
  color: #fbfeff;
  height: 16px;
}
.mixin-image-manager .controls .control .text {
  display: inline-block;
  padding-left: 15px;
  color: #fbfeff;
  font-size: 16px;
  line-height: 1;
}

@media screen and (max-width: 500px) {
  .mixin-image-manager .controls .control .text {
    display: none;
  }
  .mixin-image-manager .controls .control:nth-child(2) {
    display: none;
  }
}
.mixin-image-strip {
  display: flex;
  position: relative;
  z-index: 1;
  margin: -60px 0 20px 0;
  overflow: hidden;
  transform: skewY(-7deg);
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-image-strip .img-wrapper {
  height: 300px;
  margin: -50px -1px -50px 0;
  background-size: cover;
  background-position: center;
  transform: skewY(7deg);
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-image-strip.stand-alone {
  -webkit-gap: 0 60px;
  -moz-gap: 0 60px;
  -ms-gap: 0 60px;
  -o-gap: 0 60px;
  gap: 0 60px;
}
.mixin-image-strip.stand-alone .img-wrapper {
  background-size: contain;
  background-repeat: no-repeat;
  transform: skewY(0deg);
}

@media screen and (max-width: 1000px) {
  .mixin-image-strip .img-wrapper {
    display: none;
  }
  .mixin-image-strip .img-wrapper:nth-child(1), .mixin-image-strip .img-wrapper:nth-child(2) {
    display: block;
  }
}
.mixin-image-wrapper {
  position: relative;
  height: 100%;
}
.mixin-image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.mixin-image-wrapper img.img-loaded.border-radius {
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}
.mixin-image-wrapper img.img-loaded.box-shadow {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}
.mixin-image-wrapper img:not(.img-loaded) {
  width: 100%;
  height: 100%;
}
.mixin-image-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.mixin-image-wrapper video.motion-app-001 {
  left: 50%;
  max-height: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.mixin-image-wrapper video.motion-app-001[style*="height:"] {
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-image-wrapper figcaption {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Exo 2", sans-serif;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-image-wrapper.center img {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-image-wrapper.bottom img,
.mixin-image-wrapper.bottom video {
  top: auto;
  bottom: 0;
}
.mixin-image-wrapper.right img {
  left: auto !important;
  right: 0;
}
.mixin-image-wrapper.deco:after {
  content: "";
  position: absolute;
  bottom: -100px;
  right: -200px;
  z-index: -1;
  width: 800px;
  height: 100px;
  background: rgba(3, 127, 170, 0.15);
  transform: skewY(-7deg);
}

html.isSafari .mixin-image-wrapper video.motion-app-001 {
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -ms-border-radius: 20px 20px 20px 20px;
  -o-border-radius: 20px 20px 20px 20px;
  border-radius: 20px 20px 20px 20px;
}

@media screen and (min-width: 1601px) {
  .mixin-image-wrapper.oversize img {
    left: -100px;
    max-width: calc(100% + 100px);
    max-height: calc(100% + 100px);
  }
  .mixin-image-wrapper.oversize.supersize img {
    top: -50px;
    max-width: calc(100% + 200px);
    max-height: calc(100% + 200px);
  }
  .mixin-image-wrapper.oversize.right img {
    left: auto;
    right: -100px;
  }
  .mixin-image-wrapper.oversize.viewport-control img {
    left: -200px;
  }
  .mixin-image-wrapper.oversize.viewport-control.center img {
    left: calc(50% - 100px);
  }
  .mixin-image-wrapper.oversize.viewport-control.right img {
    right: -200px;
  }
  .mixin-image-wrapper.oversize.is-visible-in-viewport img {
    left: -100px;
  }
  .mixin-image-wrapper.oversize.is-visible-in-viewport.center img {
    left: 50%;
  }
  .mixin-image-wrapper.oversize.is-visible-in-viewport.right img {
    right: -100px;
  }
}
@media screen and (max-width: 1600px) {
  .mixin-image-wrapper video.motion-app-001 {
    left: 43%;
  }
  html.isSafari .mixin-image-wrapper video.motion-app-001 {
    -webkit-border-radius: 14px 14px 14px 14px;
    -moz-border-radius: 14px 14px 14px 14px;
    -ms-border-radius: 14px 14px 14px 14px;
    -o-border-radius: 14px 14px 14px 14px;
    border-radius: 14px 14px 14px 14px;
  }
}
@media screen and (min-width: 1001px) {
  .mixin-image-wrapper img {
    -webkit-transition: all 0.5s ease-out 0.3s;
    -moz-transition: all 0.5s ease-out 0.3s;
    -ms-transition: all 0.5s ease-out 0.3s;
    -o-transition: all 0.5s ease-out 0.3s;
    transition: all 0.5s ease-out 0.3s;
  }
  .mixin-image-wrapper.viewport-control img {
    left: -100px;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
  }
  .mixin-image-wrapper.viewport-control.right img {
    right: -100px;
  }
  .mixin-image-wrapper.is-visible-in-viewport img {
    left: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
  }
  .mixin-image-wrapper.is-visible-in-viewport.right img {
    right: 0;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-image-wrapper img {
    display: block;
    position: relative;
    margin: 40px auto;
    width: 700px;
  }
  .mixin-image-wrapper img[data-overlay-url] {
    cursor: initial;
    pointer-events: none;
  }
  .mixin-image-wrapper video.motion-app-001 {
    left: 42%;
    bottom: 40px;
  }
  html.isSafari .mixin-image-wrapper video.motion-app-001 {
    -webkit-border-radius: 25px 25px 25px 25px;
    -moz-border-radius: 25px 25px 25px 25px;
    -ms-border-radius: 25px 25px 25px 25px;
    -o-border-radius: 25px 25px 25px 25px;
    border-radius: 25px 25px 25px 25px;
  }
}
@media screen and (max-width: 800px) {
  html.isSafari .mixin-image-wrapper video.motion-app-001 {
    -webkit-border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -ms-border-radius: 20px 20px 20px 20px;
    -o-border-radius: 20px 20px 20px 20px;
    border-radius: 20px 20px 20px 20px;
  }
}
@media screen and (max-width: 600px) {
  html.isSafari .mixin-image-wrapper video.motion-app-001 {
    -webkit-border-radius: 15px 15px 15px 15px;
    -moz-border-radius: 15px 15px 15px 15px;
    -ms-border-radius: 15px 15px 15px 15px;
    -o-border-radius: 15px 15px 15px 15px;
    border-radius: 15px 15px 15px 15px;
  }
}
@media screen and (max-width: 400px) {
  html.isSafari .mixin-image-wrapper video.motion-app-001 {
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -ms-border-radius: 10px 10px 10px 10px;
    -o-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
  }
}
/* == PROJECT / APP / MIXINS / INTERFACES ====================================================== */
.mixin-interface-vue-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 180px);
  text-align: center;
}
.mixin-interface-vue-error .icon {
  margin-bottom: 30px;
  font-size: 60px !important;
}
.mixin-interface-vue-error h3 {
  margin: 0 50px 20px 50px;
  font-size: 30px !important;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.mixin-interface-vue-error p {
  margin: 0 auto;
  max-width: 500px;
}

.mixin-interface-vue-loading {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: calc(100vh - 140px);
}
.mixin-interface-vue-loading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background-image: url("../images/assets/app/layout/loading.svg?1744355188281");
  background-size: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* == PROJECT / APP / MIXINS / NEWSHUB ====================================================== */
.mixin-newshub-article-footer .mixin-share-links {
  position: absolute;
  bottom: 90px;
  right: -60px;
  z-index: 1;
}

.mixin-newshub-article-navigation {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}
.mixin-newshub-article-navigation a {
  display: inline-block;
  position: relative;
  top: -2px;
  padding: 0 10px;
  line-height: 1em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mixin-newshub-article-navigation a .icon {
  color: #42bbef;
}
.mixin-newshub-article-navigation > span {
  display: inline-block;
  color: #aac3cd;
  font-size: 16px;
  line-height: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Museo Slab", sans-serif;
}
.mixin-newshub-article-navigation > span.number {
  width: 20px;
}
.mixin-newshub-article-navigation > span.separator {
  position: relative;
  left: 1px;
}

.mixin-newshub-article-topics .clm-wrapper {
  display: block;
  column-count: 2;
  -webkit-gap: 60px !important;
  -moz-gap: 60px !important;
  -ms-gap: 60px !important;
  -o-gap: 60px !important;
  gap: 60px !important;
}
.mixin-newshub-article-topics .clm-wrapper .clm {
  position: relative;
  margin-bottom: 40px;
  padding: 0 !important;
  max-width: 600px;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper {
  overflow: visible;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > h4 {
  margin: 20px 0 10px 0;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > ul {
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 20px;
  list-style-type: disc;
  -webkit-gap: 10px 0;
  -moz-gap: 10px 0;
  -ms-gap: 10px 0;
  -o-gap: 10px 0;
  gap: 10px 0;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > ul > li {
  hyphens: auto;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > ul > li a {
  display: inline;
  color: #42bbef !important;
  font: inherit !important;
  line-height: inherit !important;
  hyphens: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > ul > li a:hover:not(.no-css-hover) {
  text-decoration: underline;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article > ul > li em {
  color: inherit;
  font: inherit;
  font-weight: 500;
  hyphens: inherit;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article .mixin-image-wrapper {
  margin-bottom: 60px;
  padding-top: 50%;
  background-color: #42bbef;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article .mixin-image-wrapper img {
  position: absolute;
  margin: 0;
  width: 100%;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article .mixin-article-header {
  position: relative;
}
.mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article .mixin-article-header:before {
  content: attr(data-first-char);
  position: absolute;
  top: 3px;
  left: -20px;
  color: rgba(3, 127, 170, 0.1);
  font-size: 100px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Museo Slab", sans-serif;
}
.mixin-newshub-article-topics .clm-wrapper .clm:first-child {
  margin-top: 100px;
}
.mixin-newshub-article-topics .clm-wrapper .clm:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .mixin-newshub-article-footer .mixin-share-links {
    display: flex;
    position: relative;
    bottom: 0;
    right: 0;
    -webkit-gap: 0 10px;
    -moz-gap: 0 10px;
    -ms-gap: 0 10px;
    -o-gap: 0 10px;
    gap: 0 10px;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-newshub-article-topics .clm-wrapper {
    column-count: 1;
  }
  .mixin-newshub-article-topics .clm-wrapper .clm {
    padding: 0 !important;
  }
  .mixin-newshub-article-topics .clm-wrapper .clm .tile-wrapper .mixin-article .mixin-article-header:before {
    left: -5px;
  }
  .mixin-newshub-article-topics .clm-wrapper .clm:first-child {
    margin-top: 0;
  }
}
.mixin-newshub-teaser-items {
  margin-top: 100px;
  column-count: 2;
  -webkit-gap: 100px;
  -moz-gap: 100px;
  -ms-gap: 100px;
  -o-gap: 100px;
  gap: 100px;
}
.mixin-newshub-teaser-items .newshub-item {
  display: inline-block;
  margin-bottom: 80px;
  max-width: 600px;
  -webkit-flex: 0 0 calc(50% - 50px);
  -moz-flex: 0 0 calc(50% - 50px);
  -ms-flex: 0 0 calc(50% - 50px);
  -o-flex: 0 0 calc(50% - 50px);
  flex: 0 0 calc(50% - 50px);
}
.mixin-newshub-teaser-items .newshub-item .tile-wrapper {
  overflow: visible;
}
.mixin-newshub-teaser-items .newshub-item .tile-wrapper .mixin-article .mixin-image-wrapper {
  margin: -70px 0 40px 0;
  padding-top: 50%;
  background: #42bbef;
}
.mixin-newshub-teaser-items .newshub-item .tile-wrapper .mixin-article .mixin-image-wrapper img {
  position: absolute;
  width: 100%;
  margin: 0;
}
.mixin-newshub-teaser-items .newshub-item:first-child {
  margin-top: 100px;
}

@media screen and (max-width: 1400px) {
  .mixin-newshub-teaser-items {
    margin-bottom: -80px;
    -webkit-gap: 60px;
    -moz-gap: 60px;
    -ms-gap: 60px;
    -o-gap: 60px;
    gap: 60px;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-newshub-teaser-items {
    margin-top: 40px;
    column-count: 1;
  }
}
@media screen and (max-width: 600px) {
  .mixin-newshub-teaser-items {
    margin-top: 0;
  }
}
/* == PROJECT / APP / MIXINS / OVERLAYS ====================================================== */
.mixin-overlay-header {
  display: flex;
  margin-bottom: 20px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -ms-gap: 15px;
  -o-gap: 15px;
  gap: 15px;
}
.mixin-overlay-header .text {
  display: inline-block;
  max-width: 100%;
  color: #037faa;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Museo Slab", sans-serif;
}
.mixin-overlay-header .icon-warning {
  margin-top: -10px;
  color: #037faa;
  font-size: 50px;
}
.mixin-overlay-header .icon-checkmark {
  margin-top: -8px;
  padding: 10px;
  color: #03aa9e;
  font-size: 30px;
  background: #a2ecb7;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-overlay-header .icon-bot {
  margin-top: -9px;
  padding: 10px;
  color: #fbfeff;
  font-size: 30px;
  background: #037faa;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.mixin-overlay-header .icon-statistics {
  color: #03aa9e;
  font-size: 36px;
}

/* == PROJECT / APP / MIXINS / TRANSLATE ====================================================== */
.mixin-translate-string {
  display: inline-block;
  margin-top: 3px;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* == PROJECT / APP / MODULES / GLOBALS ====================================================== */
#clients-images {
  margin-top: 10px;
}
#clients-images .images-wrapper {
  display: flex;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
#clients-images .images-wrapper .image-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-flex: 1 0 140px;
  -moz-flex: 1 0 140px;
  -ms-flex: 1 0 140px;
  -o-flex: 1 0 140px;
  flex: 1 0 140px;
}
#clients-images .images-wrapper .image-wrapper img {
  width: 100%;
}
#clients-images .images-wrapper .image-wrapper .enlarge {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 38px;
  overflow: hidden;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper .controls {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 38px;
  z-index: 1;
  padding-top: 0;
  text-align: center;
  background: #41535d;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper .controls .control {
  display: inline-block;
  margin: 0 7px;
  line-height: 38px;
  cursor: pointer;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper .controls .control .icon {
  color: #fbfeff;
  line-height: 38px;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper .controls .control.select {
  display: block;
  position: relative;
  top: 0;
  height: 38px;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper .controls .control.select .text {
  position: relative;
  top: 6px;
  color: #fbfeff;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
#clients-images .images-wrapper .image-wrapper .controls-wrapper.controls-wrapper-overlay .controls {
  background: #03aa9e;
}
#clients-images .images-wrapper .image-wrapper .used-counter {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 5px 7px;
  color: #fbfeff;
  font-size: 14px;
  line-height: 1em;
  background: #03aa9e;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#clients-images .images-wrapper .image-wrapper .used-counter.is-null {
  background: #41535d;
}
#clients-images .images-wrapper .image-wrapper.placeholder {
  margin-bottom: 0;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
#clients-images .images-wrapper .image-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.25s ease-out 0s;
  -moz-transition: opacity 0.25s ease-out 0s;
  -ms-transition: opacity 0.25s ease-out 0s;
  -o-transition: opacity 0.25s ease-out 0s;
  transition: opacity 0.25s ease-out 0s;
}
#clients-images .images-wrapper .image-wrapper:hover:not(.no-css-hover) .controls-wrapper .controls {
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#clients-images .images-wrapper .image-wrapper:hover:not(.no-css-hover):after {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

html.isTouch #clients-images .images-wrapper .image-wrapper .controls-wrapper .controls {
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
html.isTouch #clients-images .images-wrapper .image-wrapper:after {
  content: none;
}

@media screen and (max-width: 500px) {
  #clients-images .images-wrapper .image-wrapper {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 400px) {
  #clients-images .images-wrapper .image-wrapper {
    width: calc(50% - 5px);
  }
}
#footer {
  position: relative;
  padding: 60px 0 20px 0;
  background: #f6f9fc;
}
#footer .center-wide-ctn {
  position: relative;
}
#footer .center-wide-ctn .navigation nav ul li a,
#footer .center-wide-ctn .navigation nav ul li span {
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
#footer .center-wide-ctn .navigation nav ul li a.style-headline,
#footer .center-wide-ctn .navigation nav ul li span.style-headline {
  color: #037faa;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
#footer .center-wide-ctn .navigation nav ul li a.style-headline:hover:not(.no-css-hover),
#footer .center-wide-ctn .navigation nav ul li span.style-headline:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#footer .center-wide-ctn .navigation nav ul li a:hover:not(.no-css-hover),
#footer .center-wide-ctn .navigation nav ul li span:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#footer .center-wide-ctn .navigation .logo {
  width: 100%;
}
#footer .center-wide-ctn .navigation .logo img {
  max-width: 230px;
}
#footer .center-wide-ctn .navigation .logo span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Exo 2", sans-serif;
}
#footer .center-wide-ctn .copyright {
  margin: 20px 0 0 0;
  padding: 20px 0;
  text-align: center;
}
#footer .center-wide-ctn .copyright p {
  font-size: 16px;
  font-family: "Exo 2", sans-serif;
}
#footer .center-wide-ctn .clm-wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
#footer .center-wide-ctn .clm-wrapper .clm {
  position: relative;
}
#footer .center-wide-ctn .clm-wrapper .clm.clm-2, #footer .center-wide-ctn .clm-wrapper .clm.clm-4 {
  padding: 0 20px;
}
#footer .center-wide-ctn .clm-wrapper .clm.clm-2.clm-2, #footer .center-wide-ctn .clm-wrapper .clm.clm-4.clm-2 {
  -webkit-flex: 1 1 50%;
  -moz-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  -o-flex: 1 1 50%;
  flex: 1 1 50%;
}
#footer .center-wide-ctn .clm-wrapper .clm.clm-2.clm-4, #footer .center-wide-ctn .clm-wrapper .clm.clm-4.clm-4 {
  -webkit-flex: 1 1 25%;
  -moz-flex: 1 1 25%;
  -ms-flex: 1 1 25%;
  -o-flex: 1 1 25%;
  flex: 1 1 25%;
}
#footer .center-wide-ctn .clm-wrapper .clm.clm-2:first-child, #footer .center-wide-ctn .clm-wrapper .clm.clm-4:first-child {
  padding-left: 0;
}
#footer .center-wide-ctn .clm-wrapper .clm.clm-2:last-child, #footer .center-wide-ctn .clm-wrapper .clm.clm-4:last-child {
  padding-right: 0;
}
#footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 10px;
  background: #03aa9e;
}

@media screen and (max-width: 1100px) {
  body.sidebar-badges-is-visible #footer {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 800px) {
  #footer .center-wide-ctn .copyright {
    margin: 10px 0 0 0;
  }
  #footer .center-wide-ctn .clm-wrapper .clm {
    -webkit-flex: 1 1 100% !important;
    -moz-flex: 1 1 100% !important;
    -ms-flex: 1 1 100% !important;
    -o-flex: 1 1 100% !important;
    flex: 1 1 100% !important;
  }
  #footer .center-wide-ctn .clm-wrapper .clm:nth-child(1) {
    display: block;
    padding: 0 0 20px 0;
    text-align: center;
  }
  #footer .center-wide-ctn .clm-wrapper .clm:nth-child(1):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100vw;
    width: 200vw;
    height: 1px;
    background: rgba(3, 127, 170, 0.1);
    pointer-events: none;
  }
  #footer .center-wide-ctn .clm-wrapper .clm:nth-child(2), #footer .center-wide-ctn .clm-wrapper .clm:nth-child(3) {
    display: block;
    padding: 0;
    text-align: center;
  }
  #footer .center-wide-ctn .clm-wrapper .clm:nth-child(2) ul li:first-child, #footer .center-wide-ctn .clm-wrapper .clm:nth-child(3) ul li:first-child {
    margin-top: 20px;
  }
}
#header {
  position: relative;
  z-index: 50;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -moz-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -ms-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -o-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}
#header .fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
#header .fixed-header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 66px;
  background: #fbfeff;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#header .wrapper-navigation {
  padding: 20px 0;
}
#header .wrapper-navigation.wrapper-navigation-top nav {
  position: relative;
  float: right;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul {
  font-size: 0;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 20px;
  padding-bottom: 14px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span {
  display: inline-block;
  height: 32px;
  padding: 0 15px;
  color: #037faa;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a .label,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span .label {
  color: inherit;
  font: inherit;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a .icon,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span .icon {
  margin: -1px -1px -1px 5px;
  padding: 7px 10px 7px 7px;
  color: #fbfeff;
  background: #005e7d;
  -webkit-border-radius: 0 100px 100px 0;
  -moz-border-radius: 0 100px 100px 0;
  -ms-border-radius: 0 100px 100px 0;
  -o-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a.has-icon,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span.has-icon {
  padding-right: 0;
  color: #005e7d;
  border: 1px solid #005e7d;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a.has-icon.active, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a.has-icon:hover:not(.no-css-hover),
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span.has-icon.active,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span.has-icon:hover:not(.no-css-hover) {
  background: #005e7d;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a.active, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a:hover:not(.no-css-hover),
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span.active,
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span:hover:not(.no-css-hover) {
  color: #fbfeff;
  background: #005e7d;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul {
  position: absolute;
  top: 100%;
  right: 50%;
  padding: 20px;
  background: #fbfeff;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  -ms-column-gap: 40px;
  -o-column-gap: 40px;
  column-gap: 40px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transform-origin: 300px 0;
  -moz-transform-origin: 300px 0;
  -ms-transform-origin: 300px 0;
  -o-transform-origin: 300px 0;
  transform-origin: 300px 0;
  -webkit-transform: perspective(300px) translate(50%, 0) rotateX(-7deg);
  -moz-transform: perspective(300px) translate(50%, 0) rotateX(-7deg);
  -ms-transform: perspective(300px) translate(50%, 0) rotateX(-7deg);
  -o-transform: perspective(300px) translate(50%, 0) rotateX(-7deg);
  transform: perspective(300px) translate(50%, 0) rotateX(-7deg);
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li {
  display: inline-block;
  width: 100%;
  font-size: 0;
  clear: both;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a {
  display: block;
  position: relative;
  padding: 5px 0;
  font-size: 0;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span {
  display: block;
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span.image-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  height: calc(100% - 10px);
  padding: 0 10px;
  background: rgba(3, 127, 170, 0.05);
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span.image-wrapper img {
  height: 20px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span.label {
  color: #005e7d;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span.teaser {
  width: 300px;
  color: #8da3ab;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4em;
  hyphens: auto;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:has(.image-wrapper) {
  padding-left: 50px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -20px;
  width: 5px;
  background: #03aa9e;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -20px;
  right: -20px;
  z-index: -1;
  background: rgba(3, 127, 170, 0.05);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a.active span.image-wrapper, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:hover:not(.no-css-hover) span.image-wrapper {
  background: rgba(0, 0, 0, 0);
  -webkit-transition: background 0.3s ease-out 0s;
  -moz-transition: background 0.3s ease-out 0s;
  -ms-transition: background 0.3s ease-out 0s;
  -o-transition: background 0.3s ease-out 0s;
  transition: background 0.3s ease-out 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a.active:before, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a.active:after, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:hover:not(.no-css-hover):before, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:hover:not(.no-css-hover):after {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:hover:not(.no-css-hover) span.teaser {
  text-decoration: underline;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 50%;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #fbfeff;
  -webkit-transform: translate(50%, 0);
  -moz-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #005e7d;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu {
  display: none;
  position: absolute;
  top: -6px;
  right: -13px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu.opened {
  top: -8px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: perspective(0) rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  -ms-transform: perspective(0) rotateZ(-90deg);
  -o-transform: perspective(0) rotateZ(-90deg);
  transform: perspective(0) rotateZ(-90deg);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu:before, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 10px;
  background: #628290;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu:before {
  top: 12px;
  left: 22px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu:after {
  top: 19px;
  left: 15px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li:hover:not(.no-css-hover) > a {
  color: #fbfeff;
  background: #005e7d;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li:hover:not(.no-css-hover) > ul {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
  -moz-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
  -ms-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
  -o-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
  transform: perspective(300px) translate(50%, 0) rotateX(0deg);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li:last-child > ul {
  right: -20px;
  -webkit-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -moz-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -ms-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  -o-transform: perspective(300px) translate(0, 0) rotateX(-7deg);
  transform: perspective(300px) translate(0, 0) rotateX(-7deg);
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li:last-child > ul:before {
  right: 100px;
}
#header .wrapper-navigation.wrapper-navigation-top nav > ul > li:last-child:hover:not(.no-css-hover) > ul {
  -webkit-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -moz-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -ms-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  -o-transform: perspective(300px) translate(0, 0) rotateX(0deg);
  transform: perspective(300px) translate(0, 0) rotateX(0deg);
}
#header .wrapper-navigation.wrapper-navigation-top .logo {
  display: inline-block;
  overflow: hidden;
}
#header .wrapper-navigation.wrapper-navigation-top .logo img {
  width: 200px;
  max-height: 26px;
}
#header .wrapper-navigation.wrapper-navigation-bottom {
  padding-bottom: 80px;
  height: 68px;
}
#header .wrapper-navigation.wrapper-navigation-bottom nav ul {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 50px;
  -moz-gap: 0 50px;
  -ms-gap: 0 50px;
  -o-gap: 0 50px;
  gap: 0 50px;
}
#header .wrapper-navigation.wrapper-navigation-bottom nav ul li {
  display: inline-block;
}
#header .wrapper-navigation.wrapper-navigation-bottom nav ul li a {
  color: #42bbef;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Exo 2", sans-serif;
}
#header .wrapper-navigation.wrapper-navigation-bottom nav ul li a span {
  position: relative;
  top: 3px;
  margin-left: 5px;
  color: #037faa;
  font-size: 12px;
  font-weight: 600;
}
#header .wrapper-navigation.wrapper-navigation-bottom nav ul li a.active, #header .wrapper-navigation.wrapper-navigation-bottom nav ul li a:hover:not(.no-css-hover) {
  color: #037faa;
}
#header .wrapper-language {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 0;
}
#header .wrapper-language nav {
  padding-top: 27px;
  font-size: 0;
  white-space: nowrap;
}
#header .wrapper-language nav ul {
  font-size: 0;
}
#header .wrapper-language nav ul li {
  display: inline-block;
  margin-left: 7px;
  font-size: 0;
}
#header .wrapper-language nav ul li a {
  color: #aac3cd;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
#header .wrapper-language nav ul li.active a {
  color: #628290;
}
#header .wrapper-language nav ul li:first-child {
  margin: 0;
}
#header .wrapper-burger {
  display: none;
  position: absolute;
  top: 17px;
  right: 30px;
  z-index: 2;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
  -moz-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
  -ms-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
  -o-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
}
#header .wrapper-burger div {
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
}
#header .wrapper-burger div:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #628290;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#header .wrapper-burger div:last-child {
  margin-bottom: 0;
}
#header .wrapper-burger div:nth-child(2):after {
  width: 10px;
}
#header .wrapper-burger div:nth-child(3):after {
  width: 15px;
}
#header .wrapper-burger div:nth-child(4):after {
  width: 5px;
}
#header .wrapper-burger:hover:not(.no-css-hover) div:after {
  width: 100%;
}
#header > .center-wide-ctn {
  position: relative;
  margin-top: 66px;
}
#header > .center-wide-ctn .wrapper-language {
  display: none;
}
#header > .center-wide-ctn:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  width: 200vw;
  height: 1px;
  background: linear-gradient(90deg, rgba(3, 127, 170, 0.15), rgba(3, 127, 170, 0.15) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
  background-size: 8px 1px;
  pointer-events: none;
}

body:not(.window-scroll-top-0) #header .fixed-header:after,
body[id=page-landing-index] #header .fixed-header:after,
body[id=page-features-index] #header .fixed-header:after,
body[id=page-pricing-index] #header .fixed-header:after,
body[id^=page-solutions] #header .fixed-header:after,
body[id^=page-newshub] #header .fixed-header:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

body:not(.css-loaded) #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul {
  -webkit-transition: all 0s linear 0s;
  -moz-transition: all 0s linear 0s;
  -ms-transition: all 0s linear 0s;
  -o-transition: all 0s linear 0s;
  transition: all 0s linear 0s;
}

@media screen and (max-width: 1500px) {
  #header .wrapper-language {
    display: none;
  }
  #header > .center-wide-ctn .wrapper-language {
    display: block;
    right: 10px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1001px) {
  body.hero-is-initialized #header .wrapper-language nav ul li a {
    color: #fbfeff;
    opacity: 0.5;
    filter: alpha(opacity=50);
    -ms-filter: alpha(opacity=50);
  }
  body.hero-is-initialized #header .wrapper-language nav ul li.active a {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
  }
}
@media screen and (max-width: 1100px) {
  #header .center-wide-ctn .wrapper-navigation.wrapper-navigation-bottom nav ul {
    -webkit-gap: 0 40px;
    -moz-gap: 0 40px;
    -ms-gap: 0 40px;
    -o-gap: 0 40px;
    gap: 0 40px;
  }
}
@media screen and (max-width: 1000px), screen and (pointer: coarse) {
  #header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 66px;
    overflow: hidden;
    scrollbar-color: #aac3cd #fbfeff;
    -webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  #header .fixed-header .wrapper-language {
    display: block;
    left: 40px;
  }
  #header .fixed-header:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
  }
  #header .wrapper-navigation.wrapper-navigation-top {
    height: 66px;
    overflow: hidden;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav {
    float: none;
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul {
    pointer-events: none;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li {
    display: block;
    position: relative;
    margin-left: 0;
    padding-bottom: 3px;
    text-align: center;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > a,
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > span {
    font-weight: 600;
    text-transform: uppercase;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul {
    display: none;
    position: relative;
    top: auto;
    right: 50% !important;
    margin: 15px 0 20px 0;
    pointer-events: auto;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -ms-column-count: 1;
    -o-column-count: 1;
    column-count: 1;
    -webkit-transition: all 0s linear 0s;
    -moz-transition: all 0s linear 0s;
    -ms-transition: all 0s linear 0s;
    -o-transition: all 0s linear 0s;
    transition: all 0s linear 0s;
    -webkit-transform: perspective(300px) translate(50%, 0) rotateX(0deg) !important;
    -moz-transform: perspective(300px) translate(50%, 0) rotateX(0deg) !important;
    -ms-transform: perspective(300px) translate(50%, 0) rotateX(0deg) !important;
    -o-transform: perspective(300px) translate(50%, 0) rotateX(0deg) !important;
    transform: perspective(300px) translate(50%, 0) rotateX(0deg) !important;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a span.teaser {
    width: auto;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li a:has(.image-wrapper) {
    text-align: left;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li:first-child a.active span.label:before, #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul > li:first-child a:hover:not(.no-css-hover) span.label:before {
    right: 50%;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li > ul:before {
    right: 50% !important;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li .toggle-submenu {
    display: block;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li:last-child > ul {
    margin-bottom: 10px;
  }
  #header .wrapper-navigation.wrapper-navigation-top nav > ul > li:hover:not(.no-css-hover) > ul {
    -webkit-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
    -moz-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
    -ms-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
    -o-transform: perspective(300px) translate(50%, 0) rotateX(0deg);
    transform: perspective(300px) translate(50%, 0) rotateX(0deg);
  }
  #header .wrapper-navigation.wrapper-navigation-top nav:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100vw;
    width: 200vw;
    height: 1px;
    background: linear-gradient(90deg, rgba(3, 127, 170, 0.1), rgba(3, 127, 170, 0.1) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
    background-size: 8px 1px;
    pointer-events: none;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  #header .wrapper-navigation.wrapper-navigation-top .logo {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #header .wrapper-navigation.wrapper-navigation-bottom {
    padding: 0;
    height: auto;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav {
    position: relative;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav ul {
    pointer-events: none;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav ul li {
    text-align: center;
    -webkit-flex: 1 0 100%;
    -moz-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    -o-flex: 1 0 100%;
    flex: 1 0 100%;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav ul li a {
    position: relative;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav ul li a span {
    position: absolute;
  }
  #header .wrapper-navigation.wrapper-navigation-bottom nav:before {
    content: "";
    position: absolute;
    top: -20px;
    left: -100vw;
    width: 200vw;
    height: 1px;
    background: linear-gradient(90deg, rgba(3, 127, 170, 0.1), rgba(3, 127, 170, 0.1) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
    background-size: 8px 1px;
    pointer-events: none;
  }
  #header .wrapper-language {
    left: 0;
    z-index: 1;
    right: auto;
  }
  #header .wrapper-burger {
    display: block;
  }
  #header > .center-wide-ctn {
    margin-top: 0;
  }
  #header > .center-wide-ctn .wrapper-language {
    display: none;
  }
  #header > .center-wide-ctn:after {
    content: none;
  }
  #header.show-mobile-navigation {
    position: fixed;
    height: 100%;
    overflow-y: auto;
    background: #fbfeff;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: height 0s linear 0s;
    -moz-transition: height 0s linear 0s;
    -ms-transition: height 0s linear 0s;
    -o-transition: height 0s linear 0s;
    transition: height 0s linear 0s;
  }
  #header.show-mobile-navigation .fixed-header {
    position: relative;
  }
  #header.show-mobile-navigation .fixed-header:after {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  }
  #header.show-mobile-navigation .wrapper-navigation.wrapper-navigation-top {
    height: auto;
  }
  #header.show-mobile-navigation .wrapper-navigation.wrapper-navigation-top nav > ul {
    pointer-events: auto;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
    -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -moz-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -ms-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -o-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  }
  #header.show-mobile-navigation .wrapper-navigation.wrapper-navigation-top nav:before {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
    -ms-filter: alpha(opacity=100) !important;
  }
  #header.show-mobile-navigation .wrapper-navigation.wrapper-navigation-bottom nav > ul {
    pointer-events: auto;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
    -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -moz-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -ms-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -o-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  }
  #header.show-mobile-navigation .wrapper-burger div:nth-child(1):after {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: perspective(0) rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: perspective(0) rotateZ(-45deg);
    -o-transform: perspective(0) rotateZ(-45deg);
    transform: perspective(0) rotateZ(-45deg);
  }
  #header.show-mobile-navigation .wrapper-burger div:nth-child(2):after {
    width: 0;
  }
  #header.show-mobile-navigation .wrapper-burger div:nth-child(3):after {
    width: 0;
  }
  #header.show-mobile-navigation .wrapper-burger div:nth-child(4):after {
    top: -1px;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: perspective(0) rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: perspective(0) rotateZ(45deg);
    -o-transform: perspective(0) rotateZ(45deg);
    transform: perspective(0) rotateZ(45deg);
  }
  #header.show-mobile-navigation:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: alpha(opacity=100);
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  #header.closing-mobile-navigation {
    position: fixed;
    height: 100%;
  }
  #header.closing-mobile-navigation .fixed-header {
    position: relative;
  }
  #header.closing-mobile-navigation .fixed-header:after {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  }
  #header.closing-mobile-navigation .wrapper-navigation.wrapper-navigation-top {
    height: auto;
  }
  body:not(.window-scroll-top-0) #header .wrapper-navigation.wrapper-navigation-top nav:before {
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
  }
  body.sidebar-badges-is-visible #header.show-mobile-navigation {
    height: calc(100% - 52px);
  }
}
@media screen and (max-width: 700px) {
  #header .wrapper-language {
    left: 20px !important;
  }
  #header .wrapper-burger {
    right: 10px;
  }
}
@media screen and (max-width: 400px) {
  #header .wrapper-navigation.wrapper-navigation-top .logo {
    top: 4px;
  }
  #header .wrapper-navigation.wrapper-navigation-top .logo img {
    width: 180px;
  }
}
@media screen and (max-width: 350px) {
  #header .wrapper-navigation.wrapper-navigation-top .logo {
    top: 6px;
  }
  #header .wrapper-navigation.wrapper-navigation-top .logo img {
    width: 140px;
  }
}
#hero {
  position: relative;
}
#hero h2 {
  font-size: 50px;
  line-height: 1.1em;
}
#hero .section-content {
  display: none;
  position: relative;
}
#hero .section-content .clm-wrapper .clm .mixin-article .pagination {
  position: absolute;
  bottom: -20px;
  right: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
  font-family: "Museo Slab", sans-serif;
}
#hero .section-content .clm-wrapper .clm:last-child {
  position: relative;
}
#hero .section-content .clm-wrapper .clm:last-child .mixin-image-wrapper {
  position: absolute;
  top: -140px;
  left: 1px;
  width: calc(50vw - 1px);
  height: calc(100% + 380px);
  -webkit-transition: height 0.3s ease-out 0s;
  -moz-transition: height 0.3s ease-out 0s;
  -ms-transition: height 0.3s ease-out 0s;
  -o-transition: height 0.3s ease-out 0s;
  transition: height 0.3s ease-out 0s;
}
#hero .section-content .clm-wrapper .clm:last-child .mixin-image-wrapper img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
#hero .section-content .clm-wrapper .clm:last-child .mixin-image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 200px;
  height: 100%;
  background: -webkit-linear-gradient(left, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -moz-linear-gradient(left, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(left, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(left, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(left, #42bbef 0, rgba(0, 0, 0, 0) 100%);
}
#hero .section-content .clm-wrapper .clm:last-child .mixin-image-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200px;
  background: -webkit-linear-gradient(top, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -moz-linear-gradient(top, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, #42bbef 0, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(top, #42bbef 0, rgba(0, 0, 0, 0) 100%);
}
#hero .section-content:first-child {
  display: block;
}
#hero .image-mask {
  position: absolute;
  left: 0;
  bottom: -300px;
  z-index: 1;
  width: 100%;
  height: 300px;
  background: #fbfeff;
  transform: skewY(-7deg);
}
#hero .image-deco {
  position: absolute;
  left: calc(50% - 100px);
  bottom: 0;
  z-index: 1;
  width: calc(50% + 100px);
  height: 100px;
  background: rgba(3, 127, 170, 0.15);
  transform: skewY(-7deg);
}
#hero .royalSlider {
  margin-bottom: 120px;
  width: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
#hero .royalSlider > * {
  float: none;
}
#hero .royalSlider .rsOverflow {
  overflow: visible;
}
#hero .royalSlider .rsOverflow .rsContainer {
  overflow: visible;
}
#hero .royalSlider .rsOverflow .rsContainer .rsSlide {
  overflow: visible;
}
#hero .royalSlider .rsOverflow .rsContainer .rsSlide .section-content {
  margin-bottom: 0;
}
#hero .royalSlider .rsOverflow .rsArrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
}
#hero .royalSlider .rsOverflow .rsArrow .rsArrowIcn {
  position: relative;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(0 -50%);
  -moz-transform: translate(0 -50%);
  -ms-transform: translate(0 -50%);
  -o-transform: translate(0 -50%);
  transform: translate(0 -50%);
}
#hero .royalSlider .rsOverflow .rsArrow .rsArrowIcn .icon {
  color: #fff;
  font-size: 50px;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#hero .royalSlider .rsOverflow .rsArrow.rsArrowLeft {
  left: 0;
}
#hero .royalSlider .rsOverflow .rsArrow.rsArrowRight {
  right: 0;
}
#hero .royalSlider .rsOverflow .rsArrow:hover .rsArrowIcn .icon {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#hero .royalSlider:not(.rsAutoHeight) {
  height: auto !important;
}
#hero .royalSlider.rsAutoHeight {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 0.3s;
  -moz-transition: opacity 0.3s ease-out 0.3s;
  -ms-transition: opacity 0.3s ease-out 0.3s;
  -o-transition: opacity 0.3s ease-out 0.3s;
  transition: opacity 0.3s ease-out 0.3s;
}
#hero .royalSlider:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: -150px;
  left: 0;
  right: 0;
  z-index: -1;
  background: #42bbef;
}

html.isBot #hero .royalSlider:not(.rsAutoHeight) {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

@media screen and (max-width: 1200px) {
  #hero .royalSlider .rsOverflow .rsArrow {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  #hero .section-content .clm-wrapper .clm .mixin-article .pagination {
    bottom: auto;
    top: 0;
  }
  #hero .section-content .clm-wrapper .clm:last-child {
    display: none;
  }
  #hero .image-deco {
    bottom: -50px;
  }
  #hero .royalSlider {
    height: auto !important;
  }
}
@media screen and (max-width: 500px) {
  #hero h2 {
    font-size: 40px;
  }
}
#main {
  width: 100%;
}

#pricing-plans .clm-wrapper .clm {
  max-width: 600px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper {
  z-index: 0;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge {
  display: block;
  position: relative;
  margin: -40px -30px 40px -30px;
  padding: 20px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .text {
  display: block;
  color: #fbfeff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 50%;
  z-index: 1;
  color: #fbfeff;
  font-size: 150px;
  overflow: hidden;
  opacity: 0.1;
  filter: alpha(opacity=10);
  -ms-filter: alpha(opacity=10);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon.icon-percentage:before {
  top: -50%;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon.icon-smiley {
  font-size: 200px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon.icon-smiley:before {
  top: calc(-50% - 25px);
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon.icon-infinite {
  font-size: 200px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .badge .icon.icon-infinite:before {
  top: calc(-50% - 18px);
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper {
  position: relative;
  z-index: 1;
  margin: -40px -30px;
  padding: 40px 30px;
  overflow: hidden;
  background: #fbfeff;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article {
  text-align: center;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article p {
  position: relative;
  z-index: 1;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article p.mini {
  color: #b6c1c4;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper {
  position: relative;
  margin: 30px 0;
  height: 150px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle {
  display: inline-block;
  position: relative;
  z-index: 2;
  padding-top: 40px;
  width: 150px;
  height: 150px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle span {
  position: relative;
  z-index: 1;
  display: block;
  color: #fbfeff;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle span.price {
  font-size: 26px;
  font-weight: 500;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle span.period {
  font-size: 14px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle:before, #pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle:before {
  width: 170px;
  height: 170px;
  background: #42bbef;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper .circle:after {
  width: 100%;
  height: 100%;
  background: #037faa;
  border: 3px solid #fbfeff;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper:before, #pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper:after {
  content: "";
  position: absolute;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper:before {
  top: 50%;
  left: -30px;
  z-index: 2;
  right: -30px;
  height: 7px;
  background: #42bbef;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .circle-wrapper:after {
  top: 50%;
  left: -30px;
  right: -30px;
  bottom: -1000px;
  background: #f3f6f7;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .card-logos-wrapper {
  margin: 20px 0 10px 0;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .card-logos-wrapper .card-logos {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 30px;
  -moz-gap: 30px;
  -ms-gap: 30px;
  -o-gap: 30px;
  gap: 30px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .card-logos-wrapper .card-logos img {
  height: 16px;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .card-logos-wrapper .all-brands {
  position: relative;
  margin-top: 20px;
  text-align: center;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .card-logos-wrapper .all-brands span {
  color: #42bbef;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-header h2 {
  display: block;
  position: relative;
  padding-bottom: 50%;
  margin: -20px -10px 0 -10px;
  width: calc(100% + 20px);
  max-width: calc(100% + 20px);
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-header h2 span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Exo 2", sans-serif;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-header h2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("../images/assets/app/layout/background-blue-dots.png?1744355188281");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-header .subline {
  font-size: 14px;
  font-weight: 400;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-list {
  text-align: left;
}
#pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-btn {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
#pricing-plans .clm-wrapper .clm:nth-child(1) .tile-wrapper.glow-rainbow-spin:before, #pricing-plans .clm-wrapper .clm:nth-child(1) .tile-wrapper.glow-rainbow-spin:after {
  background: conic-gradient(from calc(var(--angle) + 180deg), #42bbef, #a2ecb7, #03aa9e, #a56cbb, #42bbef);
}
#pricing-plans .clm-wrapper .clm:nth-child(2) .tile-wrapper.glow-rainbow-spin:before, #pricing-plans .clm-wrapper .clm:nth-child(2) .tile-wrapper.glow-rainbow-spin:after {
  background: conic-gradient(from calc(var(--angle) + 0deg), #42bbef, #a2ecb7, #03aa9e, #a56cbb, #42bbef);
}
#pricing-plans .clm-wrapper .clm:nth-child(3) .tile-wrapper.glow-rainbow-spin:before, #pricing-plans .clm-wrapper .clm:nth-child(3) .tile-wrapper.glow-rainbow-spin:after {
  background: conic-gradient(from calc(var(--angle) + 60deg), #42bbef, #a2ecb7, #03aa9e, #a56cbb, #42bbef);
}

@media screen and (max-width: 400px) {
  #pricing-plans .clm-wrapper .clm .tile-wrapper .badge {
    margin-top: -25px;
    margin-bottom: 25px;
  }
  #pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper {
    margin: -25px -15px;
    padding: 25px 15px;
  }
  #pricing-plans .clm-wrapper .clm .tile-wrapper .article-wrapper .mixin-article .mixin-article-header h2 {
    margin: -10px 0 0 0;
    width: 100%;
    max-width: 100%;
  }
}
#sidebar {
  position: fixed;
  z-index: 200000;
  top: 0;
  bottom: 0;
  right: -500px;
  width: 500px;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#sidebar .badges {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: absolute;
  top: 50%;
  right: calc(100% - 60px);
  z-index: 1;
  -webkit-gap: 2px;
  -moz-gap: 2px;
  -ms-gap: 2px;
  -o-gap: 2px;
  gap: 2px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 3s;
  -moz-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 3s;
  -ms-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 3s;
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 3s;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 3s;
}
#sidebar .badges .badge {
  display: flex;
  position: relative;
  height: 48px;
  cursor: pointer;
  background: #005e7d;
}
#sidebar .badges .badge a {
  display: flex;
  width: 100%;
  height: 100%;
}
#sidebar .badges .badge .unread {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
  width: 26px;
  height: 26px;
  color: #fbfeff;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  background: #a56cbb;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  font-family: "Museo Slab", sans-serif;
  -webkit-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#sidebar .badges .badge .icon {
  color: #fbfeff;
  width: 48px;
  height: 100%;
  font-size: 25px;
  line-height: 48px;
  background: #005e7d;
  -webkit-flex: 0 0 48px;
  -moz-flex: 0 0 48px;
  -ms-flex: 0 0 48px;
  -o-flex: 0 0 48px;
  flex: 0 0 48px;
  -webkit-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: background 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#sidebar .badges .badge .info {
  max-width: 0;
  height: 48px;
  font-size: 0;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -moz-transition: all 0.8s ease 0s;
  -ms-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}
#sidebar .badges .badge .info .text {
  padding: 0 15px;
  color: #fbfeff;
  font-size: 16px;
  line-height: 48px;
  white-space: nowrap;
  font-family: "Exo 2", sans-serif;
}
#sidebar .badges .badge:hover:not(.no-css-hover) .unread {
  background: #005e7d;
  -webkit-transition: background 0.3s ease 0s;
  -moz-transition: background 0.3s ease 0s;
  -ms-transition: background 0.3s ease 0s;
  -o-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
}
#sidebar .badges .badge:hover:not(.no-css-hover) .icon {
  background: #42bbef;
  -webkit-transition: background 0.3s ease 0s;
  -moz-transition: background 0.3s ease 0s;
  -ms-transition: background 0.3s ease 0s;
  -o-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
}
#sidebar .badges .badge:hover:not(.no-css-hover) .info {
  max-width: 300px;
  -webkit-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#sidebar .assistant {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  background: #fbfeff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  -webkit-transition: box-shadow 0.4s ease-out 0s;
  -moz-transition: box-shadow 0.4s ease-out 0s;
  -ms-transition: box-shadow 0.4s ease-out 0s;
  -o-transition: box-shadow 0.4s ease-out 0s;
  transition: box-shadow 0.4s ease-out 0s;
}
#sidebar .assistant > header {
  display: flex;
  width: 100%;
  padding: 13px 20px;
  background: #037faa;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#sidebar .assistant > header > .back,
#sidebar .assistant > header > .back-placeholder,
#sidebar .assistant > header > .close {
  display: flex;
  height: 40px;
  align-items: center;
  -webkit-flex: 0 0 40px;
  -moz-flex: 0 0 40px;
  -ms-flex: 0 0 40px;
  -o-flex: 0 0 40px;
  flex: 0 0 40px;
}
#sidebar .assistant > header > .back .icon,
#sidebar .assistant > header > .back-placeholder .icon,
#sidebar .assistant > header > .close .icon {
  color: #fbfeff;
  font-size: 16px;
}
#sidebar .assistant > header > .back.back,
#sidebar .assistant > header > .back-placeholder.back,
#sidebar .assistant > header > .close.back {
  cursor: pointer;
}
#sidebar .assistant > header > .back.close,
#sidebar .assistant > header > .back-placeholder.close,
#sidebar .assistant > header > .close.close {
  justify-content: right;
  cursor: pointer;
}
#sidebar .assistant > header > .back.close .icon:last-child,
#sidebar .assistant > header > .back-placeholder.close .icon:last-child,
#sidebar .assistant > header > .close.close .icon:last-child {
  margin-left: -5px;
}
#sidebar .assistant > header > .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 0;
  text-align: center;
  line-height: 1em;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > header > .title .headline {
  display: block;
  color: #fbfeff;
  font-weight: 400;
  line-height: 22px;
  font-family: "Exo 2", sans-serif;
}
#sidebar .assistant > header > .title .subline {
  color: #aac3cd;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Exo 2", sans-serif;
}
#sidebar .assistant > header > .title .subline span {
  color: inherit;
  font: inherit;
  line-height: inherit;
}
#sidebar .assistant > header > .title [data-overlay-url]:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#sidebar .assistant > .translator {
  padding: 20px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
}
#sidebar .assistant > .messenger {
  width: 100%;
  padding: 20px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#sidebar .assistant > .messenger textarea {
  height: 100px;
}
#sidebar .assistant > .conversations {
  padding: 10px 20px;
  overflow-x: hidden;
  overflow-x: auto;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > .conversations .conversation {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
  cursor: pointer;
}
#sidebar .assistant > .conversations .conversation .teaser {
  display: flex;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 20px;
  -moz-gap: 0 20px;
  -ms-gap: 0 20px;
  -o-gap: 0 20px;
  gap: 0 20px;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > .conversations .conversation .teaser .line {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 1 0 100%;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  -o-flex: 1 0 100%;
  flex: 1 0 100%;
}
#sidebar .assistant > .conversations .conversation .teaser .line .reference {
  color: #b6c1c4;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#sidebar .assistant > .conversations .conversation .teaser .line .reference:nth-child(2) {
  padding: 0 10px;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
#sidebar .assistant > .conversations .conversation .teaser .line .date {
  color: #b6c1c4;
  font-size: 14px;
  text-align: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
#sidebar .assistant > .conversations .conversation .teaser .line .title {
  color: #037faa;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-flex: 1 1;
  -moz-flex: 1 1;
  -ms-flex: 1 1;
  -o-flex: 1 1;
  flex: 1 1;
}
#sidebar .assistant > .conversations .conversation .teaser .line .title.customer-service {
  color: #42bbef;
}
#sidebar .assistant > .conversations .conversation .teaser .line .text {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-flex: 1 0 100%;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  -o-flex: 1 0 100%;
  flex: 1 0 100%;
}
#sidebar .assistant > .conversations .conversation .teaser .line .link {
  color: #b6c1c4;
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
#sidebar .assistant > .conversations .conversation .info {
  position: relative;
  text-align: right;
  -webkit-flex: 0 0 40px;
  -moz-flex: 0 0 40px;
  -ms-flex: 0 0 40px;
  -o-flex: 0 0 40px;
  flex: 0 0 40px;
}
#sidebar .assistant > .conversations .conversation .info .icon {
  color: #037faa;
}
#sidebar .assistant > .conversations .conversation .info .unread {
  display: inline-block;
  width: 26px;
  height: 26px;
  color: #fbfeff;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  background: #a56cbb;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  font-family: "Museo Slab", sans-serif;
}
#sidebar .assistant > .conversations .conversation:last-child {
  margin-bottom: 0;
}
#sidebar .assistant > .conversations .conversation:hover:not(.no-css-hover):before {
  content: "";
  position: absolute;
  z-index: 0;
  top: -11px;
  bottom: -1px;
  left: -20px;
  right: -20px;
  background: rgba(3, 127, 170, 0.05);
}
#sidebar .assistant > .conversations .conversation:hover:not(.no-css-hover):after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -11px;
  bottom: -1px;
  left: -20px;
  width: 5px;
  background: #a56cbb;
}
#sidebar .assistant > .conversation {
  width: calc(100% - 20px);
  margin-left: 20px;
  overflow-x: hidden;
  overflow-x: auto;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > .conversation > .message {
  display: block;
}
#sidebar .assistant > .conversation > .message > .date {
  display: block;
  margin: 15px 20px 15px 0;
  color: #b6c1c4;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
#sidebar .assistant > .conversation > .message > .text {
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  hyphens: auto;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}
#sidebar .assistant > .conversation > .message > .info {
  display: flex;
  margin: 3px 25px 0 5px;
}
#sidebar .assistant > .conversation > .message > .info .time {
  position: relative;
  top: 2px;
  padding: 2px 0 15px 0;
  color: #b6c1c4;
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
}
#sidebar .assistant > .conversation > .message > .info .status {
  display: flex;
  margin-left: 10px;
  padding: 2px 0 15px 0;
  line-height: 1em;
}
#sidebar .assistant > .conversation > .message > .info .status .icon {
  top: 1px;
  color: #42bbef;
  font-size: 13px;
}
#sidebar .assistant > .conversation > .message.other > .text {
  margin-right: 60px;
  background: #f3f6f7;
}
#sidebar .assistant > .conversation > .message.own {
  text-align: right;
}
#sidebar .assistant > .conversation > .message.own > .text {
  margin: 0 20px 0 60px;
  color: #fbfeff;
  text-align: left;
  background: #42bbef;
}
#sidebar .assistant > .conversation > .message.own > .info {
  justify-content: right;
}
#sidebar .assistant > .conversation > .info {
  display: flex;
  flex-direction: column;
  margin: 40px 20px 20px 0;
  -webkit-gap: 10px 0;
  -moz-gap: 10px 0;
  -ms-gap: 10px 0;
  -o-gap: 10px 0;
  gap: 10px 0;
}
#sidebar .assistant > .conversation > .info .text {
  display: block;
  color: #b6c1c4;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
#sidebar .assistant > .conversation > .info .text.link {
  color: #037faa;
  cursor: pointer;
  text-decoration: underline;
}
#sidebar .assistant > .no-conversations,
#sidebar .assistant > .no-messages {
  display: flex;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
  overflow-x: auto;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > .no-conversations p,
#sidebar .assistant > .no-messages p {
  color: #b6c1c4;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#sidebar .assistant > .loading {
  position: relative;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
#sidebar .assistant > .loading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background-image: url("../images/assets/app/layout/loading.svg?1744355188281");
  background-size: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#sidebar .assistant > .error {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
#sidebar .assistant > .error .icon {
  margin-bottom: 30px;
  font-size: 60px;
}
#sidebar .assistant > .error h3 {
  margin: 0 50px 20px 50px;
  font-size: 30px !important;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
#sidebar .assistant > .background {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: #000;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s ease-out 0s;
  -moz-transition: opacity 0.4s ease-out 0s;
  -ms-transition: opacity 0.4s ease-out 0s;
  -o-transition: opacity 0.4s ease-out 0s;
  transition: opacity 0.4s ease-out 0s;
}
#sidebar.show {
  right: 0;
}
#sidebar.show .badges {
  -webkit-transition: right 0s linear 0s;
  -moz-transition: right 0s linear 0s;
  -ms-transition: right 0s linear 0s;
  -o-transition: right 0s linear 0s;
  transition: right 0s linear 0s;
}
#sidebar.show .assistant {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
#sidebar.show .assistant .background {
  pointer-events: auto;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#sidebar.show-badges .badges {
  right: 502px;
}

@media screen and (max-width: 1000px) {
  #sidebar {
    position: absolute;
  }
  #sidebar .badges {
    position: fixed;
    bottom: -50px;
    top: auto;
    left: 50%;
    right: auto !important;
    flex-direction: row;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #sidebar .badges .badge {
    background: #fbfeff !important;
    -webkit-flex: 0 0 48px;
    -moz-flex: 0 0 48px;
    -ms-flex: 0 0 48px;
    -o-flex: 0 0 48px;
    flex: 0 0 48px;
  }
  #sidebar .badges .badge .unread {
    background: #a56cbb !important;
  }
  #sidebar .badges .badge .icon {
    color: #005e7d;
    background: #fbfeff !important;
  }
  #sidebar .badges .badge .info {
    width: 0;
  }
  #sidebar .badges:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -50vw;
    right: -50vw;
    z-index: -1;
    height: 52px;
    background: #005e7d;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
  #sidebar .assistant {
    height: calc(100% - 52px);
  }
  #sidebar.show, #sidebar.showing-sidebar, #sidebar.closing-sidebar {
    position: fixed;
  }
  body.body-loaded #sidebar .badges {
    bottom: 2px;
  }
}
#slideup {
  position: relative;
  padding-top: 20px;
}
#slideup .links {
  justify-content: center;
}
#slideup .links .label {
  font-size: 16px;
  font-family: "Exo 2", sans-serif;
}
#slideup .links .label.active, #slideup .links .label:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#slideup .contents {
  position: relative;
}
#slideup .contents .content {
  padding: 60px 0 20px 0;
}
#slideup .contents .content h4 {
  margin: 30px 0 10px 0;
  color: #037faa;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: "Museo Slab", sans-serif;
}
#slideup .contents .content h5 {
  margin: 20px 0 10px 0;
  color: #037faa;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3em;
  text-transform: uppercase;
}
#slideup .contents .content p {
  margin-bottom: 10px;
  font-size: 14px;
}
#slideup .contents .content a {
  color: #42bbef;
  font-size: 14px;
}
#slideup .contents .content a:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#slideup .contents .content ul {
  margin-bottom: 10px;
}
#slideup .contents .content ul li {
  position: relative;
  padding-left: 20px;
  font-size: 14px;
}
#slideup .contents .content ul li:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #628290;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
#slideup .contents .close {
  top: 30px;
  padding: 10px 15px;
  color: #037faa;
  font-size: 16px;
  line-height: 1em;
  text-transform: uppercase;
  border: 1px solid #037faa;
  cursor: pointer;
  font-family: "Exo 2", sans-serif;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -ms-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
#slideup:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  width: 200vw;
  height: 1px;
  background: linear-gradient(90deg, rgba(3, 127, 170, 0.15), rgba(3, 127, 170, 0.15) 50%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
  background-size: 8px 1px;
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  #slideup .contents .content h4 {
    font-weight: 600;
  }
  #slideup .contents .content h5 {
    font-weight: 700;
  }
  #slideup .contents .close {
    font-weight: 500;
  }
}
#splasher .wrapper-section-content {
  z-index: 2;
}
#splasher .image-wrapper {
  position: absolute;
  top: 0;
  bottom: -10px;
  right: 50%;
  width: 1240px;
  background-repeat: no-repeat;
  background-position: left bottom;
  pointer-events: none;
  -webkit-transform: translate(23%, 0);
  -moz-transform: translate(23%, 0);
  -ms-transform: translate(23%, 0);
  -o-transform: translate(23%, 0);
  transform: translate(23%, 0);
}
#splasher .background-wrapper {
  position: absolute;
  top: 0;
  bottom: -20px;
  right: -30px;
  width: 750px;
  background-repeat: no-repeat;
  background-position: right bottom;
  pointer-events: none;
}

@media screen and (max-width: 1400px) {
  #splasher .background-wrapper {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  #splasher .image-wrapper {
    display: none;
  }
}
/* == PROJECT / APP / MODULES / INTERFACES ====================================================== */
[id^=interface-dashboard-areas-] .flex-wrapper .flex-item {
  -webkit-flex: 1 0 180px;
  -moz-flex: 1 0 180px;
  -ms-flex: 1 0 180px;
  -o-flex: 1 0 180px;
  flex: 1 0 180px;
}

#interface-dashboard-areas .flex-wrapper .flex-item {
  -webkit-flex: 1 0 350px;
  -moz-flex: 1 0 350px;
  -ms-flex: 1 0 350px;
  -o-flex: 1 0 350px;
  flex: 1 0 350px;
}

@media screen and (max-width: 500px) {
  #interface-dashboard-areas .flex-wrapper .flex-item {
    -webkit-flex: 1 0 210px;
    -moz-flex: 1 0 210px;
    -ms-flex: 1 0 210px;
    -o-flex: 1 0 210px;
    flex: 1 0 210px;
  }
}
#interface-dashboard-footer {
  display: flex;
  background: #f3f6f7;
}
#interface-dashboard-footer ul {
  padding: 5px 25px;
  font-size: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#interface-dashboard-footer ul li {
  display: inline-block;
  padding-right: 25px;
  font-size: 0;
}
#interface-dashboard-footer ul li a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-decoration: underline;
}
#interface-dashboard-footer ul li:last-child {
  padding-right: 0;
}
#interface-dashboard-footer .logo {
  padding: 13px 25px 13px 0;
  font-size: 0;
  text-align: right;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard-footer .logo img {
  height: 14px;
}

@media screen and (max-width: 500px) {
  #interface-dashboard-footer ul {
    display: none;
  }
}
#interface-dashboard-reports form {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.05);
}
#interface-dashboard-reports form .flex-wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
#interface-dashboard-reports form .flex-wrapper .flex-item {
  overflow: hidden;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard-reports form .flex-wrapper .flex-item select {
  min-width: 230px;
}
#interface-dashboard-reports form .flex-wrapper .flex-item .mixin-btn {
  padding-top: 10px;
}
#interface-dashboard-reports form .flex-wrapper .flex-item .mixin-btn .btn {
  width: 100%;
}

#interface-dashboard-settings form {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.05);
}
#interface-dashboard-settings form .flex-wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
#interface-dashboard-settings form .flex-wrapper .flex-item {
  overflow: hidden;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard-settings form .flex-wrapper .flex-item .mixin-btn {
  padding-top: 0;
}
#interface-dashboard-settings form .flex-wrapper .flex-item .mixin-btn .btn {
  width: 100%;
}

@media screen and (max-width: 500px) {
  #interface-dashboard-settings form .flex-wrapper .flex-item {
    -webkit-flex: 1 1 100% !important;
    -moz-flex: 1 1 100% !important;
    -ms-flex: 1 1 100% !important;
    -o-flex: 1 1 100% !important;
    flex: 1 1 100% !important;
  }
}
#interface-dashboard-users .flex-wrapper .flex-item {
  -webkit-flex: 1 0 210px;
  -moz-flex: 1 0 210px;
  -ms-flex: 1 0 210px;
  -o-flex: 1 0 210px;
  flex: 1 0 210px;
}

#interface-dashboard {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  overflow-x: hidden;
  -webkit-flex: 1 1 0;
  -moz-flex: 1 1 0;
  -ms-flex: 1 1 0;
  -o-flex: 1 1 0;
  flex: 1 1 0;
}
#interface-dashboard section[id^=interface-dashboard] {
  display: none;
  position: relative;
  z-index: 1;
  padding: 20px;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard section[id^=interface-dashboard] > header {
  display: flex;
  margin: -20px -20px 20px -20px;
  padding: 0 20px;
  font-size: 0;
  background: #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > header > h2 {
  color: #f3f6f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
  font-family: "Open Sans", sans-serif;
}
#interface-dashboard section[id^=interface-dashboard] > header > h2 .text {
  margin-right: 15px;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
#interface-dashboard section[id^=interface-dashboard] > header > h2 .text[data-dashboard-id] {
  text-decoration: underline;
}
#interface-dashboard section[id^=interface-dashboard] > header > h2 .text:last-child {
  margin-right: 0;
}
#interface-dashboard section[id^=interface-dashboard] > header > h2 .icon {
  margin-right: 15px;
  color: inherit;
  font-size: 14px;
  line-height: inherit;
}
#interface-dashboard section[id^=interface-dashboard] > header > ul {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#interface-dashboard section[id^=interface-dashboard] > header > ul li {
  margin-right: -10px;
  padding: 0 10px;
  font-size: 0;
}
#interface-dashboard section[id^=interface-dashboard] > header > ul li .icon {
  color: #f3f6f7;
  font-size: 20px;
  line-height: 50px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item {
  display: flex;
  position: relative;
  height: 50px;
  font-size: 0;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  background: #fbfeff;
  border: 1px solid #628290;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text {
  font-size: 14px;
  line-height: 18px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text.number {
  margin: -3px 10px -3px 0;
  padding: 3px 7px;
  color: #f3f6f7;
  font-weight: 600;
  background: #037faa;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text.name {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text.border {
  margin: -4px 0 -4px 10px;
  padding: 3px 7px;
  color: #037faa;
  font-weight: 600;
  border: 1px solid #037faa;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text.border .icon {
  margin-left: 10px;
  color: #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text.placeholder {
  font-size: 0;
  pointer-events: none;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text .icon {
  margin-left: 15px;
  font-size: 16px;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item .text .icon.icon-checkmark {
  color: #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-blue {
  border: 1px solid #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-blue .text .icon {
  color: #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-blue .text.number {
  background: #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-blue .text.border {
  color: #037faa;
  border: 1px solid #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-blue:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #037faa;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-green {
  border: 1px solid #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-green .text .icon {
  color: #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-green .text.number {
  background: #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-green .text.border {
  color: #03aa9e;
  border: 1px solid #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-green:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #03aa9e;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-purple {
  border: 1px solid #a56cbb;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-purple .text .icon {
  color: #a56cbb;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-purple .text.number {
  background: #a56cbb;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-purple .text.border {
  color: #a56cbb;
  border: 1px solid #a56cbb;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-purple:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #a56cbb;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-orange {
  border: 1px solid #f59513;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-orange .text .icon {
  color: #f59513;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-orange .text.number {
  background: #f59513;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-orange .text.border {
  color: #f59513;
  border: 1px solid #f59513;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-orange:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #f59513;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-red {
  border: 1px solid #e2430f;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-red .text .icon {
  color: #e2430f;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-red .text.number {
  background: #e2430f;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-red .text.border {
  color: #e2430f;
  border: 1px solid #e2430f;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.highlight-red:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #e2430f;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out {
  border: 1px solid #b6c1c4;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out .text.number {
  background: #b6c1c4;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out .text.border {
  color: #b6c1c4;
  border: 1px solid #b6c1c4;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out .text.border .icon {
  color: #b6c1c4;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out .icon {
  color: #b6c1c4 !important;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.grey-out:before {
  background: #b6c1c4;
}
#interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.placeholder {
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
#interface-dashboard section[id^=interface-dashboard] .warning-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 180px);
  text-align: center;
}
#interface-dashboard section[id^=interface-dashboard] .warning-wrapper .icon {
  margin-bottom: 30px;
  font-size: 60px !important;
}
#interface-dashboard section[id^=interface-dashboard] .warning-wrapper p {
  margin: 0 50px 20px 50px;
  font-weight: 400;
}
#interface-dashboard section[id^=interface-dashboard].show {
  display: block;
}

@media screen and (max-width: 400px) {
  #interface-dashboard section[id^=interface-dashboard] {
    min-width: calc(100vw - 50px);
  }
  #interface-dashboard section[id^=interface-dashboard] > .flex-wrapper > .flex-item.placeholder {
    display: none;
  }
}
#interface-header {
  display: flex;
  height: 50px;
  max-width: 100%;
  overflow: hidden;
  background: #41535d;
  -webkit-flex: 0 0 50px;
  -moz-flex: 0 0 50px;
  -ms-flex: 0 0 50px;
  -o-flex: 0 0 50px;
  flex: 0 0 50px;
}
#interface-header h1 {
  padding: 0 15px;
  color: #fbfeff;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
  font-family: "Open Sans", sans-serif;
}
#interface-header .user {
  display: flex;
  padding: 0 15px;
  margin-left: auto;
  max-width: 100%;
  font-size: 0;
  justify-content: right;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}
#interface-header .user > span {
  display: flex;
  color: #fbfeff;
  font-size: 14px;
  line-height: 50px;
  -webkit-gap: 0 7px;
  -moz-gap: 0 7px;
  -ms-gap: 0 7px;
  -o-gap: 0 7px;
  gap: 0 7px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#interface-header .user > span.number {
  margin: 10px 0;
  padding: 7px;
  height: 30px;
  font-weight: 600;
  line-height: 1.2em;
  background: #03aa9e;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#interface-header .user > span.name {
  max-width: 200px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: underline;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}
#interface-header .user > span.login, #interface-header .user > span.logout {
  margin: 10px 0;
  padding: 7px;
  height: 30px;
  font-weight: 600;
  line-height: 1.2em;
  white-space: nowrap;
  background: #037faa;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#interface-header .user > span.login .icon, #interface-header .user > span.logout .icon {
  top: -1px;
  color: inherit;
}
#interface-header .user > span.icon {
  margin: 10px 0;
  padding: 5px 7px;
  height: 30px;
  font-size: 16px;
  line-height: 20px;
  background: #037faa;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

@media screen and (max-width: 500px) {
  #interface-header h1 {
    display: none;
  }
}
#interface-login-authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}
#interface-login-authentication .tile-wrapper {
  width: 400px;
  max-width: 100%;
  padding: 20px !important;
}
#interface-login-authentication .tile-wrapper form {
  width: 100%;
}
#interface-login-authentication .tile-wrapper form .row label {
  font-size: 16px !important;
}
#interface-login-authentication .tile-wrapper form .row label span {
  margin: 10px 0;
}
#interface-login-authentication .tile-wrapper form .row .logo {
  float: right;
  margin: 10px 0 20px 0;
  padding: 0 10px 0 0;
  font-size: 0;
}
#interface-login-authentication .tile-wrapper form .row .logo img {
  height: 14px;
}
#interface-login-authentication .tile-wrapper form .row .mixin-form-number-pad {
  margin: 20px 0;
}

#interface-login-email-password {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}
#interface-login-email-password .tile-wrapper {
  width: 400px;
  max-width: 100%;
  padding: 20px !important;
}
#interface-login-email-password .tile-wrapper form {
  width: 100%;
}
#interface-login-email-password .tile-wrapper form .row label {
  font-size: 16px !important;
}
#interface-login-email-password .tile-wrapper form .row .logo {
  float: right;
  margin: 10px 0 20px 0;
  padding: 0 10px 0 0;
  font-size: 0;
}
#interface-login-email-password .tile-wrapper form .row .logo img {
  height: 14px;
}
#interface-login-email-password .tile-wrapper form .row .legal {
  margin-top: 10px;
}

#interface-navigation {
  display: flex;
  position: relative;
  background: #099bda;
  -webkit-flex: 0 0 0;
  -moz-flex: 0 0 0;
  -ms-flex: 0 0 0;
  -o-flex: 0 0 0;
  flex: 0 0 0;
}
#interface-navigation nav ul {
  margin-top: 50px;
  font-size: 0;
  overflow: hidden;
  -webkit-transition: width 0.3s ease-out 0s;
  -moz-transition: width 0.3s ease-out 0s;
  -ms-transition: width 0.3s ease-out 0s;
  -o-transition: width 0.3s ease-out 0s;
  transition: width 0.3s ease-out 0s;
}
#interface-navigation nav ul li {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#interface-navigation nav ul li .icon {
  top: 9px;
  width: 20px;
  margin: 0 15px;
  color: #f3f6f7;
  font-size: 20px;
}
#interface-navigation nav ul li .text {
  display: inline-block;
  padding: 10px 20px 10px 0;
  color: #f3f6f7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  -webkit-transition: opacity 0.3s ease-out 0.3s;
  -moz-transition: opacity 0.3s ease-out 0.3s;
  -ms-transition: opacity 0.3s ease-out 0.3s;
  -o-transition: opacity 0.3s ease-out 0.3s;
  transition: opacity 0.3s ease-out 0.3s;
}
#interface-navigation nav ul li.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#interface-navigation nav ul li.active:before {
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: rgba(0, 0, 0, 0) #fbfeff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
#interface-navigation nav ul li.imprint, #interface-navigation nav ul li.privacy {
  display: none;
}
#interface-navigation .enwrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding: 13px 15px;
  font-size: 0;
  text-align: right;
  background: #037faa;
  cursor: pointer;
}
#interface-navigation .enwrap .icon {
  color: #f3f6f7;
  font-size: 14px;
}
#interface-navigation .enwrap .icon.icon-arrows-right {
  display: none;
}
#interface-navigation.enwraped nav ul {
  width: 50px !important;
}
#interface-navigation.enwraped nav ul li .text {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#interface-navigation.enwraped .enwrap .icon.icon-arrows-left {
  display: none;
}
#interface-navigation.enwraped .enwrap .icon.icon-arrows-right {
  display: inline-block;
}

@media screen and (max-width: 500px) {
  #interface-navigation nav ul li.imprint, #interface-navigation nav ul li.privacy {
    display: block;
  }
}
/* == PROJECT / APP / MODULES / OVERLAYS ====================================================== */
#accounts-overlay-confirm {
  width: 600px;
}

#accounts-overlay-forgot-password {
  width: 400px;
}

#accounts-overlay-logged-in-with-user {
  width: 600px;
}

#accounts-overlay-login {
  width: 400px;
}

#accounts-overlay-order,
#interfaces-overlay-order {
  width: 450px;
}
#accounts-overlay-order .key-value,
#interfaces-overlay-order .key-value {
  padding-right: 35px;
}
#accounts-overlay-order .key-value .clm,
#interfaces-overlay-order .key-value .clm {
  position: relative;
}
#accounts-overlay-order .key-value .clm .icon,
#interfaces-overlay-order .key-value .clm .icon {
  position: absolute;
  left: auto;
  right: -35px;
}
#accounts-overlay-order .key-value.flex-wrapper,
#interfaces-overlay-order .key-value.flex-wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item {
  position: relative;
  -webkit-flex: 1 1;
  -moz-flex: 1 1;
  -ms-flex: 1 1;
  -o-flex: 1 1;
  flex: 1 1;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item form,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item form {
  margin: -5px 0;
  width: 100%;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item .icon,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item .icon {
  position: absolute;
  top: 0;
  left: auto;
  right: -35px;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item:first-child,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item:first-child {
  overflow: hidden;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item:last-child,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item:last-child {
  justify-content: right;
}
#accounts-overlay-order .key-value.flex-wrapper .flex-item:last-child p,
#interfaces-overlay-order .key-value.flex-wrapper .flex-item:last-child p {
  white-space: nowrap;
}
#accounts-overlay-order ul.courses,
#interfaces-overlay-order ul.courses {
  font-size: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #aac3cd;
}
#accounts-overlay-order ul.courses > li h5,
#interfaces-overlay-order ul.courses > li h5 {
  margin: 5px 0;
  padding: 5px 0;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #aac3cd;
  border-bottom: 1px solid #aac3cd;
}
#accounts-overlay-order ul.courses > li ul.items,
#interfaces-overlay-order ul.courses > li ul.items {
  font-size: 0;
}
#accounts-overlay-order ul.courses > li ul.items > li,
#interfaces-overlay-order ul.courses > li ul.items > li {
  position: relative;
  padding-right: 60px;
  font-size: 0;
}
#accounts-overlay-order ul.courses > li ul.items > li > span,
#interfaces-overlay-order ul.courses > li ul.items > li > span {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
#accounts-overlay-order ul.courses > li ul.items > li > span.amount,
#interfaces-overlay-order ul.courses > li ul.items > li > span.amount {
  position: absolute;
  top: 0;
  left: 0;
  font-variant: tabular-nums;
}
#accounts-overlay-order ul.courses > li ul.items > li > span.label,
#interfaces-overlay-order ul.courses > li ul.items > li > span.label {
  width: 100%;
  padding-left: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
}
#accounts-overlay-order ul.courses > li ul.items > li > span.label .label-cost,
#interfaces-overlay-order ul.courses > li ul.items > li > span.label .label-cost {
  display: inline;
  font: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#accounts-overlay-order ul.courses > li ul.items > li > span.price,
#interfaces-overlay-order ul.courses > li ul.items > li > span.price {
  position: absolute;
  top: 0;
  right: 0;
}
#accounts-overlay-order ul.courses > li ul.items > li > span.preparation,
#interfaces-overlay-order ul.courses > li ul.items > li > span.preparation {
  display: block;
  margin-left: 35px;
  color: #8da3ab;
  font-size: 12px;
  font-weight: 300;
}
#accounts-overlay-order ul.courses > li ul.items > li ul.add-ons,
#interfaces-overlay-order ul.courses > li ul.items > li ul.add-ons {
  width: 100%;
  margin-left: 35px;
  font-size: 0;
}
#accounts-overlay-order ul.courses > li ul.items > li ul.add-ons li,
#interfaces-overlay-order ul.courses > li ul.items > li ul.add-ons li {
  font-size: 0;
}
#accounts-overlay-order ul.courses > li ul.items > li ul.add-ons li .label,
#interfaces-overlay-order ul.courses > li ul.items > li ul.add-ons li .label {
  color: #8da3ab;
  font-size: 12px;
}
#accounts-overlay-order ul.courses > li:first-child h5,
#interfaces-overlay-order ul.courses > li:first-child h5 {
  font-weight: 700 !important;
  border-top: none;
}
#accounts-overlay-order .subtotal span,
#accounts-overlay-order .tip span,
#accounts-overlay-order .total span,
#accounts-overlay-order .outstanding span,
#interfaces-overlay-order .subtotal span,
#interfaces-overlay-order .tip span,
#interfaces-overlay-order .total span,
#interfaces-overlay-order .outstanding span {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
#accounts-overlay-order .subtotal span.label:first-letter,
#accounts-overlay-order .tip span.label:first-letter,
#accounts-overlay-order .total span.label:first-letter,
#accounts-overlay-order .outstanding span.label:first-letter,
#interfaces-overlay-order .subtotal span.label:first-letter,
#interfaces-overlay-order .tip span.label:first-letter,
#interfaces-overlay-order .total span.label:first-letter,
#interfaces-overlay-order .outstanding span.label:first-letter {
  text-transform: capitalize;
}
#accounts-overlay-order .subtotal span.amount,
#accounts-overlay-order .tip span.amount,
#accounts-overlay-order .total span.amount,
#accounts-overlay-order .outstanding span.amount,
#interfaces-overlay-order .subtotal span.amount,
#interfaces-overlay-order .tip span.amount,
#interfaces-overlay-order .total span.amount,
#interfaces-overlay-order .outstanding span.amount {
  float: right;
  font-variant: tabular-nums;
}
#accounts-overlay-order .subtotal.subtotal,
#accounts-overlay-order .tip.subtotal,
#accounts-overlay-order .total.subtotal,
#accounts-overlay-order .outstanding.subtotal,
#interfaces-overlay-order .subtotal.subtotal,
#interfaces-overlay-order .tip.subtotal,
#interfaces-overlay-order .total.subtotal,
#interfaces-overlay-order .outstanding.subtotal {
  padding-top: 5px;
  font-size: 0;
}
#accounts-overlay-order .subtotal.tip,
#accounts-overlay-order .tip.tip,
#accounts-overlay-order .total.tip,
#accounts-overlay-order .outstanding.tip,
#interfaces-overlay-order .subtotal.tip,
#interfaces-overlay-order .tip.tip,
#interfaces-overlay-order .total.tip,
#interfaces-overlay-order .outstanding.tip {
  padding-bottom: 5px;
  font-size: 0;
  border-bottom: 1px solid #aac3cd;
}
#accounts-overlay-order .subtotal.total,
#accounts-overlay-order .tip.total,
#accounts-overlay-order .total.total,
#accounts-overlay-order .outstanding.total,
#interfaces-overlay-order .subtotal.total,
#interfaces-overlay-order .tip.total,
#interfaces-overlay-order .total.total,
#interfaces-overlay-order .outstanding.total {
  padding: 5px 0;
  font-size: 0;
  border-bottom: 1px solid #aac3cd;
}
#accounts-overlay-order .subtotal.total span,
#accounts-overlay-order .tip.total span,
#accounts-overlay-order .total.total span,
#accounts-overlay-order .outstanding.total span,
#interfaces-overlay-order .subtotal.total span,
#interfaces-overlay-order .tip.total span,
#interfaces-overlay-order .total.total span,
#interfaces-overlay-order .outstanding.total span {
  font-weight: 600;
}
#accounts-overlay-order .subtotal.outstanding,
#accounts-overlay-order .tip.outstanding,
#accounts-overlay-order .total.outstanding,
#accounts-overlay-order .outstanding.outstanding,
#interfaces-overlay-order .subtotal.outstanding,
#interfaces-overlay-order .tip.outstanding,
#interfaces-overlay-order .total.outstanding,
#interfaces-overlay-order .outstanding.outstanding {
  padding-top: 5px;
}
#accounts-overlay-order .subtotal.outstanding span,
#accounts-overlay-order .tip.outstanding span,
#accounts-overlay-order .total.outstanding span,
#accounts-overlay-order .outstanding.outstanding span,
#interfaces-overlay-order .subtotal.outstanding span,
#interfaces-overlay-order .tip.outstanding span,
#interfaces-overlay-order .total.outstanding span,
#interfaces-overlay-order .outstanding.outstanding span {
  font-weight: 600;
}

#accounts-overlay-payment-details {
  width: 450px;
}
#accounts-overlay-payment-details .clm-wrapper.key-value {
  padding-right: 35px;
}
#accounts-overlay-payment-details .clm-wrapper.key-value .clm {
  position: relative;
}
#accounts-overlay-payment-details .clm-wrapper.key-value .clm .icon {
  position: absolute;
  top: -1px;
  left: auto;
  right: -35px;
}
#accounts-overlay-payment-details ul.orders {
  margin: 10px 0 5px 0;
  padding-bottom: 5px;
  font-size: 0;
  border-bottom: 1px solid #aac3cd;
}
#accounts-overlay-payment-details ul.orders li {
  position: relative;
  margin-bottom: 5px;
  padding: 0 60px 3px 0;
  font-size: 0;
}
#accounts-overlay-payment-details ul.orders li .number,
#accounts-overlay-payment-details ul.orders li .price {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
#accounts-overlay-payment-details ul.orders li .number.number,
#accounts-overlay-payment-details ul.orders li .price.number {
  padding-right: 60px;
}
#accounts-overlay-payment-details ul.orders li .number.price,
#accounts-overlay-payment-details ul.orders li .price.price {
  position: absolute;
  top: 0;
  right: 35px;
}
#accounts-overlay-payment-details ul.orders li .number:hover:not(.no-css-hover),
#accounts-overlay-payment-details ul.orders li .price:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#accounts-overlay-payment-details ul.orders li .icon {
  position: absolute;
  top: -1px;
  right: 0;
}
#accounts-overlay-payment-details ul.orders li:last-child {
  margin-bottom: 0;
}
#accounts-overlay-payment-details .total span {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}
#accounts-overlay-payment-details .total span.label:first-letter {
  text-transform: capitalize;
}
#accounts-overlay-payment-details .total span.amount {
  float: right;
  padding-right: 35px;
  font-variant: tabular-nums;
}

#accounts-overlay-payment-method {
  width: 350px;
}

#accounts-overlay-register {
  width: 700px;
}
#accounts-overlay-register .mixin-choose .choose-buttons .clm-wrapper {
  justify-content: center;
}

#clients-overlay-categories-change-order {
  width: 400px;
}
#clients-overlay-categories-change-order form {
  margin-top: 20px;
}

#clients-overlay-menu-configure-qr-codes {
  width: 350px;
}
#clients-overlay-menu-configure-qr-codes form {
  margin-top: 20px;
}
#clients-overlay-menu-configure-qr-codes form [data-download] {
  display: none;
}

#clients-overlay-menu-confirm-languages {
  width: 600px;
}

#clients-overlay-menu-confirm-navigation-level {
  width: 600px;
}

#clients-overlay-menu-font-selector {
  width: 400px;
}

#clients-overlay-menu-image {
  max-width: calc(100vh - 122px) !important;
}
#clients-overlay-menu-image img {
  width: 100%;
}

#clients-overlay-menu-images {
  width: 1200px;
}

#clients-overlay-menu-interface-area-tables {
  width: 400px;
}

#clients-overlay-menu-interface-area {
  width: 400px;
}
#clients-overlay-menu-interface-area form {
  margin-top: 20px;
}

#clients-overlay-menu-interface-user {
  width: 400px;
}
#clients-overlay-menu-interface-user form {
  margin-top: 20px;
}

#clients-overlay-menu-interface-login-pass {
  width: 400px;
}

#clients-overlay-menu-interface-person {
  width: 400px;
}
#clients-overlay-menu-interface-person form {
  margin-top: 20px;
}

#clients-overlay-payment-account-person {
  width: 900px;
}

#clients-overlay-translate-language {
  width: 350px;
}
#clients-overlay-translate-language .wrapper-translate form {
  margin-top: 20px;
}
#clients-overlay-translate-language .wrapper-success {
  display: none;
}
#clients-overlay-translate-language.show-success {
  width: 500px;
}
#clients-overlay-translate-language.show-success .wrapper-translate {
  display: none;
}
#clients-overlay-translate-language.show-success .wrapper-success {
  display: block;
}

#clients-overlay-translate-string {
  width: 350px;
}
#clients-overlay-translate-string .wrapper-success {
  display: none;
}
#clients-overlay-translate-string .wrapper-success .mixin-article-list li {
  display: none;
}
#clients-overlay-translate-string .wrapper-success .mixin-article-list li.show {
  display: flex;
}
#clients-overlay-translate-string .wrapper-warning {
  display: none;
}
#clients-overlay-translate-string.show-success {
  width: 500px;
}
#clients-overlay-translate-string.show-success .wrapper-translate {
  display: none;
}
#clients-overlay-translate-string.show-success .wrapper-success {
  display: block;
}
#clients-overlay-translate-string.show-warning .wrapper-translate {
  display: none;
}
#clients-overlay-translate-string.show-warning .wrapper-warning {
  display: block;
}

#contacts-overlay-request {
  width: 400px;
}
#contacts-overlay-request form {
  margin-top: 20px;
}

#contracts-overlay-accept-terms {
  width: 600px;
}
#contracts-overlay-accept-terms form {
  margin-top: 20px;
}
#contracts-overlay-accept-terms form .select2 {
  margin-bottom: 30px;
}

#contracts-overlay-change-plan {
  width: 600px;
}
#contracts-overlay-change-plan form {
  margin-top: 20px;
}

#contracts-overlay-confirm-cancel {
  width: 600px;
}

#contracts-overlay-confirm-payment-account {
  width: 600px;
}

#contracts-overlay-create-menu {
  width: 400px;
}

#contracts-overlay-send-promotion {
  width: 400px;
}
#contracts-overlay-send-promotion form {
  margin-top: 20px;
}
#contracts-overlay-send-promotion .wrapper-invitations {
  margin-top: 30px;
}

#demos-overlay-choose {
  width: 600px;
}
#demos-overlay-choose .tiles-wrapper {
  padding: 0 30px;
}

@media screen and (max-width: 600px) {
  #demos-overlay-choose .tiles-wrapper {
    padding: 0;
  }
  #demos-overlay-choose .tiles-wrapper .tile-wrapper {
    border: 1px solid #037faa;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
}
#demos-overlay-request {
  width: 400px;
}
#demos-overlay-request form {
  margin-top: 20px;
}

#images-overlay-enlarge img {
  max-width: calc(100vw - 100px);
  max-height: calc(100vh - 130px);
}

#interfaces-overlay-area-favourites {
  width: 400px;
}
#interfaces-overlay-area-favourites form {
  margin-top: 20px;
}

#interfaces-overlay-area-table {
  width: 400px;
}
#interfaces-overlay-area-table > ul > li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.05);
}
#interfaces-overlay-area-table > ul > li > ul > li {
  display: flex;
  margin-bottom: 3px;
  overflow: hidden;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}
#interfaces-overlay-area-table > ul > li > ul > li > span {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.label {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.text {
  text-align: right;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.text .icon {
  color: #037faa;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box {
  padding: 4px 7px;
  color: #f3f6f7;
  line-height: 1em;
  background: #037faa;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box .icon {
  display: none;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box .icon[class^=icon-], #interfaces-overlay-area-table > ul > li > ul > li > span.box .icon[class*=" icon-"] {
  display: inline-block;
  margin-left: 9px;
  color: #f3f6f7;
  font-size: 14px;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box.highlight-green {
  background: #03aa9e;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box.highlight-purple {
  background: #a56cbb;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box.highlight-orange {
  background: #f59513;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.box.highlight-red {
  background: #e2430f;
}
#interfaces-overlay-area-table > ul > li > ul > li > span.bold {
  font-weight: 600;
}
#interfaces-overlay-area-table > ul > li > ul > li:last-child {
  margin-bottom: 0;
}
#interfaces-overlay-area-table > ul > li > ul.discreet {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#interfaces-overlay-area-table > ul > li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

#interfaces-overlay-reports-orders form {
  margin-top: 20px;
}
#interfaces-overlay-reports-orders .table-wrapper {
  overflow-x: auto;
}
#interfaces-overlay-reports-orders .table-wrapper table {
  min-width: 900px;
}

#interfaces-overlay-settings-area {
  width: 400px;
}

#interfaces-overlay-settings-product {
  width: 400px;
}

#interfaces-overlay-user-forbidden {
  width: 400px;
}

#interfaces-overlay-user-login {
  width: 700px;
}

@media screen and (max-width: 900px) {
  #interfaces-overlay-user-login form > .clm-wrapper > .clm.clm-7x4 {
    margin-bottom: 20px;
  }
}
#interfaces-overlay-user-password-set {
  width: 700px;
}
#interfaces-overlay-user-password-set form p {
  padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
  #interfaces-overlay-user-password-set form > .clm-wrapper > .clm.clm-7x4 {
    margin-bottom: 20px;
  }
}
#interfaces-overlay-user {
  width: 500px;
}

#overlay-error {
  max-width: 600px;
}
#overlay-error .mixin-overlay-header .text,
#overlay-error .mixin-overlay-header .icon {
  color: #e2430f;
}

#overlay {
  min-width: 20px;
}
#overlay > .outer > .wrapper {
  background: #fbfeff repeating-linear-gradient(120deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(3, 127, 170, 0.05) 0, rgba(3, 127, 170, 0.05) 100%);
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}
#overlay > .outer > .wrapper > .back {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0);
}
#overlay > .outer > .wrapper > .back:before, #overlay > .outer > .wrapper > .back:after {
  top: 10px;
  width: 11px;
  background-color: #628290;
}
#overlay > .outer > .wrapper > .close {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0);
}
#overlay > .outer > .wrapper > .close:before, #overlay > .outer > .wrapper > .close:after {
  top: 10px;
  width: 18px;
  background-color: #628290;
}
#overlay > .outer > .wrapper > .content {
  max-width: 1200px;
  padding-top: 20px;
}
#overlay > .outer > .wrapper > .content h5 {
  position: relative;
  margin-bottom: 5px;
  color: #037faa;
  font-weight: 600;
  line-height: 1.3em;
}
#overlay > .outer > .wrapper > .content h5 a {
  color: inherit;
  font: inherit;
  line-height: inherit;
}
#overlay > .outer > .wrapper > .content h5 a:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#overlay > .outer > .wrapper > .content p {
  margin-bottom: 10px;
  line-height: 1.4em;
}
#overlay > .outer > .wrapper > .content p.text-icon-left .icon {
  top: 1px;
  margin-right: 10px;
  font-size: 22px;
}
#overlay > .outer > .wrapper > .content p.text-icon-right .icon {
  top: 2px;
  margin-left: 10px;
  font-size: 22px;
}
#overlay > .outer > .wrapper > .content p.bold {
  font-weight: 600;
}
#overlay > .outer > .wrapper > .content p:last-of-type {
  margin-bottom: 0;
}
#overlay > .outer > .wrapper > .content table .mixin-btn {
  margin-top: 0;
}
#overlay > .outer > .wrapper > .content .ctn-block {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #aac3cd;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#overlay > .outer > .wrapper > .content .ctn-block h3 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #628290;
  font-size: 22px;
  text-align: center;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
  font-family: "Open Sans", sans-serif;
}
#overlay > .outer > .wrapper > .content .ctn-block h3 span {
  position: relative;
  top: 6px;
  margin-left: 5px;
  color: #03aa9e;
  font-size: 12px;
  font-weight: 600;
  line-height: 1em;
}
#overlay > .outer > .wrapper > .content .ctn-block:last-child {
  margin-bottom: 0;
}
#overlay > .outer > .wrapper > .content .mixin-interface-vue-error {
  max-width: 400px;
  height: auto;
  margin: 0 auto;
}
#overlay > .outer > .wrapper > .content .border-top {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(3, 127, 170, 0.1);
}
#overlay > .outer > .wrapper > .content .border-bottom {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
}
#overlay > .outer > .wrapper > .content > .module {
  max-width: 100%;
}
#overlay > .outer > .wrapper > .content [id^=interfaces-overlay] .clm-wrapper.key-value .clm .select2-container {
  position: relative;
  top: -5px;
  margin-bottom: -10px !important;
}
#overlay > .outer > .wrapper > .loading {
  z-index: 10;
  overflow: hidden;
  background: #fbfeff repeating-linear-gradient(120deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(3, 127, 170, 0.05) 0, rgba(3, 127, 170, 0.05) 100%);
}
#overlay > .outer > .wrapper > .loading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background-image: url("../images/assets/app/layout/loading.svg?1744355188281");
  background-size: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#overlay > .outer > .wrapper > .loading:after {
  content: attr(data-text);
  z-index: 999999999;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
#overlay.loading > .outer > .wrapper > .content {
  min-width: 150px;
  min-height: 120px;
}

html:not(.isMobile) #overlay > .outer > .wrapper {
  scrollbar-color: #aac3cd rgba(3, 127, 170, 0.05);
}

@media screen and (max-width: 1100px) {
  body.sidebar-badges-is-visible #overlay {
    bottom: 70px;
  }
}
@media screen and (max-width: 900px) {
  #overlay .clm-wrapper:not(.division-header):not(.key-value):not(.choose-buttons).equal-distribution {
    margin: 0 !important;
  }
  #overlay .clm-wrapper:not(.division-header):not(.key-value):not(.choose-buttons) .clm {
    width: 100%;
    padding: 0 !important;
  }
  #overlay .clm-wrapper:not(.division-header):not(.key-value):not(.choose-buttons) .clm .mixin-form-field-info {
    right: 0;
  }
}
@media screen and (max-width: 600px) {
  #overlay > .outer > .wrapper {
    padding: 15px;
  }
}
@media screen and (max-width: 400px) {
  body.sidebar-badges-is-visible #overlay {
    bottom: 60px;
  }
}
#pricing-overlay-plan-helper {
  width: 600px;
}
#pricing-overlay-plan-helper .row p {
  color: #8da3ab;
  font-size: 16px;
  line-height: 1.4;
}
#pricing-overlay-plan-helper .row .mixin-slider-range {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(3, 127, 170, 0.1);
}
#pricing-overlay-plan-helper .row .mixin-slider-range .header,
#pricing-overlay-plan-helper .row .mixin-slider-range .irs--flat {
  margin: 0 20px;
}

#pricing-overlay-pricing-countries {
  width: 600px;
}
#pricing-overlay-pricing-countries .table-wrapper {
  margin-bottom: 10px;
}

#pricing-overlay-supported-credit-debit-card-brands {
  width: 600px;
}

#pricing-overlay-supported-languages {
  width: 600px;
}
#pricing-overlay-supported-languages .mixin-article .mixin-article-list.clms-3 > li {
  -webkit-flex-basis: calc(33.333333% - 20px) !important;
  -moz-flex-basis: calc(33.333333% - 20px) !important;
  -ms-flex-basis: calc(33.333333% - 20px) !important;
  -o-flex-basis: calc(33.333333% - 20px) !important;
  flex-basis: calc(33.333333% - 20px) !important;
}

@media screen and (max-width: 500px) {
  #pricing-overlay-supported-languages .mixin-article .mixin-article-list.clms-3 > li {
    -webkit-flex-basis: calc(50% - 20px) !important;
    -moz-flex-basis: calc(50% - 20px) !important;
    -ms-flex-basis: calc(50% - 20px) !important;
    -o-flex-basis: calc(50% - 20px) !important;
    flex-basis: calc(50% - 20px) !important;
  }
}
@media screen and (max-width: 350px) {
  #pricing-overlay-supported-languages .mixin-article .mixin-article-list.clms-3 > li {
    -webkit-flex-basis: 100% !important;
    -moz-flex-basis: 100% !important;
    -ms-flex-basis: 100% !important;
    -o-flex-basis: 100% !important;
    flex-basis: 100% !important;
  }
}
#subscriptions-overlay-confirmed {
  width: 400px;
}

#subscriptions-overlay-register {
  width: 400px;
}
#subscriptions-overlay-register form {
  margin-top: 20px;
}

#users-overlay-select-orders {
  width: 450px;
}
#users-overlay-select-orders form {
  margin-top: 20px;
}
#users-overlay-select-orders form .row.orders {
  margin: 0 0 7px 0;
  padding-right: 30px;
  font-size: 0;
}
#users-overlay-select-orders form .row.orders .form-box {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
}
#users-overlay-select-orders form .row.orders .date,
#users-overlay-select-orders form .row.orders .price {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  -ms-filter: alpha(opacity=40);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#users-overlay-select-orders form .row.orders .date.date,
#users-overlay-select-orders form .row.orders .price.date {
  margin-left: 15px;
  font-variant: tabular-nums;
}
#users-overlay-select-orders form .row.orders .date.price,
#users-overlay-select-orders form .row.orders .price.price {
  float: right;
  margin-right: 10px;
}
#users-overlay-select-orders form .row.orders .date:hover:not(.no-css-hover),
#users-overlay-select-orders form .row.orders .price:hover:not(.no-css-hover) {
  text-decoration: underline;
}
#users-overlay-select-orders form .row.orders .icon {
  position: absolute;
  top: 2px;
  right: 0;
}
#users-overlay-select-orders form .row.orders.checked .date,
#users-overlay-select-orders form .row.orders.checked .price {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#users-overlay-select-orders form .row.sum {
  margin-top: 5px;
  padding: 5px 40px 0 0;
  border-top: 1px solid #aac3cd;
}
#users-overlay-select-orders form .row.sum span {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}
#users-overlay-select-orders form .row.sum span.label:first-letter {
  text-transform: capitalize;
}
#users-overlay-select-orders form .row.sum span.amount {
  float: right;
}

/* == PROJECT / APP / PAGES ====================================================== */
#page-error .icon {
  color: #42bbef;
}
#page-error:before {
  content: none;
}

#page-interfaces-interface-id {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  flex-direction: column;
  align-content: stretch;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
#page-interfaces-interface-id * {
  font-weight: 500;
}
#page-interfaces-interface-id p {
  font-size: 16px;
}
#page-interfaces-interface-id p.small {
  font-size: 14px !important;
}
#page-interfaces-interface-id form .row {
  font-size: 0;
}
#page-interfaces-interface-id form .row label {
  font-size: 14px;
}
#page-interfaces-interface-id form .row > a,
#page-interfaces-interface-id form .row > .link-style {
  font-size: 12px;
}
#page-interfaces-interface-id form .input-checkbox label span,
#page-interfaces-interface-id form .input-radio label span {
  font-size: 14px;
}
#page-interfaces-interface-id form .type-text-style .icon {
  top: 5px;
  font-size: 16px;
}
#page-interfaces-interface-id table * {
  font-size: 14px;
}
#page-interfaces-interface-id table tr th > .icon.icon-x, #page-interfaces-interface-id table tr th > .icon.icon-pending, #page-interfaces-interface-id table tr th > .icon.icon-warning,
#page-interfaces-interface-id table tr td > .icon.icon-x,
#page-interfaces-interface-id table tr td > .icon.icon-pending,
#page-interfaces-interface-id table tr td > .icon.icon-warning {
  top: 4px;
}
#page-interfaces-interface-id table tr th .mixin-btn .btn,
#page-interfaces-interface-id table tr td .mixin-btn .btn {
  height: 22px;
}
#page-interfaces-interface-id table tr th .mixin-btn .btn .icon,
#page-interfaces-interface-id table tr td .mixin-btn .btn .icon {
  top: 1px;
}
#page-interfaces-interface-id table tr th.mixin-table-th .headline,
#page-interfaces-interface-id table tr td.mixin-table-th .headline {
  font-weight: 700;
}
#page-interfaces-interface-id .section-content:first-child {
  background-image: none;
}
#page-interfaces-interface-id .tile-wrapper {
  margin: 0;
  padding: 15px;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
#page-interfaces-interface-id .clm-wrapper.key-value .icon-wrapper .icon,
#page-interfaces-interface-id .clm-wrapper.key-value .icon .icon {
  font-size: 18px;
}
#page-interfaces-interface-id .mixin-btn {
  margin-top: 0 !important;
}
#page-interfaces-interface-id .mixin-btn .btn {
  padding: 8px 12px;
  height: 34px;
}
#page-interfaces-interface-id .mixin-form-label .text {
  font-weight: 700;
}
#page-interfaces-interface-id #main {
  display: flex;
  overflow: hidden;
  -webkit-flex: 1 1 0;
  -moz-flex: 1 1 0;
  -ms-flex: 1 1 0;
  -o-flex: 1 1 0;
  flex: 1 1 0;
}
#page-interfaces-interface-id #notify {
  background: #e2430f;
}
#page-interfaces-interface-id #overlay table tr td .mixin-btn .btn {
  padding: 3px;
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
}
#page-interfaces-interface-id [data-dashboard-id] {
  cursor: pointer;
}
#page-interfaces-interface-id:before {
  content: none;
}

#page-maintenance form input[type=submit] {
  padding: 5px 15px;
  background: #037faa;
}
#page-maintenance form .mixin-toggle-password .icon {
  color: #037faa;
}
#page-maintenance .icon-excavator {
  color: #42bbef;
}

#page-pricing-index .mixin-article .mixin-article-list > li > .headline h4 {
  margin-top: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
#page-pricing-index .mixin-article .mixin-article-list > li > .content {
  margin-top: -3px;
}
#page-pricing-index .mixin-article .mixin-article-list > li > .content p {
  font-size: 14px;
  font-weight: 400;
}