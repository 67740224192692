@use '../../basics/globals' as *;

#hero {
    position: relative;

    h2 {
        font-size: $font-size-50px;
        line-height: 1.1em;
    }

    .section-content {
        display: none;
        position: relative;

        .clm-wrapper {

            .clm {

                .mixin-article {

                    .pagination {
                        position: absolute;
                        bottom: -20px;
                        right: 0;
                        color: #fff;
                        font-weight: 400;
                        font-size: 14px;
                        @include opacity(0.5);
                        @include font-family('Museo Slab');
                    }
                }

                &:last-child {
                    position: relative;

                    .mixin-image-wrapper {
                        position: absolute;
                        top: -140px;
                        left: 1px;
                        width: calc(50vw - 1px);
                        height: calc(100% + 380px);
                        @include transition(height, 0.3s, ease-out, 0s);

                        img {
                            height: 100%;
                            min-width: 100%;
                            object-fit: cover;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            width: 200px;
                            height: 100%;
                            @include gradient-streight(left, $color-blue-logo-1, 0, $transparent, 100%);
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            width: 100%;
                            height: 200px;
                            @include gradient-streight(top, $color-blue-logo-1, 0, $transparent, 100%);
                        }
                    }
                }
            }
        }

        &:first-child {
            display: block;
        }
    }

    .image-mask {
        position: absolute;
        left: 0;
        bottom: -300px;
        z-index: 1;
        width: 100%;
        height: 300px;
        background: $color-grey-white;
        transform: skewY(-7deg);
    }

    .image-deco {
        position: absolute;
        left: calc(50% - 100px);
        bottom: 0;
        z-index: 1;
        width: calc(50% + 100px);
        height: 100px;
        background: $color-blue-logo-2-opacity-015p;
        transform: skewY(-7deg);
    }

    .royalSlider {
        margin-bottom: 120px;
        width: 100%;
        @include opacity(0);

        > * {
            float: none;
        }

        .rsOverflow {
            overflow: visible;

            .rsContainer {
                overflow: visible;

                .rsSlide {
                    overflow: visible;

                    .section-content {
                        margin-bottom: 0;
                    }
                }
            }

            .rsArrow {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100px;

                .rsArrowIcn {
                    position: relative;
                    top: 50%;
                    text-align: center;
                    @include transform(translate(0 -50%));

                    .icon {
                        color: $color-white;
                        font-size: 50px;
                        @include opacity(0.3);
                        @include transition(opacity, 0.3s, ease-out, 0s);
                    }
                }

                &.rsArrowLeft {
                    left: 0;
                }

                &.rsArrowRight {
                    right: 0;
                }

                &:hover {

                    .rsArrowIcn {

                        .icon {
                            color: $color-white;
                            @include opacity(1);
                        }
                    }
                }
            }
        }

        &:not(.rsAutoHeight) {
            height: auto !important;
        }

        &.rsAutoHeight {
            @include opacity(1);
            @include transition(opacity, 0.3s, ease-out, 0.3s);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: -150px;
            left: 0;
            right: 0;
            z-index: -1;
            background: $color-blue-logo-1;
        }
    }
}

html.isBot {

    #hero {

        .royalSlider:not(.rsAutoHeight) {
            @include opacity(1);
        }
    }
}

@media screen and (max-width: 1200px) {

    #hero {

        .royalSlider {

            .rsOverflow {

                .rsArrow {
                    display: none !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    #hero {

        .section-content {

            .clm-wrapper {

                .clm {

                    .mixin-article {

                        .pagination {
                            bottom: auto;
                            top: 0;
                        }
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        .image-deco {
            bottom: -50px;
        }

        .royalSlider {
            height: auto !important;
        }
    }
}

@media screen and (max-width: 500px) {

    #hero {

        h2 {
            font-size: $font-size-40px;
        }
    }
}
