@use '../../basics/globals' as *;

#pricing-plans {

    .clm-wrapper {

        .clm {
            max-width: 600px;

            .tile-wrapper {

                .mixin-article {
                    text-align: center;

                    p {
                        position: relative;
                        z-index: 1;

                        &.mini {
                            color: $color-grey-bright;
                        }
                    }

                    .badge {
                        display: block;
                        position: relative;
                        margin: -40px -30px 40px -30px;
                        padding: 20px;
                        background: $color-grey-dark;

                        .text {
                            color: $color-grey-white;
                            font-weight: 500;
                            text-transform: uppercase;
                            @include font-family('Exo 2');
                        }

                        .icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            color: $color-grey-white;
                            font-size: 150px;
                            @include opacity(0.1);
                            @include transform(translate(-50%, -50%));

                            &.icon-smiley {
                                top: calc(50% - 10px);
                                font-size: 200px;
                            }

                            &.icon-infinite {
                                font-size: 200px;
                            }
                        }
                    }

                    .circle-wrapper {
                        position: relative;
                        margin: 30px 0;
                        height: 150px;

                        .circle {
                            display: inline-block;
                            position: relative;
                            z-index: 2;
                            padding-top: 40px;
                            width: 150px;
                            height: 150px;


                            span {
                                position: relative;
                                z-index: 1;
                                display: block;
                                color: $color-grey-white;

                                &.price {
                                    font-size: $font-size-26px;
                                    font-weight: 500;
                                }

                                &.period {
                                    font-size: $font-size-14px;
                                }
                            }

                            &:before,
                            &:after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                @include transform(translate(-50%, -50%));
                                @include border-radius(50%, 50%, 50%, 50%);
                            }

                            &:before {
                                width: 170px;
                                height: 170px;
                                background: $color-blue-logo-1;
                            }

                            &:after {
                                width: 100%;
                                height: 100%;
                                background: $color-blue-logo-2;
                                border: 3px solid $color-grey-white;
                            }
                        }

                        &:before,
                        &:after {
                            content: '';
                            position: absolute;
                        }

                        &:before {
                            top: 50%;
                            left: -30px;
                            z-index: 2;
                            right: -30px;
                            height: 7px;
                            background: $color-blue-logo-1;
                            @include transform(translate(0, -50%));
                        }

                        &:after {
                            top: 50%;
                            left: -30px;
                            right: -30px;
                            bottom: -1000px;
                            background: $color-grey-light;
                        }
                    }

                    .card-logos-wrapper {
                        position: relative;
                        z-index: 1;
                        margin-bottom: 10px;

                        div {
                            font-size: 0;

                            img {
                                height: 16px;
                                margin: 10px 16px;

                                &:first-child {
                                    margin-left: 0;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    .border-bottom {
                        position: absolute;
                        bottom: -40px;
                        left: -30px;
                        right: -30px;
                        height: 10px;
                        background: $color-grey-dark;
                    }

                    .mixin-article-header {

                        h2 {
                            display: block;
                            position: relative;
                            padding-bottom: 50%;
                            margin: -20px -10px 0 -10px;
                            width: calc(100% + 20px);
                            max-width: calc(100% + 20px);

                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                color: $color-white;
                                font-size: $font-size-30px;
                                font-weight: 500;
                                text-transform: uppercase;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                @include font-family('Exo 2');
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                z-index: -1;
                                background-image: url('../images/assets/app/layout/background-blue-dots.png?#{$timestamp}');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 100% auto;
                            }
                        }

                        .subline {
                            font-size: $font-size-14px;
                            font-weight: 400;
                        }
                    }

                    .mixin-article-list {
                        text-align: left;
                    }

                    .mixin-btn {
                        position: relative;
                        z-index: 1;
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {

    #pricing-plans {

        .clm-wrapper {

            .clm {

                .tile-wrapper {

                    .mixin-article {

                        .badge {
                            margin-top: -25px;
                        }

                        .mixin-article-header {

                            h2 {
                                margin: -10px 0 0 0;
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
