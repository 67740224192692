$distance-default-20px: 20px;
$distance-default-30px: 30px;
$distance-default-40px: 40px;
$distance-default-50px: 50px;
$screen-1365: 1365px;
$screen-1279: 1279px;
$screen-1023: 1023px;
$screen-767: 767px;
$screen-319: 319px;
$border-radius-3px: 3px;
$border-radius-5px: 5px;
$form-padding: 4px 5px;
$form-font-size: 16px;
$form-font-weight: 300;
$form-line-height: 24px;
$duration-100ms: 0.1s;
$duration-200ms: 0.2s;
$duration-300ms: 0.3s;
$duration-400ms: 0.5s;
$duration-500ms: 0.5s;
$font-size-8px: 8px;
$font-size-10px: 10px;
$font-size-12px: 12px;
$font-size-14px: 14px;
$font-size-16px: 16px;
$font-size-18px: 18px;
$font-size-20px: 20px;
$font-size-22px: 22px;
$font-size-24px: 24px;
$font-size-26px: 26px;
$font-size-28px: 28px;
$font-size-30px: 30px;
$font-size-32px: 32px;
$font-size-34px: 34px;
$font-size-36px: 36px;
$font-size-38px: 38px;
$font-size-40px: 40px;
$font-size-46px: 46px;
$font-size-50px: 50px;
$font-size-60px: 60px;
$font-size-70px: 70px;
$font-size-80px: 80px;
$font-size-90px: 90px;
$font-size-100px: 100px;
$font-size-110px: 110px;
$font-size-120px: 120px;
$font-size-130px: 130px;
$font-size-140px: 140px;
$font-size-150px: 150px;
$color-theme: null;
$color-selection-color: null;
$color-selection-background: null;
$color-white: #fff;
$color-black: #000;
$color-grey-light: #f3f3f4;
$color-grey-shiny: #c3c3c3;
$color-grey-bright: #e0e0e0;
$color-grey-middle: #999c9e;
$color-grey-dim: #676a6c;
$color-grey-dark: #333;
$color-grey-black: #181818;
$color-grey-blue-pale: #a7b1c2;
$color-grey-blue-bright: #2f4050;
$color-grey-blue-middle: #293846;
$color-blue-light: #d9e9f3;
$color-blue-middle: #1c84c6;
$color-green-mint: #23c6c8;
$color-red-error: #e2430f;
$color-orange-warning: #ff9800;
$transparent: rgba(0, 0, 0, 0);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$header-mobile-height: 66px;
$border-radius-3px: 3px;
$border-radius-5px: 5px;
$border-radius-10px: 10px;
$border-radius-15px: 15px;
$border-radius-30px: 30px;
$color-theme: #fbfeff;
$color-selection-color: #fbfeff;
$color-selection-background: #42bbef;
$color-grey-white: #fbfeff;
$color-grey-shiny: #f6f9fc;
$color-grey-light: #f3f6f7;
$color-grey-bright: #b6c1c4;
$color-grey-middle: #999c9e;
$color-grey-dark: #628290;
$color-grey-dim: #676a6c;
$color-blue-shiny: #ddeff5;
$color-blue-bright: #aac3cd;
$color-blue-logo-1: #42bbef;
$color-blue-middle: #099bda;
$color-blue-logo-2: #037faa;
$color-blue-logo-2-opacity-005p: rgba(3, 127, 170, 0.05);
$color-blue-logo-2-opacity-005p-hex: #f4f9fb;
$color-blue-logo-2-opacity-010p: rgba(3, 127, 170, 0.1);
$color-blue-logo-2-opacity-010p-hex: #e9f3f6;
$color-blue-logo-2-opacity-015p: rgba(3, 127, 170, 0.15);
$color-blue-logo-2-opacity-015p-hex: #dfedf3;
$color-blue-grey: #8da3ab;
$color-blue-dark: #005e7d;
$color-blue-black: #41535d;
$color-green-bright: #a2ecb7;
$color-green-middle: #03aa9e;
$color-purple-middle: #a56cbb;
$color-red-error: #e2430f;
$color-orange-middle: #f59513;
$color-menu-default: #fff;
$timestamp: 1738659275857;
